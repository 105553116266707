@import "../../../styles/uitilities.scss";

.register_modal {
  &_header {
    margin-bottom: 29px;
    text-align: center;

    h4 {
      font-size: size(26);
      font-weight: $font-bold;
      margin-bottom: 0;
      color: $text-color-alt;

      @include max(767.98) {
        font-size: size(22);
      }
    }
  }

  &_info {
    p {
      font-size: 10px;
      text-align: center;
    }
  }

  &_other_logins {
    width: 100%;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px;

    .continue {
      font-size: size(15);
      font-weight: $font-regular;
      color: $text-color-alt;
    }

    .other_login_icons {
      padding-left: 18px;
      display: flex;
      align-items: center;
    }

    .other_login_icon {
      margin-bottom: 0;
      border: none;
      background: none;
      transition: 0.3s ease all;

      &:not(:first-child) {
        padding-left: 7.5px;
      }

      &:not(:last-child) {
        padding-right: 7.5px;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  &_content {
    padding: 56px 46px 50px;
    position: relative;

    @include max(575.98) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .forgot_password {
      margin-top: 15px;
      text-align: right;
      margin-bottom: 26px;

      button {
        font-size: size(15);
        font-weight: $font-regular;
        margin-bottom: 0;
        color: $text-color-alt;
        text-decoration: none;
        border: none;
        background: none;
        transition: 0.3s ease all;

        &:hover {
          filter: opacity(0.7);
        }
      }
    }

    :global {
      .select__control {
        transition: border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-right: none !important;
      }

      .form-group.error {

        .select__control,
        #phone_number {
          border-color: #ff6261 !important;
        }

        .select__control {
          border-right: none !important;
        }
      }


    }
  }

  &_form {
    width: 100%;

    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;

      &::before {
        content: "";
        background: $white;
        position: absolute;
        left: 20px;
        width: 14px;
        height: 14px;
      }

      &[x-placement="bottom-start"] {
        transform: translate(0px, 63px) !important;

        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }

      &[x-placement="top-start"] {
        transform: translate(0px, -64px) !important;

        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }

      a {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        transition: 0.5s ease all;

        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }
    }

    .mobile_input {
      height: 50px;
      display: flex;

      .select_wrap {
        position: relative;
        flex: 0 0 60px;

        &::after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.871' height='5.686' viewBox='0 0 9.871 5.686'%3E%3Cpath id='Path_76283' data-name='Path 76283' d='M46.27,25.325,50.145,29.2l3.875-3.875' transform='translate(-45.209 -24.264)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-58%);
          pointer-events: none;
        }
      }

      select {
        padding-left: 10px;
        border: 1px solid $input-border;
        border-radius: 10px 0 0 10px;
        border-right: 1px solid $input-border-alt;
        background: $input-background;
        width: 100%;
        height: 100%;
        color: $text-color-alt;
        appearance: none;
      }

      input {
        margin-left: -1px;
      }

      :global(.form-control) {
        border-radius: 0 10px 10px 0;
        flex: 1;
      }

      button {
        background-color: $input-background;
      }
    }

    :global(.error) {
      select {
        border: 1px solid $error-message;
      }
    }
  }

  &_agreement {
    margin-top: 26px;
    font-size: size(14);
    font-weight: $font-regular;
    line-height: 28px;
    color: var(--graytext1) !important;
    opacity: 1;
    text-align: center;

    span {
      cursor: pointer;
      color: inherit;
      text-underline-offset: 1px;
      text-decoration: underline;

      &:hover {
        color: $modal-link;
        text-decoration: underline;
      }
    }
  }

  &_have_account {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: size(15);
      font-weight: $font-medium;
      color: $text-color-alt;
      padding-right: 5px;
    }

    button {
      font-size: size(15);
      font-weight: $font-medium;
      color: $modal-link;
      border: none;
      background: none;

      &:hover {
        filter: opacity(0.7);
      }
    }
  }
}

@media (max-height: 900px) {
  .register_modal {
    align-items: flex-start;
    padding-top: 25px;
  }
}

.errorMessage {
  background-color: #ff888821;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  border-radius: 4px;
  margin-bottom: 22px;
  max-width: calc(100% - 8px);
  margin-left: 4px;

  p {
    margin: 0px;
    padding: 0px;
    color: #ff6261;
  }
}

.otp_continue_btn_loader::after {
  content: ".";
  animation: dots 2s steps(5, end) infinite;
  font-size: 40px;
  line-height: 1px;
  vertical-align: top;
}

.select_wrap {
  &::after {
    filter: invert(1);
  }
}

.prelogin_header_nav_language {
  :global(.dropdown-toggle) {
    background-color: $input-background;
    padding: 0;
    height: 50px;
    border-radius: 6px 0 0 6px;
    border: 1px solid #dedede;
    color: #000;
    min-width: inherit;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: size(16);
    font-weight: $font-regular;

    &::after {
      display: none;
    }

    &:active,
    &:focus {
      background-color: #fff;
      border-color: #dedede;
      color: #000;
    }
  }

  :global(.dropdown-menu[x-placement="bottom-start"]::before) {
    right: inherit;
    left: 25px;
  }
}

.form_error_msg_wrap {
  position: relative;
  height: 24px;
  margin-top: -26px;
  margin-bottom: 3px;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

:global(.form-group .field_error_message) {
  position: absolute;
  padding-top: 3px;
  font-size: 12px;
}

:global(.form-group.error) {
  margin-bottom: 1.25rem;
}

:root {
  --graytext1: rgba(0, 0, 0, 0.57);
}

:global(.dark) {
  --graytext1: rgba(255, 255, 255, 0.57);
}

.showErrors_wrap {
  height: 22px;
  width: 100%;
  position: relative;
  margin-top: -24px;
  margin-bottom: 7px;
}