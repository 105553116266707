@import "../../../../../../styles/uitilities.scss";

.file_upload {
  &_modal {
    :global(.modal-dialog) {
      @include min(768) {
        max-width: 836px;
      }
    }

    :global(.modal-content) {
      padding: 48px 40px 40px;
    }
  }


  &_image {
    margin-bottom: 23px;
    object-fit: cover;
  }

  &_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &_label {
      color: #23cc2b;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  &_info {
    font-size: size(20);
    font-weight: $font-medium;
    margin-bottom: 10px;
    color: $text-color-alt;

    &_desc {
      font-size: size(17);
      font-weight: $font-regular;
      line-height: 28px;
      color: $text-color-alt;
      margin-bottom: 0;
    }
  }

  &_preview {
    display: flex;
    justify-content: space-between;
    align-items: center;

    :global(.swiper) {
      flex: 1;
    }

    :global(.swiper-slide) {
      width: 111.4px !important;
    }

    &_wrap {
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      position: relative;

      &.blank_image {
        border: 1px dashed #bfc4ce7c;
      }

      svg {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 20px;
        height: 20px;
        fill: transparent;
      }

     
    }

    &_image {
      position: relative;
      margin-bottom: 0;
      background: $file-preview-bg;
      border-radius: 6px ;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        max-width: 100%;
        max-height: 100%;
        user-select: none;
        object-fit: cover;
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: 74.107143%;
        z-index: 100;
        position: relative;
      }
      &.invalidImage::after,
      &.dupliaction::after {

        background-color: rgb(149 141 19 / 40%);
        box-shadow: inset 0px 0px 0px 3px $error-message-alt;

      }
      &.dupliaction{
        background-color: #fff;
       img{
      object-fit: contain !important;
        
       }


      }

      &.uploadfail {
        &::after {
          box-shadow: inset 0px 0px 20px $error-message-alt;
          z-index: 1;
          pointer-events: none;
          position: relative;
        }
      }
    }

    .file_upload_input_2 {
      display: none;
    }

   
  }

  &_btn_wrap {
    text-align: center;
    margin-top: 27px;

    :global(.btn) {
      min-width: 136px;
      line-height: 1;
    }
  }

  &_delete {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #00000064;
    box-shadow: 0px 3px 4px #0000001c;
    border: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 8px;
      height: 8px;

      path {
        fill: #fff;
      }
    }

    &:hover {
      background: #ffffff64;

      svg {
        path {
          fill: $text-color-alt;
        }
      }
    }
  }


}

.duplicatedIcon {
  position: relative;
  z-index: 1000 !important;
}

.progress_message {
  display: flex;
  justify-content: center;
  width: 100%;

  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fff;
    ;
  }

}

.success_indicator {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: #21c031;
    border-radius: 50%;

    .success_check {
      width: 12px;
      height: 6px;
      border: 1px solid transparent;
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%) rotate(-45deg);
    }
  }

  :global{
    #invalid-tooltip,
    #button-tooltip{
      z-index: 10000000;
      --bs-tooltip-bg: #f03b0f;
      font-size: 12px;
    }
  }