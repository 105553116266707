@import "../../../styles/uitilities.scss";

.not_found_page {
    text-align: center;
    .error_code {
        font-size: size(74);
        font-weight: $font-bold;
        color: $text-color-alt;
        margin-bottom: 20px;
        line-height: 1;
    }
    .error_name {
        font-size: size(28);
        font-weight: $font-medium;
        color: $text-color-alt;
        line-height: 1;
        margin-bottom: 0;
    }
}