@import '../../styles/uitilities.scss';

.home_live_status {
  padding: 105px 0 102px;
  background: url('../../assets/images/create-and-explore-bg.webp') no-repeat, $gradient-bg;
  background-size: cover;
  @include max(991.98) {
      padding: 70px 0;
  }
  @include max(767.98) {
    padding: 55px 0;
    background: url('../../assets/images/create-and-explore-bg-mob-2.webp') no-repeat, $gradient-bg;
    background-size: cover;
  }
  &_wrap {
    display: flex;
    flex-wrap: wrap;
  }
  :global(.show > .btn-primary.dropdown-toggle) {
      color: #ffffff;
      background-color: #26325d;
      border-color: 1px solid #26325d;
  }
  :global(.dropdown-toggle) {
    background: $dropdown-bg;
    border: 1px solid $dropdown-bg;
    border-radius: 12px;
    height: 46px;
    font-size: size(16);
    font-weight: $font-regular;
    position: relative;
    padding: 10px 40px 10px 16px;
    text-transform: uppercase;
    transition: 0.5s ease all;
    min-width: 130px;
    display: flex;
    align-items: center;
    &:focus,
    &:active {
      background: $dropdown-bg;
      border: 1px solid $dropdown-bg;
      color: $white;
    }
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      border-top: 6px solid rgba($white, 0.46);
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transition: 0.5s ease all;
    }
    span {
      margin-right: 10px;
    }
  }
  :global(.dropdown-item) {
    text-transform: uppercase;
  }
  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 13px;
    position: relative;
    transform: translate(-10px, 55px) !important;
    width: min-content;
    // transition: 0.5s ease all;
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 25px;
      width: 14px;
      height: 14px;
    }
    &[x-placement='bottom-start'] {
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        // transition: 0.5s ease all;
      }
    }
    &[x-placement='top-start'] {
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        // transition: 0.5s ease all;
      }
    }
    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.5s ease all;
      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }
  &_country {
    padding-bottom: 35px;
    width: max-content;
  }
  &_title {
    max-width: 490px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include max(575.98) {
      max-width: 320px;
    }
    p {
      max-width: 400px;
    }
  }
  &_box {
    padding: 28px 0 17px;
    width: max-content;
    @include max(767.98) {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
    &:first-child {
      padding-top: 0;
      padding-left: 0;
    }
    &:last-child {
      padding-bottom: 0;
      padding-right: 0;
    }
    &:not(:last-child) {
      border-bottom: 2px solid rgba($border-grey, 0.34);
      transition: 0.5s ease all;
      @include max(767.98) {
        border-bottom: none;
        border-right: 2px solid rgba($border-grey, 0.34);
      }
    }
    .status_count {
      font-size: size(40);
      font-weight: $font-medium;
      margin-bottom: 5px;
      color: $white;
      line-height: 1;
      display: flex;
      align-items: center;
      transition: 0.5s ease all;
      height: 40px;
      display: block;
      width: max-content;
      @include max(575.98) {
        font-size: size(32);
        margin-bottom: 8px;
      }
    }
    .status_name {
      font-size: size(15);
      font-family: $font-family-lato;
      font-weight: $font-regular;
      line-height: 26px;
      margin-bottom: 0;
      color: $white;
      letter-spacing: 1.95px;
      transition: 0.5s ease all;
      @include max(575.98) {
        font-size: size(13);
      }
    }
  }
  &_head {
    flex: 0 0 70.8%;
    max-width: 70.8%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    @include max(991.98) {
      flex: 0 0 65%;
      max-width: 65%;
      padding-right: 50px;
    }
    @include max(767.98) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 33px;
      padding-right: 0 !important;
    }
  }
  &_status {
    flex: 0 0 29.2%;
    max-width: 29.2%;
    @include max(991.98) {
      flex: 0 0 35%;
      max-width: 35%;
    }
    @include max(767.98) {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
    }
  }
}



:global(.data-loading) {
  &.home_live_status_box,
  &.home_live_status_country {
    .shimmer-styles {
      border-radius: 5px;
      width: 100%;
      background: $shimmer-green-bg;
      background-image: $shimmer-green-gradient;
      background-repeat: no-repeat;
      background-size: 800px 104px; 
      display: inline-block;
      position: relative; 
      
      -webkit-animation-duration: 1s;
      -webkit-animation-fill-mode: forwards; 
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-name: placeholderShimmer;
      -webkit-animation-timing-function: linear;
    }
  }
  .status_count {
    span {
      @extend .shimmer-styles;
      width: 30px;
      display: block;
      height: 30px;
      margin-right: 10px;
    }
  }
  &.home_live_status_country {
    span {
      @extend .shimmer-styles;
      height: 46px;
      max-width: 130px;
    }
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }
}

.dropdown_wrap{
  position: relative;
  z-index: 9;

  display: none;
}