@import "../../../styles/uitilities.scss";

.signin_modal {
  width: 100%;
  &_header {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
    h4 {
      font-size: size(26);
      font-weight: $font-bold;
      margin-bottom: 0;
      color: $text-color-alt;
      @include max(767.98) {
        font-size: size(22);
      }
    }
    p{
      color: #a8a8a8;
    }
  }
  &_tabs {
    width: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    overflow: hidden;
    height: 60px;
    position: relative;
    margin-bottom: 32px;
    .tab_item {
      width: 50%;
      height: 100%;
      color: $text-color-alt;
      background: $tab-bg;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      span {
        z-index: 2;
        font-size: size(18);
        font-weight: $font-medium;
        color: $text-color;
      }
    }
    .tab {
      position: absolute;
      top: 10%;
      width: 48%;
      height: 80%;
      border-radius: 4px;
      background-color: $tab-active-bg;
      transition: all 0.3s ease-out;
      z-index: 1;
    }
  }
  &_other_logins {
    width: 100%;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px;
    .continue {
      font-size: size(15);
      font-weight: $font-regular;
      color: $text-color-alt;
    }
    .other_login_icons {
      padding-left: 18px;
      display: flex;
      align-items: center;
    }
    .other_login_icon {
      margin-bottom: 0;
      border: none;
      background: none;
      transition: 0.3s ease all;
      &:not(:first-child) {
        padding-left: 7.5px;
      }
      &:not(:last-child) {
        padding-right: 7.5px;
      }
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  &_create_account {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: size(15);
      font-weight: $font-medium;
      color: $text-color-alt;
      padding-right: 5px;
    }
    button {
      font-size: size(15);
      font-weight: $font-medium;
      color: $modal-link;
      border: none;
      background: none;
      transition: 0.3s ease all;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &_content {
    max-width: 522px;
    width: 100%;
    min-height: 300px;
    border-radius: 20px;
    background-color: $modal-bg;
    padding: 56px 46px 50px;
    transition: all 0.3s;
    position: relative;
    @include max(575.98) {
      border-radius: 0;
      max-width: 100%;
      min-height: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }
    .close_btn {
      position: absolute;
      top: 15px;
      right: 12px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .btn {
        transform: rotate(45deg);
        font-size: 35px;
        color: $text-color-alt;
      }
    }
    :global(.form-group.password) {
      margin-bottom: size(18);
    }
    .btn_forgot_password {
      margin-top: 17px;
      text-align: right;
      button {
        font-size: size(15);
        font-weight: $font-regular;
        margin-bottom: 0;
        color: $text-color-alt;
        text-decoration: none;
        border: none;
        background: none;
        transition: 0.3s ease all;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    :global(.error-note) {
      margin-top: 18px;
    }
    .signin_button {
      margin-top: 20px;
    }

    :global(.form-group) {
      // margin-bottom: 25px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .mobile_input {
      height: 50px;
      display: flex;
      .select_wrap {
        position: relative;
        flex: 0 0 60px;
        &::after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.871' height='5.686' viewBox='0 0 9.871 5.686'%3E%3Cpath id='Path_76283' data-name='Path 76283' d='M46.27,25.325,50.145,29.2l3.875-3.875' transform='translate(-45.209 -24.264)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-58%);
          pointer-events: none;
        }
      }
      select {
        padding-left: 10px;
        border: 1px solid $input-border;
        border-radius: 10px 0 0 10px;
        border-right: 1px solid $input-border-alt;
        background: $input-background;
        width: 100%;
        height: 100%;
        color: $text-color-alt;
        appearance: none;
      }
      input {
        margin-left: -1px;
        border: none;
        outline: none;
      }
      :global(.form-control) {
        border-radius: 0 10px 10px 0;
        flex: 1;
      }
    }
    :global(.error) {
      select {
        border: 1px solid $error-message;
      }
    }
    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        left: 20px;
        width: 14px;
        height: 14px;
      }
      &[x-placement="bottom-start"] {
        transform: translate(0px, 63px) !important;
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }
      &[x-placement="top-start"] {
        transform: translate(0px, -64px) !important;
        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }
      a {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        transition: 0.5s ease all;
        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }
    }
  }
  &_wider_modal{
    min-width: 800px !important;
  }
}
// .description{
//   p{
//     color: #fff;
//     font-size: 16px;
//   }
//   span{
//     text-align: center;
//     display: block;
//     color: #fff;
//     font-size: 18px;
//     small{
//       color: green;
//       font-size: 18px;

//     }
//   }
// }
.desc{
  color:#cecece
}
.user_profiles {
  &_avatar {
    width: 50px;
    border-radius: 50%;
    margin: 0 auto 14px;
    img {
      width: 100%;
      display: block;
      border-radius: 50%;
    }  
  }
  &_info{
    text-align: left;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    flex-wrap: wrap;
    width: 100%;
    li {
      width: 200px;
      margin: 0 auto;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-color: #7a787856;
      padding: 20px;
      border-radius: 10px;
      transition: 0.3s ease;
      margin-top: 14px;
      border: 2px solid transparent;
      // box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 29px 0px;
      box-shadow: rgba(41, 41, 41, 0.2) 0px 8px 24px;
      &:hover {
        transform: scale(1.03);
      }

      h4 {
        color: #fff;
        font-size: 16px;
        text-transform: capitalize;
        margin-bottom: 0 !important;
      }
      
      span {
        display: inline-block;
        color: rgb(212, 212, 212);
        font-size: 12px;
      }
    }
  }
}
:global(.dark .fb-icon) {
  path {
    fill: $white;
  }
}

@media (max-height: 900px) {
  .signin_modal {
    align-items: flex-start;
    padding-top: 25px;
  }
}

.errorMessage {
  background-color: #ff888821;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  border-radius: 4px;
  margin-bottom: 22px;
  max-width: calc(100% - 8px);
  margin-left: 4px;
  width: 100%;
  p {
    margin: 0px;
    padding: 0px;
    color: #ff6261;
  }
}
.errorMessage_wrap {
  margin-top: -10px;
  margin-bottom: -5px;
  position: relative;
  padding: 5px;
  .errorMessage {
    padding: 5px;
    margin-bottom: 0;
    text-align: center;
  }
}

.otp_continue_btn_loader::after {
  content: " .";
  animation: dots 2s steps(5, end) infinite;
  font-size: 40px;
  line-height: 1px;
  vertical-align: top;
}

.select_wrap {
  &::after {
    filter: invert(1);
  }
}
.prelogin_header_nav_language {
  :global(.dropdown-toggle) {
    background-color: #fff;
    padding: 0;
    height: 50px;
    border-radius: 6px 0 0 6px;
    border: 1px solid #dedede;
    color: #000;
    min-width: inherit;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: size(16);
    font-weight: $font-regular;
    &::after {
      display: none;
    }
    &:active,
    &:focus {
      background-color: #fff;
      border-color: #dedede;
      color: #000;
    }
  }
  :global(.dropdown-menu[x-placement="bottom-start"]::before) {
    right: inherit;
    left: 25px;
  }
}


@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
