@import "../../styles/uitilities.scss";

.my_gallery {
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding-bottom: 44px;

  &_header {
    position: sticky;
    top: 83px;
    z-index: 99;
    background: $dashboard-bg;
    margin-bottom: 16px;
    padding: 0 52px;

    &_wrap {
      width: 100%;
      border-bottom: 1px solid $input-border;
      padding-bottom: 18px;

      @include max(575.9) {
        padding-bottom: 12px;
      }

      @include max(1199.9) {
        display: flex;
        flex-direction: column-reverse;
      }

      :global {
        .dropdown-menu {
          // background-color: $body-background;
          // color: $text-color;
          // padding: 10px 15px;
          background-color: #fff;
          border-radius: 10px;
          padding: 15px 13px;
          position: relative;
          transform: translate(-30px, 51px) !important;
          li {
            padding-top: 6px;
            padding-bottom: 6px;
            cursor: pointer;
            padding: 10px 15px;
            &:hover {
              color: #252533;
              background-color: rgba(51, 51, 51, 0.2) !important;
              border-radius: 6px;
            }
          }
        }
      }
    }

    @include min(1280) {
      padding-top: 25px;
    }

    @include min-max(1200, 1499.9) {
      padding: 25px 40px 0 40px;
    }

    @include min(1600) {
      padding-top: 44px;
    }

    @include max(1279.98) {
      top: 52px;
      padding-top: 15px;
    }

    @include max(575.98) {
      margin: auto auto 25px auto;
      padding: 0 16px;
      width: calc(100vw - 32px);
      padding: 0;
      padding-top: 15px;
    }

    + div {
      padding: 0 50px;
      margin: 0 !important;

      @include min-max(1200, 1499.9) {
        padding: 0 30px;
      }

      // @include max(1279.98) {
      //   padding-top: 25px;
      // }
      @include max(575.98) {
        padding: 0;
      }

      // > div {
      //   // margin-left: -10px;
      //   // margin-right: -10px;
      //   // width: inherit;
      //   @include max(575.98) {
      //     margin-left: -6px;
      //     margin-right: -6px;
      //   }
      // }
    }
  }

  &_title {
    font-size: size(30);
    font-weight: $font-bold;
    line-height: 40px;
    color: $text-color-alt;
    margin-bottom: 0;

    @include max(767.98) {
      font-size: size(24);
      line-height: 28px;
      margin-bottom: 5px;
    }
  }

  &_btn_wrap {
    display: flex;
    align-items: flex-end;

    :global {
      .btn:not(.btn-border-secondary):not(:hover) {
        color: $text-color;
      }
    }

    @include max(575.98) {
      margin-bottom: 17px !important;
    }

    @include max(1199.9) {
      border-bottom: 1px solid $input-border;
      margin-bottom: 20px;
      padding-bottom: 18px;
    }

    > *:not(:nth-child(1)) {
      margin-left: 8px;
    }

    > *:nth-child(1) {
      @include max(1199.9) {
        margin-right: auto !important;
      }

      @include min(1200) {
        margin-left: auto;
      }
    }

    @include max(575.9) {
      :global {
        .btn {
          width: auto !important;

          &:nth-child(1) {
            margin-left: 0 !important;
          }

          &:nth-last-child(1) {
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

.dashboard_btn {
  background-color: transparent;
  min-width: 40px;
  padding: 11px 20px;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid $dashboard-add-btn-border !important;
  &:active,
  &:hover {
    background-color: $text-color !important;
    color: $body-background !important;
  }

  @include max(1799.9) {
    font-size: 14px;
  }

  @include min(1200) {
    min-width: 90px;
  }

  svg {
    margin-top: -0.15em;

    &:nth-child(1) {
      margin-right: 7px;
    }
  }

  @include max(1199.9) {
    padding-left: 8px;
    padding-right: 8px;

    svg {
      margin-left: auto;
      margin-right: auto !important;
    }
  }
}

.Dropdown_toggle_btn {
  background-color: transparent;
  // max-width: 90px;
  min-width: 108px;
  padding: 11px 15px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;

  // &:focus,
  &:hover,
  &:active {
    background-color: $text-color !important;
    color: $body-background !important;
    border-color: currentColor !important;
  }
  @include max(1799.9) {
    font-size: 14px;
  }

  svg {
    margin-left: 4px;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  &:global(.show) {
    svg {
      transform: rotate(0deg);
    }
    &:after {
      content: "";
      width: 15px;
      height: 15px;
      background-color: white;
      display: block;
      position: absolute;
      top: calc(100% + 6px);
      transform: rotate(45deg);
      z-index: 10000;
      border-radius: 3px;
    }
  }
}

.VideoGallery_item {
  width: 100% !important;
  position: relative;

  // @include min(1200){
  //   max-width: 20%;
  // }
  @include min(380) {
    max-width: 50%;
  }

  @include max(575.9) {
    padding: 2px;
  }

  @include min(578) {
    padding: 10px;
  }

  @include min(768) {
    max-width: 33.3333%;
  }

  @include min(992) {
    max-width: 25%;
  }

  @include min(1200) {
    max-width: 20%;
  }

  &_container {
    width: 100%;
  }

  &_row {
    display: flex;
    flex-wrap: wrap;

    @include max(575.9) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @include min(576) {
      margin-left: -10px;
      margin-right: -10px;
    }

    @include min-max(1200, 1499.9) {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &_footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    color: white;
    padding: 18px 16px;
    z-index: 10;

    @include min(992) {
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: translateY(20px);
    }

    &_title {
      padding-right: 15px;
      white-space: nowrap;
      max-width: calc(100% - 71px);
      width: 100%;

      h4 {
        font-size: 16px;
        font-weight: 500;
        color: white;
        margin-bottom: 2px;
        line-height: 1.2em;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 95%;
      }

      p {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 80%;
      }
    }

    &_btns {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      button {
        svg {
          margin-left: 0px !important;
        }
      }
    }
  }

  &_content {
    width: 100%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);

    &:before {
      content: "";
      width: 100%;
      display: block;
      padding-bottom: calc(100 / 284 * 215 * 1%);
      box-shadow: inset 0px -106px 50px -60px rgba(0, 0, 0, 0.8);
      z-index: 1;
      position: relative;
      transition: box-shadow 0.3s ease-in-out;

      @include min(992) {
        box-shadow: inset 0px 0px 0px -60px rgba(0, 0, 0, 0.6);
      }
    }

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    &:hover {
      &:before {
        box-shadow: inset 0px -106px 50px -60px rgba(0, 0, 0, 0.8);
      }

      .VideoGallery_item {
        &_footer {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }

  &_select_active {
    .VideoGallery_item_content {
      pointer-events: none;

      &:before {
        opacity: 0 !important;
        pointer-events: none;
      }
    }
  }

  &_delete_btn,
  &_edit_btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    color: $dark-alt;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 3px;

    svg {
      margin-left: 2px;
    }

    &:hover {
      color: $primary-color;
    }
  }

  &_play_btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 44px;
    height: 44px;
    border: none;
    z-index: 15;
    border-radius: 50px;
    background-color: #0000003e;
    transition: background-color 0.3s ease-in-out;
    color: white;

    &:hover {
      background-color: #000000;
    }
  }

  &_select {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    z-index: 10;
    bottom: 15px;
    right: 15px;
    border: 2px solid #bbafaf;
    box-shadow: 0 0 100vmin 100vmin rgba(0, 0, 0, 0.3843137255);
    transition: box-shadow 0.3s ease-in-out;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
  }

  &_selected {
    box-shadow: 0 0 100vmin 100vmin rgba(0, 0, 0, 0);
    border-color: white;
    background-color: $primary-color;
  }
}

.VideoGallery_modal {
  &_add_video {
    :global {
      .modal-dialog.modal-dialog-centered {
        max-width: 780px;
      }
    }
  }

  &_container {
    padding: 25px 35px 35px 35px;

    h4 {
      color: $text-color;
    }

    label {
      font-size: 14px;
    }

    textarea {
      resize: none;
    }
  }

  &_content {
    padding-top: 8px;

    &_top {
      display: flex;

      @include max(575.9) {
        flex-wrap: wrap;
      }
    }

    &_poster {
      width: 100%;
      max-width: 225px;
      padding: 25px;
      border: 1px solid $input-border;
      margin-bottom: 20px;
      margin-right: 24px;
      border-radius: 8px;

      @include max(575.9) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &_add_thumbnail {
    width: 100%;
    height: 100%;
    font-size: 18px;

    svg {
      margin-bottom: 5px;
    }
  }

  &_footer {
    padding-top: 6px;

    :global {
      .btn {
        padding-top: 11px;
        padding-bottom: 11px;
        min-width: 150px;
        margin-left: auto;
        height: 45px;
        position: relative;
      }

      .noData_loader {
        margin-top: 0;

        > * {
          transform-origin: center;
          transform: translateZ(0) scale(0.5) translateY(-16px);
        }
      }
    }
  }

  &_add_area {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23ADB0B76B' stroke-width='2' stroke-dasharray='6%2c 10' stroke-dashoffset='23' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 6px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $text-color;
    cursor: pointer;
  }

  &_added_image {
    height: 100%;
    width: 100%;
    overflow: hidden;
    border-radius: 6px;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    max-height: 129px;

    img {
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &_img_remove_btn {
    background-color: #707070;
    height: 24px;
    width: 24px;
    color: white;
    position: absolute;
    border: none;
    border-radius: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-bottom: 0px;
    }

    &:hover {
      background-color: $primary-color;
    }
  }
}

.close_select {
  height: 40px;
  color: $text-color;
  background-color: transparent;
  border: none !important;
  margin-left: 30px !important;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: $primary-color;
  }
}

.error_msg {
  color: red;
  font-size: 0.9em;
  position: absolute;
}
.validationError {
  color: red;
  text-align: center;
  width: 100%;
  display: block;
  background-color: #ff000027;
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 12px;
}
