@import "../../../styles/uitilities.scss";

.edit_my_album {
  @include max(991.98) {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 80px;
    padding-top: 20px;
  }

  @include max(575.98) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0;
    padding-bottom: 42px;
  }

  &,
  h4 {
    color: $text-color;
  }

  :global(.btn-close) {
    background-size: 14px;

    @include max(767.98) {
      display: none;
    }
  }

  :global {
    .modal-body {
      .row {
        margin: 0;

        @include max(991.98) {
          flex-direction: column;
        }

        @include max(575.98) {
          margin-right: 0;
          width: 100%;
          --bs-gutter-x: 0;
        }
      }
    }
  }

  :global(.modal-dialog) {
    max-width: 1131px;

    @include max(1199.98) {
      padding: 15px;
    }
  }

  &_leftside {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    min-height: 560px;

    // min-height: max(calc(100vh - 506px), 283px);
    @include max(991.98) {
      min-height: 400px;
    }

    @include max(575.98) {
      flex-direction: column;
      min-height: 320px;

      .edit_album_head {
        position: relative;
        margin-bottom: 22px;
        width: 100%;
        text-align: center;

        .back_button {
          border: none;
          background-color: transparent;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);

          svg {
            path {
              fill: $black;
            }
          }
        }
      }

      h4 {
        font-size: size(18);
        font-weight: $font-medium;
        color: $black;
        margin-bottom: 0;
      }
    }
  }

  &_rightside {
    padding: 35px;

    @include max(575.98) {
      padding: 20px 16px;
    }

    :global(.form-control) {
      padding: 12px 15px;
      border-color: $input-border;

      @include max(575.98) {
        font-size: size(14);
        line-height: 16px;
      }
    }

    :global(.textarea) {
      border: 1px solid $input-border;
      padding: 0 0 12px 0;
      border-radius: 10px;
      overflow: hidden;
      background-color: $input-background;

      textarea {
        border: none;
        border-radius: 0;
        height: 60px;
      }
    }

    textarea {
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background: #252533;
      }

      &::-webkit-scrollbar-track {
        background: rgba(224, 226, 232, 0.5);
      }
    }
  }

  @include max(575.98) {
    overflow: auto;

    :global(.modal-dialog) {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0;
    }

    :global(.modal-content) {
      border-radius: 0px !important;
    }
  }

  &_mob_header {
    display: flex;
    padding: 6px 40px 6px 10px;
    align-items: center;
    justify-content: center;
  }

  &_close {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 16px;
    }
  }

  &_title_wrap {
    text-align: center;
    width: 100%;
  }

  &_title {
    font-size: size(18);
    margin-bottom: 0px;
  }

  @include max(767.98) {
    :global(.modal-body) {
      >div {
        margin-bottom: auto;
      }
    }
  }
}

.select_a_frame_col {
  height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 35px;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(#e0e2e8, 50%);
  }

  &::-webkit-scrollbar-thumb {
    background: #252533;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.select_a_frame_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -9px;
  margin-right: -9px;
}

.btnItem {
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #085cfd;
  color: #fff;
  border: none;
  min-height: 50px;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  font-weight: 600;
  display: block;
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
  cursor: pointer;

  &[disabled] {
    background-color: $btn-disabled;
    border: 1px solid $btn-disabled;
    color: $white;
    cursor: not-allowed !important;
  }
}

.modalMini {
  :global(.modal-dialog) {
    max-width: 500px;

    @include max(1199.98) {
      padding: 15px;
    }
  }
}

.error_msg {
  font-size: 12px;
  color: #d96060;
}