@import "../../styles/uitilities.scss";

.starred_gallery_grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  margin-top: 20px;
  &_wrap {
    flex: 0 0 20%;
    max-width: 20%;
    padding: 0 10px;
  }
  &_image {
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    &.shake_active {
      animation: rearrangeShake 1.2s linear infinite forwards;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }
    &::after {
      content: "";
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: calc(214 / 284 * 100%);
    }
    .actions_btn {
      border: none;
      background: #fff;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: all;
      position: absolute;
      top: 11px;
      right: 11px;
      border: 1px solid #d7dae4;
      &:hover {
        animation: trashAnim 1s linear infinite forwards;
      }
    }
  }
  &_add_btn {
    &_image {
      transition: 0.1s ease filter;
    }
  }
  .add_btn {
    color: #fff;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
    transition: 0.1s ease background-color;
    span {
      display: block;
    }
    &_sub {
      font-size: 12px;
    }
  }
  .add_btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    // display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border: none;
    background-color: $placeholder-image;
    box-shadow: inset 0 0 0 5px var(--placeholder-image), inset 0 0 0 8px #fff;
    border-radius: 20px;

    &_wrap {
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: calc(214 / 284 * 100%);
      }
    }
    &:hover {
      background-color: #23cc2c2f;
      .starred_gallery_grid_add_btn_image {
        filter: invert(1) brightness(2);
      }
    }
  }
}

@keyframes trashAnim {
  0% {
    transform: rotate(0);
  }
  7.69% {
    transform: rotate(20deg);
  }
  15.38% {
    transform: rotate(0);
  }
  23.07% {
    transform: rotate(-20deg);
  }
  30.76% {
    transform: rotate(0);
  }
  38.45% {
    transform: rotate(20deg);
  }
  46.14% {
    transform: rotate(0);
  }
  53.83% {
    transform: rotate(-20deg);
  }
  61.52% {
    transform: rotate(0);
  }
  69.21% {
    transform: rotate(0);
  }
  76.9% {
    transform: rotate(0);
  }
  84.59% {
    transform: rotate(0);
  }
  92.28% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes rearrangeShake {
  0% {
    transform: rotate(0);
  }
  7.69% {
    transform: rotate(1deg);
  }
  15.38% {
    transform: rotate(0);
  }
  23.07% {
    transform: rotate(-1deg);
  }
  30.76% {
    transform: rotate(0);
  }
  38.45% {
    transform: rotate(1deg);
  }
  46.14% {
    transform: rotate(0);
  }
  53.83% {
    transform: rotate(-1deg);
  }
  61.52% {
    transform: rotate(0);
  }
  69.21% {
    transform: rotate(0);
  }
  76.9% {
    transform: rotate(0);
  }
  84.59% {
    transform: rotate(0);
  }
  92.28% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
