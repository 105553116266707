.payment_modal {
  :global {
    .modal-dialog {
      max-width: 1300px;
    }
    // .modal-content{
    //   overflow: hidden;
    // }
  }
}
