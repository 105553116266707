@import "../../styles/uitilities.scss";

.InfiniteDotLoader {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 14px;
    div {
        position: absolute;
        top: 30%;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
        &:nth-child(1) {
            left: 0;
            animation: InfiniteDotLoader1 0.6s infinite;
        }
        &:nth-child(2) {
            left: 0;
            animation: InfiniteDotLoader2 0.6s infinite;
        }
        &:nth-child(3) {
            left: 16px;
            animation: InfiniteDotLoader2 0.6s infinite;
        }
        &:nth-child(4) {
            left: 32px;
            animation: InfiniteDotLoader3 0.6s infinite;
        }
    }
}

@keyframes InfiniteDotLoader1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes InfiniteDotLoader3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes InfiniteDotLoader2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(16px, 0);
    }
}
