@import "../../../../styles/uitilities.scss";

.root {
  background-color: $card-bg;
  flex-wrap: wrap;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 234px;
  padding: 20px 25px;
  border-radius: 6px;
  margin-left: 30px;
  
  @include max(991.98) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 102%;
    margin-left: 0;
    padding: 0 16px;
    background: $dashboard-bg;
  }
  &::after {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid $card-bg;
    position: absolute;
    left: -10px;
    bottom: 20%;
    z-index: 1;
  }
  &.selectChild{
    margin-left: 0px;
    margin-right: 30px;
    &::after {
      content: "";
      border-left: 12px solid var(--card-bg);
      border-right: 0;
      right: -11px;
      left: auto;
    }
  }
  .innerWrapper {
    width: 100%;
    position: relative;
  }
  .heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    @include max(991.98) {
      text-align: center;
    }
    h3 {
      font-size: 18px;
      line-height: 1.5;
      color: $text-color-alt;
      text-decoration: none;
      font-weight: 600;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;
    }
    button {
      background-color: transparent;
      border: none;
      color: $text-color-alt;
      line-height: 2.2;
    }
  }
}

.listWrapper {
  width: 100%;
  position: relative;
  overflow: scroll;
  height: 160px;
  &::-webkit-scrollbar {
    display: none;
  }
  .listItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  }
}

.item {
  width: 100%;
  max-width: 100%;
  position: relative;
  max-width: 202px;
  padding: 5px;
  background-color: $tab-active-bg;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  border: 2px solid $input-border;
  border-radius: 30px;
  @include max(991.98) {
    width: 100%;
    max-width: 100%;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .imageWrapper {
    width: 50px;
    display: flex;
    .imageContainer {
      width: 100%;
      position: relative;
      padding-bottom: 100%;
      border-radius: 50%;
      overflow: hidden;
      background-color: $placeholder-image;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .textWrapper {
    width: auto;
    position: relative;
    font-size: 16px;
    line-height: 1.25;
    color: $text-color-alt;
    text-decoration: none;
    font-weight: 500;
    padding: 0px 15px;
    text-align: left;
    transition: .3s transform ease-in-out;
  }
  &:hover{
    .textWrapper{
      transform: translateX(5px);
    }
  }
}

.active {
  border: 2px solid #23cc2b;
}

.buttonWrap {
  background-color: $card-bg;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  position: absolute;
  left: 50%;
  bottom: -26px;
  transform: translateX(-50%);
  &:hover {
    .submitChild {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
.submitChild {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border-radius: 50%;
  border: none;
  background-color: $modal-link;
  transform-origin: center;
  transition: 0.3s ease transform;
  span {
    display: block;
    width: 15px;
    height: 2px;
    background-color: #fff;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    + span {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}

.skip_child {
  text-decoration: underline;
  font-size: size(13);
  padding-left: 30px;
  &:hover {
    color: $modal-link !important;
  }
}