@import "../../styles/uitilities.scss";

.input_Style_error {
  border-color: #f85e5e !important;
  box-shadow: 0 0 0 1px #f85e5e;
}

.edit_profile_page {
  &_setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e2e877;
    &:last-child{
      border-bottom: none !important;
    }
    padding: 15px 0;

    &:first-child {
      padding-top: 0;
    }

    .setting {
      &_name {
        font-size: size(18);
        font-weight: $font-medium;
        color: $text-color-alt;

        @include max(575.98) {
          font-size: size(16);
        }
      }

      &_value {
        font-size: size(14);
        font-weight: $font-regular;
        margin-top: 8px;
        margin-bottom: 0;
        color: $text-color-alt;
        opacity: 0.7;

        @include max(575.98) {
          max-width: 232px;
        }
      }

      &_action {
        font-size: size(18);
        font-weight: $font-regular;
        background-color: transparent;
        border: none;
        text-decoration: underline;
        text-underline-offset: 2px;
        position: relative;
        cursor: pointer;

        @include max(575.98) {
          font-size: size(14);
        }

        &:hover {
          color: $modal-link;

          svg {
            @include max(575.98) {
              width: 14px;
              height: 14px;
            }

            path {
              fill: $modal-link;
            }
          }
        }

        svg {
          margin-right: 8px;
          margin-top: -4px;

          path {
            transition: 0.3s ease all;
            fill: $text-color-alt;
          }
        }

        input {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.edit_profile_info {
  &_modal {
    p {
      color: $text-color;
    }

    :global {
      .modal-dialog {
        width: 400px;
        max-width: 514px;
      }

      .modal-content {
        padding: 40px 35px;

        @include max(575.98) {
          padding: 22px;
        }
      }

      .ReactCrop__child-wrapper {
        position: relative;
        max-width: 335px;
        width: 335px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
        }

        &::after {
          content: "";
          display: block;
          height: 0;
          width: 100%;
          padding-bottom: 100%;
        }
      }
    }

    &.profile_image_upload {
      :global {
        .modal-dialog {
          max-width: 407px;
          margin: 0 auto;
        }
      }

      .file_upload {
        &_draggable_area {
          // max-width: 335px;
          // width: 100%;
          // height: 335px;
          // border-radius: 6px;
          // border: 2px dashed #23b070;
          // background: $file-upload-bg;
          // margin-bottom: 13px;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          // transition: 0.3s;

          position: relative;
          margin-bottom: 13px;

          :global {
            .ReactCrop {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
              max-width: 100%;
              max-height: 100%;

              &__child-wrapper {
                height: 100%;

                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          &::after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: 100%;
          }
        }

        &_image {
          margin-bottom: 23px;
          width: 52px;

          img {
            width: 100%;
          }
        }

        &_input {
          display: none;

          &_label {
            color: #23cc2b;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              filter: brightness(0.8);
            }
          }
        }

        &_info {
          font-size: size(15);
          font-weight: $font-medium;
          color: $text-color-alt;
          max-width: 145px;
          margin: 0 auto;
          margin-bottom: 10px;
          text-align: center;

          span {
            span {
              font-size: size(14);
            }
          }
        }

        &_message_info {
          font-size: size(14);
          font-weight: $font-regular;
          line-height: 16px;
          color: $text-color;
          margin-bottom: 5px;
          text-align: center;
        }
      }
    }

    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;

      &::before {
        content: "";
        background: $white;
        position: absolute;
        right: 45px;
        width: 14px;
        height: 14px;
      }

      &[x-placement="bottom-start"] {
        transform: translate(0px, 63px) !important;

        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }

      &[x-placement="top-start"] {
        transform: translate(0px, -64px) !important;

        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }

      a {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        transition: 0.3s ease all;

        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }
    }
  }

  &_title {
    font-size: size(24);
    font-weight: $font-medium;
    color: $text-color;
    margin-bottom: 22px;

    @include max(575.98) {
      font-size: size(20);
    }
  }

  &_btn_wrap {
    display: flex;
    justify-content: flex-end;

    button {
      height: 42px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: inherit;
      margin: 0 6px;

      @include max(575.98) {
        margin: 0 4px !important;
        font-size: size(14);
        max-width: 108px !important;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.edit_profile_info_modal {
  .edit_profile_info_btn_wrap {
    justify-content: flex-end;
    padding-top: 10px;

    :global {
      .btn-border-grey {
        &:hover,
        &:focus {
          border-color: #000;
          background-color: #000;
          color: #fff;
        }
      }

      .btn-tertiary {
        &:hover,
        &:focus {
          background-color: $btn-primary-hover;
          border-color: $btn-primary-hover;
          color: $white;
        }
      }
    }
  }

  &.profile_image_upload {
    .edit_profile_info_btn_wrap {
      justify-content: center;
    }
  }
}

.success_message {
  font-size: size(15);
  font-weight: $font-regular;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .success {
    color: $green;
  }

  .error {
    color: $error-message;
  }
}

.field_error {
  color: $error-message;
  font-size: size(14);
  font-weight: $font-regular;
}

@import "../../styles/uitilities.scss";

.left_filtered_category {
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  &_list {
    display: flex;
    list-style: none;
    padding: 20px 0 18px 0;
    margin: 0;
    position: relative;

    @include max(1279.98) {
      display: flex;
      margin: 0 -22px;
      border-top: 1px solid $border-color;
      width: calc(100% + 45px);
      padding-left: 10px;
      padding-right: 10px;
    }

    @include max(575.98) {
      margin: 0 -15px;
    }

    div {
      text-transform: capitalize;
      display: inline-block;
      padding: 0 22px;
      margin: 0 8px;

      @include max(1439.98) {
        padding: 0 12px;
      }

      &::before {
        content: "";
        width: 0;
        height: 2px;
        background: $blue;
        position: absolute;
        bottom: -25px;
        left: 0;
        transition: 0.3s ease all;

        @include max(1439.98) {
          bottom: -21px;
        }

        @include max(1199.98) {
          bottom: -16px;
        }
      }

      a {
        font-weight: $font-medium;
        line-height: 1;
        color: transparent;
        transition: 0.3s ease all;
        position: relative;

        @include min(1200) {
          font-size: size(18);
        }

        @include min-max(576, 1199.98) {
          font-size: size(16);
        }

        @include max(575.98) {
          font-size: size(14);
        }

        &:before {
          content: attr(data-name);
          position: absolute;
          // font-weight: $font-regular;
          white-space: nowrap;
          color: $dashboard-menu-item;
        }

        @include max(767.98) {
          font-size: size(16);
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        a {
          color: $dashboard-btn-gray2-hover;
        }
      }
    }

    &_active {
      a {
        color: transparent !important;
        transition: none !important;

        &:before {
          font-weight: $font-medium !important;
          color: $modal-link !important;
        }
      }
    }
  }
}

.floater {
  background-color: $modal-link;
  position: absolute;
  height: 4px;
  bottom: 0px;
  border-radius: 2px;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-left: 0px !important;
}

.user_profile {
  @include min(768) {
    padding: 44px 50px 44px;
  }

  &_row {
    display: flex;
    width: 100%;
    padding: 22px;
    padding-bottom: 0;

    @include max(1279.98) {
      flex-wrap: wrap;

      :global {
        .list-group {
          flex: 0 0 100%;
          max-width: 100%;

          &,
          .list-group-item {
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }
      }
    }

    @include max(575.98) {
      padding: 15px;
      padding-bottom: 0;
    }
  }

  .shimmer-styles {
    width: 100%;
    background: $shimmer-bg-alt;
    background-image: $shimmer-gradient-alt;
    background-repeat: no-repeat;
    background-size: 800px 100%;
    border-radius: 5px;

    -webkit-animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }

  &_cover_photo {
    position: relative;
    // overflow: hidden;
    margin-bottom: 0;
    z-index: 99;
    @extend .shimmer-styles;

    @include max(575.98) {
      max-height: 183px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      object-position: center 30%;
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: calc(312 / 820 * 100%);

      @include max(575.98) {
        padding-bottom: calc(360 / 640 * 100%);
      }
    }

    .cover_photo_upload {
      position: absolute;
      bottom: 30px;
      right: 10px;
      height: 42px;
      padding: 0 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: inherit;

      @include max(1279.98) {
        // width: 42px;
        height: 38px;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #00000000;
        border: none;
        // backdrop-filter: blur(50px) brightness(1);
        backdrop-filter: brightness(1);
        bottom: 16px;
        right: 98px;
      }

      :global {
        .btn {
          opacity: 0.7;
          transition: 0.45s all ease-in-out;

          @include max(767.98) {
            opacity: 1;
            padding: 0px;
          }

          &:hover {
            opacity: 1;

            &.btn-white {
              border-color: $white;
              background-color: $white;
              color: $black;

              svg {
                path {
                  fill: currentColor;
                }
              }
            }
          }
        }
      }

      @include max(767.98) {
        right: 15px;

        svg {
          margin-top: -1px !important;
        }

        button {
          border-radius: 50%;
          overflow: hidden;
          width: 40px;
          height: 40px;
          line-height: 1;
        }

        :global {
          .dropdown-menu {
            z-index: 9999;
            right: 0 !important;
          }
        }
      }

      span {
        @include max(767.98) {
          display: none;
        }
      }

      svg {
        margin-right: 5px;

        path {
          transition: 0.3s ease fill;
        }
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
      }

      form {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        cursor: pointer;
      }

      // dropdown styles
      button {
        min-width: 42px;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: none;
        cursor: pointer;

        @include max(767.98) {
          padding: 10px 8px;

          svg {
            margin-right: 0px !important;
          }
        }

        &::after {
          display: none;
        }

        @include max(767.98) {
          width: 35px;
          padding: 0px;
          min-width: 35px;
          height: 35px;
          border-radius: 50%;
          bottom: 0px;

          svg {
            margin-right: 0px !important;
          }

          span:not(.loading) {
            display: none;
          }
        }

        svg {
          margin-top: 1px;
          margin-right: 10px;

          path {
            fill: $black;
          }
        }

        &:hover {
          svg {
            path {
              fill: $white;
            }
          }
        }

        &:focus {
          background-color: $btn-primary;
          border-color: $btn-primary;
          color: $white;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }

      :global(.dropdown-item) {
        input {
          cursor: pointer !important;
          opacity: 0;
        }
      }

      :global(.dropdown-menu) {
        background-color: $white;
        border-radius: 10px;
        padding: 13px;
        position: relative;
        left: inherit !important;

        button {
          display: block;
          text-align: left;
        }

        @include min(1280) {
          right: 22px !important;
        }

        @include min-max(768, 1279.98) {
          right: -83px !important;
        }

        @include max(1279.98) {
          right: 0px !important;
        }

        &::before {
          content: "";
          background: $white;
          position: absolute;
          right: 45px;
          width: 14px;
          height: 14px;

          @include max(767.98) {
            right: 15px;
          }
        }

        &[x-placement="bottom-start"] {
          transform: translate(0px, 50px) !important;

          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }

        &[x-placement="bottom-end"] {
          transform: translate(0px, 50px) !important;

          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }

        &[x-placement="top-start"] {
          transform: translate(0px, -50px) !important;

          &::before {
            bottom: 0;
            transform: translateY(50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-top: 1px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }

        a,
        button {
          font-size: size(17);
          font-weight: $font-regular;
          padding: 10px 20px;
          border-radius: 6px;
          overflow: hidden;
          transition: 0.5s ease all;

          &:hover,
          &:focus {
            color: $text-alt;
            background-color: $dropdown-bg-hover;
          }
        }

        button {
          background-color: transparent;
          border: none;
          position: relative;
          justify-content: flex-start;
          cursor: pointer !important;
        }
      }

      :global {
        .btn-remove {
          background-color: $delete-red;
          width: 42px;
          height: 42px;
          padding: 0px;
          margin-left: 15px;
          border: none !important;
          display: flex;
          justify-content: center;
          align-items: center;

          &:disabled {
            opacity: 1;
          }

          .loading {
            width: 15px;
            height: 15px;
            border: 2px solid #fff;
            border-bottom-color: transparent;
            border-radius: 50%;
            animation: spinner-border 0.75s linear infinite;
          }

          svg {
            margin-right: 0;

            path {
              fill: $white;
            }
          }

          @include max(767.98) {
            width: 35px;
            min-width: 35px;
            height: 35px;
            border-radius: 50%;

            span {
              display: none;
            }
          }
        }
      }
    }

    :global {
      .dropdown-toggle {
        @include max(767.98) {
          padding: 0 !important;
        }
      }

      .show > .btn-primary.dropdown-toggle {
        background-color: $btn-primary !important;
        border-color: $btn-primary !important;
        color: $white !important;

        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }

  &_profimg {
    // padding: 0 17px 20px;
    position: relative;
    width: 100%;
    flex: 0 0 142px;
    max-width: 142px;
    margin-bottom: 22px;

    @include max(767.98) {
      flex: 0 0 120px;
      max-width: 120px;
    }

    @include max(575.98) {
      flex: 0 0 78px;
      max-width: 78px;
    }

    figure {
      margin-bottom: 0px;
      position: relative;
      width: 100%;
      max-width: 142px;
      overflow: hidden;
      border-radius: 100%;

      &:before {
        content: "";
        position: relative;
        display: block;
        width: 100%;
        padding-bottom: 100%;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 100%;
      }
    }
  }

  &_details {
    // padding: 0 17px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    margin-left: 33px;

    @include max(1279.98) {
      flex: 1;
      justify-content: center;
    }

    @include max(767.98) {
      margin-left: 0;
    }

    &_row {
      border-bottom: 1px solid $dashboard-item-border;
      display: flex;
      // align-items: flex-end;
      align-items: center;

      @include max(1279.98) {
        border-bottom: none;
      }
    }

    &_name {
      font-weight: 500;
      margin-bottom: 8px;
      color: $text-color;

      @include min(1200) {
        font-size: size(34);
      }

      @include min-max(992, 1199.98) {
        font-size: size(31);
      }

      @include min-max(768, 991.98) {
        font-size: size(27);
      }

      @include min-max(576, 767.98) {
        font-size: size(25);
      }

      @include max(575.98) {
        font-size: size(22);
      }
    }

    &_links {
      display: flex;

      // flex: 1;
      @include min(1280) {
        margin-left: auto;
      }
    }

    &_main {
      padding-right: 40px;
      // width: 100%;
      // @include max(1439.98) {
      //   width: 100%;
      // }

      @include min(1280) {
        max-width: calc(100% - 150px);
      }

      @include max(767.98) {
        width: 100%;

        .user_profile_details_name_header {
          display: flex;
          align-items: center;

          .user_profile_details_name_wrap {
            margin-left: 15px;
            flex: 1;
          }

          .user_profile_details_links {
            display: flex;

            button {
              height: 32px;
              padding-top: 0;
              padding-bottom: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: size(14);
            }
          }
        }
      }
    }

    &_btn {
      background-color: transparent;
      padding: 8px 22px;
      border: 1px solid $text-color;
      color: $text-color;
      border-radius: 4px;
      font-size: size(17);
      font-weight: 500;
      margin: 12px 9px;
      transition: all 0.3s ease-in;

      @include max(1439.98) {
        &:nth-child(1) {
          margin-left: 0px;
        }
      }

      @include min(1200) {
        font-size: size(17);
      }

      @include min-max(576, 1199.98) {
        font-size: size(15);
      }

      @include max(575.98) {
        font-size: size(13);
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        margin-bottom: 0;
      }

      &.user_profile_edit_profile {
        @include min(768) {
          margin-left: auto;
        }

        &:hover {
          background-color: $text-color-alt;
          border-color: $text-color-alt;
          color: $dashboard-btn-hover;
        }
      }
    }
  }

  &_header {
    background-color: $dashboard-header-bg;
    box-shadow: 0 3px 6px rgba(#071454, 7%);

    @include min(768) {
      border-radius: 10px;
      overflow: hidden;
    }

    :global(.list-group) {
      flex-direction: row;
      flex-wrap: wrap;

      :global(.list-group-item) {
        background-color: transparent;
        display: inline-block;
        border: none;
        padding-left: 0px;
        padding-right: 33px;
        color: $text-color;

        @include min(1200) {
          font-size: size(16);
        }

        @include min-max(576, 1199.98) {
          font-size: size(14);
        }

        @include max(575.98) {
          font-size: size(13);
        }
      }
    }
  }
}

:global(body.dark) {
  .user_profile_details_row {
    border-color: #767c8d;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -1250px 0;
  }

  100% {
    background-position: 1250px 0;
  }
}

.profileImage_upload {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: $green;
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 3px #00000030;
  cursor: pointer;
  transition: 0.3s ease transform;
  will-change: transform;
  z-index: 2;

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }

  .profile_photo_upload {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &:global(.show > .btn-primary.dropdown-toggle) {
      border-color: $green;
    }

    button {
      width: 100%;
      height: 100%;
      padding: 0;
      min-width: inherit;
      border-radius: 50%;
      background-color: $green;
      border-color: $green;
      text-align: left;
      cursor: pointer;

      &::after {
        display: none;
      }

      &:focus {
        border-color: $green;
      }
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: #fff;
      }
    }

    a {
      position: relative;
    }

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;

      + label {
        cursor: pointer;
        pointer-events: none;
      }
    }

    ::-webkit-file-upload-button {
      cursor: pointer !important;
    }

    :global(.dropdown-menu) {
      background-color: $white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      right: inherit !important;
      left: -35px !important;

      &::before {
        content: "";
        background: $white;
        position: absolute;
        left: 45px;
        width: 14px;
        height: 14px;
      }

      &[x-placement="bottom-start"] {
        transform: translate(0px, 50px) !important;

        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }

      &[x-placement="top-start"] {
        transform: translate(0px, -50px) !important;

        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }

      a,
      button {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        transition: 0.5s ease all;
        cursor: pointer;

        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }

      button {
        background-color: transparent;
        border: none;
        position: relative;
        justify-content: flex-start;
        cursor: pointer !important;
      }
    }
  }
}

.RemoveImage {
  text-align: center;
  margin-top: -20px;
  background-color: transparent;
  border: 1px solid $input-border;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 45px;
  right: -20px;
  width: 42px;
  height: 42px;
  background-color: $red;
  border: 4px solid $white;
  transition: 0.3s ease transform;

  &:hover {
    transform: scale(1.1);
    will-change: transform;
  }

  svg {
    path {
      fill: $white;
    }
  }
}

.edit_profile_info {
  &_modal {
    :global {
      .modal-dialog {
        max-width: 514px;
      }

      .modal-content {
        padding: 40px 35px;

        @include max(575.98) {
          padding: 22px;
        }
      }
    }

    &.profile_image_upload {
      :global {
        .modal-dialog {
          max-width: 407px;
          margin: 0 auto;
        }
      }

      .file_upload {
        &_draggable_area {
          position: relative;
          margin-bottom: 13px;

          :global {
            .ReactCrop {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
              max-width: 100%;
              max-height: 100%;

              &__child-wrapper {
                height: 100%;

                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }

          &::after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: 100%;
          }
        }

        &_image {
          margin-bottom: 23px;
          width: 52px;

          img {
            width: 100%;
          }
        }

        &_input {
          display: none;

          &_label {
            color: #23cc2b;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
              filter: brightness(0.8);
            }
          }
        }

        &_info {
          font-size: size(15);
          font-weight: $font-medium;
          color: $text-color-alt;
          max-width: 145px;
          margin: 0 auto;
          margin-bottom: 10px;
          text-align: center;

          span {
            span {
              font-size: size(14);
            }
          }
        }

        &_message_info {
          font-size: size(14);
          font-weight: $font-regular;
          line-height: 16px;
          color: $text-color;
          margin-bottom: 18px;
        }
      }
    }
  }

  &_title {
    font-size: size(24);
    font-weight: $font-medium;
    color: $text-color-alt;
    margin-bottom: 22px;

    @include max(575.98) {
      font-size: size(20);
    }
  }

  &_btn_wrap {
    display: flex;
    justify-content: flex-end;

    button {
      height: 42px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: inherit;
      margin: 0 6px;

      @include max(575.98) {
        margin: 0 4px !important;
        font-size: size(14);
        max-width: 108px !important;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.edit_profile_info_modal {
  .edit_profile_info_btn_wrap {
    justify-content: flex-end;
    padding-top: 10px;

    :global {
      .btn-border-grey {
        &:hover {
          border-color: #000;
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
}

$color: #ddd;
$size: 50px;

.loader {
  animation: load3 1s infinite linear;
  background: #000;
  background: linear-gradient(to right, $color 10%, rgba($color, 0) 70%);
  border-radius: 50%;
  height: $size;
  margin: 5em auto;
  position: relative;
  width: $size;
}

.loader:before {
  width: 100%;
  height: 50%;
  background: $color;
  border-radius: $size $size 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.loader:after {
  background: #fff;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  100% {
    transform: rotate(360deg);
  }
}

.ProfileImageCrop {
  &__Modal {
    :global {
      .modal-dialog {
        max-width: 350px;
      }

      .modal-content {
        padding: 40px 35px;
      }
    }
  }

  &__previewImage {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 160px;
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }

    &::after {
      content: "";
      display: block;
      height: 0;
      width: 100%;
      padding-bottom: calc(180 / 261 * 100%);
    }
  }

  &__controller {
    :global {
      .ant-slider-track {
        background-color: $btn-primary-hover;
        transition: none;
      }

      .ant-slider {
        &-handle {
          border: 2px solid $btn-primary-hover;
          background-color: $btn-primary-hover;
          transition: none;
        }

        &-rail {
          border-radius: 12px;
          transition: none;
        }

        &:hover {
          .ant-slider-track {
            background-color: $primary-color;
          }

          .ant-slider-handle {
            border: 2px solid $primary-color;
            background-color: $primary-color;
          }
        }
      }
    }
  }

  &__btnWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    column-gap: 10px;

    :global {
      .btn {
        min-width: 120px;
        flex: 1;
        padding: 12px 15px;

        &-tertiary {
          color: #fff;
        }
      }
    }
  }

  &__upload {
    &Box {
      position: relative;
      overflow: hidden;
      background-color: $file-upload-bg;

      &:hover {
        background-color: $file-upload-bg;
      }

      label {
        width: 100%;
        height: 160px;
        border: 1px dashed $border-color;
        border-radius: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;

        figure {
          margin-bottom: 13px;
          max-width: 60px;

          img {
            width: 100%;
          }
        }

        p {
          font-size: size(14);
          font-weight: $font-medium;
          line-height: 16px;
          text-align: center;
          color: $text-color-alt;

          span {
            color: $green;
            text-decoration: underline;
            cursor: pointer;
            font-size: size(15);
          }
        }
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: calc(190 / 270 * 100%);
      }
    }

    &FileLimit {
      font-size: size(14);
      font-weight: $font-regular;
      line-height: 16px;
      margin-bottom: 15px;
    }
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

:global {
  .loading {
    display: flex !important;
    width: 15px;
    height: 15px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;

    &.dark {
      border-color: #fff;
      border-bottom-color: transparent;
      display: none !important;

      @include max(767.98) {
        display: flex !important;
      }
    }
  }
}

.edit_profile_info_form {
  &,
  p {
    color: $text-color;
  }
}

.btnText {
  color: #23cc2b;
  margin-left: 10px;
  cursor: pointer;
}

.otpResponse {
  color: #f85e5e !important;
  text-align: center;
  &.success {
    text-align: center;
    color: #18d140 !important;
  }
}

.message {
  color: #f85e5e !important;
}

.edit_profile_otp_update_modal_wrap {
  text-align: center;
  :global(.form-group) {
    > div {
      justify-content: center;
    }
  }
}
.modal_footer_btn {
  display: flex;
  align-items: center;
  // justify-content: end;
  button {
    border: none;
    outline: none;
    background: transparent;
    color: green;
    margin-left: 6px;
  }
}
