@import "../../styles/uitilities.scss";

.ItemCardWrap{
    
    >div{
        height: 100%;
        background-color: #c9c9c9;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 20px;
        position: relative;
    }
    h4{
        font-size: 13px;
        margin-top: 10px;
    }
}
.ItemCard__copy{
    border: none;
    background-color: transparent!important;
    color: white;
    position: absolute;
    top: 8px;
    right: 8px;
}