@import "../../styles/uitilities.scss";

.dashboard {
  display: flex;
  min-height: calc(100vh - 83px);

  background: $dashboard-bg;
  transition: padding 0.3s ease-in-out;
  will-change: padding;

  @include max(1279.98) {
    min-height: calc(100vh - 52px);
  }

  @include max(575.98) {
    margin-top: -2px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  img {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &_sidebar {
    flex: 0 0 307px;
    max-width: 307px;
    padding: 61px 36px 0;
    background: $dashboard-bg-alt;
    position: relative;
    z-index: 2;
    transition: 0.3s ease-in-out max-width, 0.3s ease-in-out padding;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include max(1599.98) {
      flex: 0 0 250px;
      max-width: 250px;
      padding: 61px 30px 0;
    }

    @include max(1199.98) {
      display: none;
    }

    &_menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    &_wrap {
      position: sticky;
      top: 132px;
    }

    :global(.advertisement-tab-swiper) {
      width: 235px;

      @include max(1439.98) {
        width: 178px;
      }
    }

    &.sidebar_collapse {
      flex: 0 0 100px;
      max-width: 100px;
      padding: 61px 30px 17px 20px;

      .collapse_holder {
        right: -30px;

        &_btn {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .dashboard_sidebar {
        &_title {
          opacity: 0;
        }

        &_links {
          li {
            span {
              opacity: 0;
            }
          }
        }

        &_menu {
          position: initial;
          overflow: hidden;
        }
      }
    }

    .collapse_holder {
      position: absolute;
      top: 10px;
      right: -30px;

      &_image {
        path {
          fill: $dashboard-bg-alt;
        }
      }

      &_btn {
        border: none;
        background: none;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        transform-origin: center;
        transition: 0.5s ease filter;

        svg {
          transition: 0.5s ease transform;
        }

        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    &_title {
      font-size: size(14);
      font-weight: $font-medium;
      color: $text-color;
      margin-bottom: 15px;
      white-space: nowrap;
      transition: 0.3s ease all;
    }

    &_links {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 35px;
      position: relative;
      z-index: 9;

      li {
        border-radius: 6px;
        margin-bottom: 5px;
        cursor: pointer;
        transition: 0.3s ease all;

        >div {
          text-decoration: none;
          color: $dashboard-menu-item;
          transition: none;
          white-space: nowrap;
          display: block;
          transition: 0.3s ease all;

          @include min(1280) {
            padding: 14px 16px;
          }

          @include max(1279.98) {
            padding: 10px 16px;
          }

          span {
            transition: 0.3s ease all;
          }

          svg {
            @include min(1280) {
              margin-right: 25px;
            }

            path {
              fill: $dashboard-menu-item;
              transition: 0.3s ease all;
            }
          }
        }

        &:hover {
          @include min(1280) {
            &:not(.dashboardTabActive) {
              background-color: $dashboard-menu-hover-bg;

              >div {
                color: $dashboard-menu-hover;

                span {
                  color: $dashboard-menu-hover;
                }

                svg {
                  path {
                    fill: $dashboard-menu-hover;
                  }
                }
              }
            }
          }
        }
      }
    }

    &_collapse {
      @include min(1200) {
        padding-left: 100px;
      }
    }

    &_expand {
      @include min(1600) {
        padding-left: 307px;
      }

      @include min-max(1280, 1599.98) {
        padding-left: 238px;
      }
    }
  }
  :global(.dashboard-tab-active):hover,
  :global(.dashboard-tab-active) {
    >div {
      color: $dashboard-menu-active !important;

      svg {
        path {
          fill: $dashboard-menu-active;
          // @include max(1279.98) {
          //   fill: $dashboard-menu-item;
          // }
        }
      }
    }

    @include min(1280) {
      background-color: $dashboard-menu-active-bg;
      width: 100%;
    }
  }

  &_outlet {
    flex: 1;
    z-index: 1;
    position: relative;

    @include max(991.98) {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    :global {

      .FIE_topbar-reset-button,
      .FIE_topbar-save-button {
        border-radius: 20px;
        min-width: 100px;
      }
    }
  }

  .ad_sec {
    transition: 0.3s ease all;
  }

  :global(.amily-users-ad),
  :global(.advertisement-tab-swiper) {
    animation: fadeInAd 0.5s ease-in-out 0s forwards;
  }

  .dashboard_sidebar {
    position: fixed;
    left: 0;
    top: 83px;
    height: calc(100vh - 83px);
    padding-top: 0;

    >div {
      opacity: 0;
      animation: fadeInAd 0.5s ease-in-out 0.3s forwards;
    }

    &.sidebar_collapse {

      :global(.family-users-ad),
      :global(.advertisement-tab-swiper) {
        animation: fadeOutAd 0.2s ease-in-out 0s forwards;
        pointer-events: none;
      }

      .dashboard_sidebar_links {
        li {
          overflow: hidden;
        }
      }
    }
  }

  @include max(1199.98) {
    padding-bottom: 43px;
  }
}

.onlineStatus {
  position: absolute;
  top: 96%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.dashboard_sidebar_mob {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: $dashboard-bg-alt;
  z-index: 9999;
  box-shadow: 0 0 0.5px #707070;

  .dashboard_sidebar_links {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 0;
    box-shadow: 0 3px 7px rgba(#000, 0.26);

    @include max(1280) {
      margin-left: auto;
      margin-right: auto;
      // max-width: 600px;
    }

    li {
      a {
        svg {
          margin: auto;
        }
      }
    }
  }
}

@keyframes fadeInAd {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAd {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.dashboard_sidebar_menu {
  max-height: 100%;
  overflow: auto;
  width: 100%;
  padding-bottom: 30px;
  scrollbar-width: none;
  position: sticky;
  // top: 0;
  padding-top: 61px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.collapse_menu_closed {
  background-color: #23cc2b !important;
  width: 42px;
  height: 42px;
  border-radius: 42px;
}

// collapsed state icon color
// .collapse_menu_closed_icon {
//   path {
//     fill: white;
//   }
// }

:global(.language-open) {
  z-index: 99;
}

:global {
  body {
    .dashboard-page {
      background-color: $dashboard-bg !important;
    }

    [class*="dashboard_outlet"] {
      >div {
        &[class*="familyTree_tree"] {
          transition: 0.5s ease-in-out width, 0.5s ease-in-out min-width, 0.5s ease-in-out max-width;
        }
      }
    }

    &.sideDrawerOpen {
      [class*="dashboard_outlet"] {
        >div {
          &[class*="familyTree_tree"] {
            width: calc(100vw - 780px);
            min-width: 1px;
            max-width: 9999px;

            @include max(1599.98) {
              width: calc(100vw - 718px);
            }

            @include max(1199.98) {
              width: calc(100vw - 480px);
            }
          }
        }
      }
    }

    .dashboard-main,
    .myTemplate.bft-light {
      min-width: 1px;
      max-width: 9999px;
      width: 100%;
      transition: 0.5s ease-in-out width, 0.5s ease-in-out min-width, 0.5s ease-in-out max-width, 0.3s ease-in-out padding;
    }
  }


}

.sidebaritem {
  display: flex;
  position: relative;
  .tree_beta {
    width: 35px;
    height: 35px;
    transform: rotate(90deg);
    // margin-left: 60px;
    top: 0;
    right: 0;
    position: absolute;

  }
}