@import "../../styles/uitilities.scss";

// @mixin resitem($w,$h){
//   $val: calc( 10000 / $w * $h);
//   $val1: floor($val);
//   $val2: calc($val1 / 100);
//   position: relative;
//   >div{
//       position: absolute;
//       width: 100%;
//       // height: 100%;
//       height: 173px;
//       left: 0;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       // margin: auto;
//   }
//   &:before{
//       content: "";
//       position: relative;
//       display: block;
//       width: 100%;
//       padding-bottom: ($val2 * 1%);
//       pointer-events: none;
//   }
// }

// .album {
//   &_container {
//     position: absolute;
//     display: block;
//     top: 40%;
//     left: 50%;
//     text-align: center;
//   }
//   &_btn {
//     padding: 8px 25px;
//     border-radius: 5px;
//     margin: 20px 0;
//     border: none;
//     background-color: #267ffd;
//     color: white;
//   }
//   &_items{
//     &_container {
//       width: 100%;
//       padding-bottom: 18px;
//     }
//     &_row{
//       display: flex;
//       flex-wrap: wrap;
//       @include min(1350){
//         margin-left: -23px;
//         margin-right: -23px;
//       }
//       @include min-max(1200,1349.98){
//         margin-left: -18px;
//         margin-right: -18px;
//       }
//       @include min-max(768,1199.98){
//         margin-left: -15px;
//         margin-right: -15px;
//       }
//       @include min-max(576,767.98){
//         margin-left: -11px;
//         margin-right: -11px;
//       }
//       @include max(575.98){
//         margin-left: -7px;
//         margin-right: -7px;
//       }
//       >div{
//         @include min(1350){
//           max-width: 311px;
//           padding: 0 23px;
//           margin-bottom: 46px;
//           flex: 0 0 311px;
//         }
//         @include min-max(1200,1349.98){
//           max-width: 250px;
//           padding: 18px;
//           flex: 0 0 250px;

//         }
//         @include min-max(768,1499.98){
//             max-width: 25%;
//             flex: 0 0 25%;

//         }
//         @include min-max(768,1199.98){
//             padding: 15px 10px;

//         }
//         @include min-max(576,767.98){
//             max-width: 33.3333%;
//             padding: 11px;
//             flex: 0 0 33.3333%;
//         }
//         @include max(575.98){
//             max-width: 50%;
//             padding: 7px;
//             flex: 0 0 50%;

//         }
//         @include max(374.98) {
//             max-width: 100%;
//             flex: 0 0 100%;
//         }
//       }
//     }
//   }
// }

// .add_new_album{
//   @include max(1199.98) {
//     display: none;
//   }
//   &_content{
//     @include resitem(259,243);
//     cursor: pointer;
//     >div{
//       position: absolute;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       flex-direction: column;
//       border-radius: 6px;
//       background-color: $card-bg;
//       border: 1px dashed #BFC4CE;
//       color: $text-color;
//       will-change: color, border-color;
//       svg{
//         color: currentColor;
//         margin-bottom: 16px;
//         path{
//           fill: currentColor;
//         }
//         @include min-max(576, 991.9){
//           max-width: 19px;
//           max-height: 19px;
//           margin-bottom: 8px;
//         }
//         @include max(575.9){
//           max-width: 17px;
//           max-height: 17px;
//           margin-bottom: 5px;
//         }
//       }
//     }
//     &:hover{
//       >div{
//         background-color: $modal-link;
//         color: $white !important;
//         border: transparent
//       }
//     }

//   }

// }
// .new_empty_album{
//   &_folder{
//     @include resitem(259,243);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #E0E2E8;
//     border-radius: 12px;
//     color: #C0C1C7;
//     margin-bottom: 15px;
//     line-height: 157%;
//     cursor: pointer;
//     transition: color 0.3s ease-in-out;
//     svg{
//       position: absolute;
//       path{
//         fill: currentColor;
//       }
//     }
//     &:hover{
//       // color: #89abff!important;
//       color: $swiper-nav-bg !important;
//     }
//   }
//   &_name{
//     font-size: size(22);
//     font-weight: 700;
//     color: $text-color;

//   }
// }

// body:global(.dark){
//   .new_empty_album{
//     &_folder{
//       background-color: #323241;
//       color: #fff;

//     }
//   }
// }

// .add_new_album,
// .new_empty_album{
//   width: 100%;
//   @include min(1350){
//     // max-width: 284px;
//     max-width: 311px;
//     padding: 0 23px;
//     margin-bottom: 23px;
//     &_name{
//       font-size: size(22);
//     }
//     &_content{
//       >div{
//         font-size: size(21);
//       }
//     }
//   }

//   @include min-max(1200,1349.98){
//       max-width: 250px;
//       padding: 18px;
//       &_name{
//         font-size: size(20);
//       }
//       &_content{
//         >div{
//           font-size: size(19);
//         }
//       }
//   }
//   @include min-max(768,1499.98){
//     max-width: 25%;
//   }
//   @include min-max(768,1199.98){
//       padding: 15px;
//       &_name{
//         font-size: size(18);
//       }
//       &_content{
//         >div{
//           font-size: size(17);
//         }
//       }
//   }
//   @include min-max(576,767.98){
//       max-width: 33.3333%;
//       padding: 11px;
//       &_name{
//         font-size: size(16);
//       }
//       &_content{
//         >div{
//           font-size: size(15);
//         }
//       }
//   }
//   @include max(575.98){
//       max-width: 50%;
//       padding: 7px;
//       &_name{
//         font-size: size(14);
//     }
//     &_content{
//       >div{
//         font-size: size(13);
//       }
//     }
//   }
// }

.edit_my_album {
  @include max(991.98) {
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-bottom: 80px;
    padding-top: 20px;
  }
  @include max(575.98) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0;
    padding-bottom: 42px;
  }
  &,
  h4 {
    color: $text-color;
  }
  :global(.btn-close) {
    background-size: 14px;
    @include max(767.98) {
      display: none;
    }
  }
  :global {
    .modal-body {
      .row {
        margin: 0;
        @include max(991.98) {
          flex-direction: column;
        }
        @include max(575.98) {
          margin-right: 0;
          width: 100%;
          --bs-gutter-x: 0;
        }
      }
    }
  }
  :global(.modal-dialog) {
    max-width: 1131px;
    @include max(1199.98) {
      padding: 15px;
    }
  }
  &_leftside {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6f6;
    min-height: 560px;
    // min-height: max(calc(100vh - 506px), 283px);
    @include max(991.98) {
      min-height: 400px;
    }
    @include max(575.98) {
      flex-direction: column;
      min-height: 320px;
      .edit_album_head {
        position: relative;
        margin-bottom: 22px;
        width: 100%;
        text-align: center;
        .back_button {
          border: none;
          background-color: transparent;
          position: absolute;
          top: 50%;
          left: 15px;
          transform: translateY(-50%);
          svg {
            path {
              fill: $black;
            }
          }
        }
      }
      h4 {
        font-size: size(18);
        font-weight: $font-medium;
        color: $black;
        margin-bottom: 0;
      }
    }
  }
  &_rightside {
    padding: 35px;
    @include max(575.98) {
      padding: 20px 16px;
    }
    :global(.form-control) {
      padding: 12px 15px;
      border-color: $input-border;
      @include max(575.98) {
        font-size: size(14);
        line-height: 16px;
      }
    }
    :global(.textarea) {
      border: 1px solid $input-border;
      padding: 0 0 12px 0;
      border-radius: 10px;
      overflow: hidden;
      background-color: $input-background;
      textarea {
        border: none;
        border-radius: 0;
        height: 60px;
      }
    }
    textarea {
      &::-webkit-scrollbar {
        width: 4px;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #252533;
      }
      &::-webkit-scrollbar-track {
        background: rgba(224, 226, 232, 0.5);
      }
    }
  }
  @include max(575.98) {
    overflow: auto;
    :global(.modal-dialog) {
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 0;
    }
    :global(.modal-content) {
      border-radius: 0px !important;
    }
  }
  &_mob_header {
    display: flex;
    padding: 6px 40px 6px 10px;
    align-items: center;
    justify-content: center;
  }
  &_close {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 16px;
    }
  }
  &_title_wrap {
    text-align: center;
    width: 100%;
  }
  &_title {
    font-size: size(18);
    margin-bottom: 0px;
  }
  @include max(767.98) {
    :global(.modal-body) {
      > div {
        margin-bottom: auto;
      }
    }
  }
}
.select_a_frame_col {
  height: 110px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 35px;
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(#e0e2e8, 50%);
  }
  &::-webkit-scrollbar-thumb {
    background: #252533;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.select_a_frame_row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -9px;
  margin-right: -9px;
}
.edit_album_submit_btn {
  padding: 12px 15px;
}

.btnItem {
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #085cfd;
  color: #fff;
  border: none;
  min-height: 50px;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  font-weight: 600;
  display: block;
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
  cursor: pointer;
}

.album {
  &_container {
    padding: 0 10px;
  }
}
.create_album_modal {
  :global {
    .modal-dialog {
      max-width: 385px;
    }
  }
}

.delete_account {
  padding: 40px;
  @include max(575.98) {
    padding: 22px;
  }
  .field_error {
    margin-bottom: 30px !important;
  }
  &_title {
    font-size: size(24);
    font-weight: $font-bold;
    color: $text-color-alt;
    text-align: left;
    margin-bottom: 10px;
    @include max(575.98) {
      font-size: size(20);
    }
  }
  &_description {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.61;
    line-height: 28px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  :global(.form-group) {
    margin-bottom: 20px;
    label {
      padding-bottom: 10px;
    }
  }
  :global(.error-msg) {
    color: rgb(255, 98, 97);
  }
  &_btn_wrap {
    display: flex;
    justify-content: flex-end;
    button {
      font-size: size(14);
      font-weight: $font-medium;
      margin: 0 4px !important;
      min-width: inherit;
      height: 42px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:focus {
        color: $green;
      }
      @include max(575.98) {
        padding: 0 34px;
        width: max-content !important;
        min-width: 112px;
      }
      @include max(374.98) {
        &:first-child {
          margin-bottom: 10px;
        }
      }
      @include min(576) {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &:global(.btn-tertiary) {
        &:focus {
          color: $white;
        }
      }
    }
  }
}
.field_error {
  input {
    border-color: rgb(255, 98, 97);
  }
}

.delete_account_modal {
  .input_wrap {
    position: relative;
  }
  .icon_visibility {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    svg {
      path {
        fill: $text-color-alt;
      }
    }
  }
  .errors_list {
    padding-top: 5px;
    ul {
      list-style: none;
      padding-left: 15px;
      margin-bottom: 0;
      li {
        font-size: size(13);
        color: red;
        position: relative;
        &::before {
          content: "*";
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.lockerror {
  display: flex;
  justify-content: center;

  p {
    color: red;
    font-weight: 600;
    margin: 0px;
  }
}
.otp_continue_btn_loader {
  position: relative;
}
.otp_continue_btn_loader::after {
  content: ".";
  animation: dots 2s steps(5, end) infinite;
  font-size: 40px;
  line-height: 1px;
  vertical-align: top;
  position: absolute;
  left: 30%;
  top: 25%;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

.modalMini {
  :global(.modal-dialog) {
    max-width: 500px;
    @include max(1199.98) {
      padding: 15px;
    }
  }
}
