@import "../../styles/uitilities.scss";

.image_gallery {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  :global(.my-masonry-grid_column) {
    flex: 0 0 20%;
    max-width: 20%;
    width: 100% !important;
    @include max(1599.98) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @include max(991.98) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
    @include max(575.98) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include max(374.98) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    // @include max(424.98) {
    //   flex: 0 0 100%;
    //   max-width: 100%;
    // }
  }
}

:global(.masonry-wrap[data-masonry]) {
  margin-top: 45px;
  margin-left: -10px;
  margin-right: -10px;
  width: auto;
  display: block;
  @include max(575.98) {
    margin-top: 25px;
  }
}
:global(.my-masonry-grid) {
  width: 100%;
  margin-left: -6px;
  margin-right: -6px;
}

:global(.customRightClick) {
  position: fixed;
  max-width: 165px !important;
  width: 100% !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;

  ul {
    list-style: none;
    padding: 0px;
  }
  li {
    font-size: 14px;
    display: block;
    text-decoration: none;
    padding: 10px 15px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s;
    position: relative;
    margin-bottom: 2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    outline: none;

    &:hover {
      background-color: rgba(95, 140, 134, 0.267);
    }
    svg {
      margin-right: 10px;
    }

    p {
      margin: 0px;
    }
  }
}

.longPressFavIcon {
  svg {
    margin: 0px;
    width: 22%;
    height: 22%;
  }
}

// loader

@keyframes loader_spinner {
  0% {
    transform: translate(6px, 40px) scale(0);
  }
  25% {
    transform: translate(6px, 40px) scale(0);
  }
  50% {
    transform: translate(6px, 40px) scale(1);
  }
  75% {
    transform: translate(40px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(1);
  }
}
@keyframes loader_spinner-r {
  0% {
    transform: translate(74px, 40px) scale(1);
  }
  100% {
    transform: translate(74px, 40px) scale(0);
  }
}
@keyframes loader_spinner-c {
  0% {
    background: #f9d028;
  }
  25% {
    background: #42cb33;
  }
  50% {
    background: #5562fc;
  }
  75% {
    background: #f9d028;
  }
  100% {
    background: #42cb33;
  }
}
.loader_spinner div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px, 40px) scale(1);
  background: #f9d028;
  animation: loader_spinner 2s infinite cubic-bezier(0, 0.5, 0.5, 1);
}
.loader_spinner div:nth-child(1) {
  background: #42cb33;
  transform: translate(74px, 40px) scale(1);
  animation: loader_spinner-r 0.5s infinite cubic-bezier(0, 0.5, 0.5, 1),
    loader_spinner-c 1s infinite step-start;
}
.loader_spinner div:nth-child(2) {
  animation-delay: -0.5s;
  background: #5562fc;
}
.loader_spinner div:nth-child(3) {
  animation-delay: -1s;
  background: #f9d028;
}
.loader_spinner div:nth-child(4) {
  animation-delay: -1.5s;
  background: #42cb33;
}
.loader_spinner div:nth-child(5) {
  animation-delay: -2s;
  background: #5562fc;
}
.loader_spinner_wrap {
  height: 65px;
  display: flex;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  justify-content: center;
  margin-top: 50px;

  &.noData_loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.loader_spinner {
  width: 100%;
  height: 100%;
  max-width: 97px;
  position: relative;
  transform: translateZ(0) scale(0.7);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.loader_spinner div {
  box-sizing: content-box;
}

.image_container {
  &.shake_active {
    animation: rearrangeShake 1.2s linear infinite forwards;
  }
}

@keyframes rearrangeShake {
  0% {
    transform: rotate(0);
  }
  7.69% {
    transform: rotate(1deg);
  }
  15.38% {
    transform: rotate(0);
  }
  23.07% {
    transform: rotate(-1deg);
  }
  30.76% {
    transform: rotate(0);
  }
  38.45% {
    transform: rotate(1deg);
  }
  46.14% {
    transform: rotate(0);
  }
  53.83% {
    transform: rotate(-1deg);
  }
  61.52% {
    transform: rotate(0);
  }
  69.21% {
    transform: rotate(0);
  }
  76.9% {
    transform: rotate(0);
  }
  84.59% {
    transform: rotate(0);
  }
  92.28% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
