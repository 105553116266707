@import "../../../styles/uitilities.scss";

.full_view_slider_img_wrap {
  height: auto;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;


 

}
.gallery_image {
  &_tray {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 71px 38px 32px 38px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    // background-image: linear-gradient(rgba(#000000, 0), rgba(#000000, 1));
    transform: translateY(100%);
    opacity: 0;
    transition: 0.5s ease all;
    pointer-events: none;
    @media (max-height: 830px) {
      padding: 71px 20px 20px !important;
    }
    &:hover {
      opacity: 1;
      transform: translateY(0);
    }
    &_author {
      font-size: size(28);
      font-weight: $font-medium;
      margin-bottom: 6px;
      color: #fff;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media (max-height: 830px) {
        font-size: size(19) !important;
      }
    }
    &_post_date {
      font-size: size(16);
      font-weight: $font-regular;
      margin-bottom: 15px;
      color: #fff;
      @media (max-height: 830px) {
        font-size: size(15) !important;
      }
    }
    .tray_actions {
      display: flex;
      align-items: center;
      max-width: 145px;
      justify-content: space-between;
      margin-bottom: 15px;
      &_btn {
        border: none;
        background: #fff;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        margin: 0 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        svg {
          transition: 0.5s ease all;
        }
        &:hover {
          svg {
            opacity: 0.5;
          }
        }
        &.favorite_btn {
          svg {
            cursor: pointer;
            overflow: visible;
            width: 32px;
            :global(#heart) {
              transform-origin: center;
              animation: animateHeartOut 0.3s linear forwards;
            }
            :global(#main-circ) {
              transform-origin: 29.5px 29.5px;
            }
          }
        }
      }
    }
  }
}

.gallery_image {
  &_tray {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 71px 33px 17px 33px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    // background-image: linear-gradient(
    //   180deg,
    //   #00000057 0,
    //   rgba(0, 0, 0, 0.338) 3.5%,
    //   rgba(0, 0, 0, 0.324) 7%,
    //   rgba(0, 0, 0, 0.306) 10.35%,
    //   rgba(0, 0, 0, 0.285) 13.85%,
    //   rgba(0, 0, 0, 0.262) 17.35%,
    //   rgba(0, 0, 0, 0.237) 20.85%,
    //   rgba(0, 0, 0, 0.213) 24.35%,
    //   rgba(0, 0, 0, 0.188) 27.85%,
    //   rgba(0, 0, 0, 0.165) 31.35%,
    //   rgba(0, 0, 0, 0.144) 34.85%,
    //   rgba(0, 0, 0, 0.126) 38.35%,
    //   rgba(0, 0, 0, 0.112) 41.85%,
    //   rgba(0, 0, 0, 0.103) 45.35%,
    //   #0000001a 48.85%,
    //   rgba(0, 0, 0, 0.103) 52.35%,
    //   rgba(0, 0, 0, 0.112) 55.85%,
    //   rgba(0, 0, 0, 0.126) 59.35%,
    //   rgba(0, 0, 0, 0.144) 62.85%,
    //   rgba(0, 0, 0, 0.165) 66.35%,
    //   rgba(0, 0, 0, 0.188) 69.85%,
    //   rgba(0, 0, 0, 0.213) 73.35%,
    //   rgba(0, 0, 0, 0.237) 76.85%,
    //   rgba(0, 0, 0, 0.262) 80.35%,
    //   rgba(0, 0, 0, 0.285) 83.85%,
    //   rgba(0, 0, 0, 0.306) 87.35%,
    //   rgba(0, 0, 0, 0.324) 90.85%,
    //   rgba(0, 0, 0, 0.338) 94.35%,
    //   rgba(0, 0, 0, 0.347) 97.85%,
    //   #00000059
    // );
    height: 100%;
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out opacity;
    &_author {
      font-size: size(28);
      font-weight: $font-medium;
      margin-bottom: 6px;
      color: #fff;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media (max-height: 830px) {
        margin-bottom: 3px;
      }
    }
    &_post_date {
      font-size: size(16);
      font-weight: $font-regular;
      margin-bottom: 15px;
      color: #fff;
      @media (max-height: 830px) {
        margin-bottom: 0;
      }
    }
    .tray_actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 15px;
      @media (max-height: 830px) {
        margin-bottom: 0;
      }
      &_btn {
        border: none;
        background: #fff;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        margin: 0 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: all;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        svg {
          transition: 0.5s ease all;
        }
        &:hover {
          svg {
            opacity: 0.5;
          }
        }
      }

      .favorite_btn {
        svg {
          path {
            fill: $black;
            &:nth-of-type(2) {
              fill: $white;
              transform: scale(0.87) !important;
              transform-origin: center;
            }
            &:last-child {
              fill: $black;
            }
          }
        }
      }
    }
  }
}

.gallery_image_wrap {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  max-height: 100%;
  margin-bottom: 0;
  display: flex;
  height: 100%;

  &.border_active{
    border-width: 10px;
    // border-radius: 12px;
    height: auto;
    border-style: inset;
    place-items: center;
    border-image-slice: 25;
    border-image-repeat: round;
    img{
      border-radius: 0px!important;
    }
    @include max(575.9){
      border-width: 5px;
    }
    @include min(1200){
      border-width: 20px;
    }
  }


  @media (max-height: 810px) {
    height: 100%;
    figure {
      height: 100%;
      img {
        height: 100%;
      }
    }
  }
  figure {
    margin-bottom: 0;
    max-width: 1024px;
    height: 100%;
    display: flex;
  }
  img {
    max-height: calc(100vh - 215px);
    // object-fit: contain;
    max-width: 100%;
    cursor: url('../../../assets/magnifier-plus.png'), zoom-in;
    height: 100%;
    object-fit: contain;
  }
}
.gallery_image_cont {
  position: relative;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  img {
    width: 100%;
    height: 100%;
    transition: 0.1s ease-in;
  }

  .delete_img {
    position: absolute;
    right: -2px;
    top: -12px;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    border-radius: 50%;
    background-color: $rearrange-close-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;
    font-size: 35px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      position: relative;
      top: -4px;
    }
  }
  &_edit {
    img {
      object-fit: cover;
      cursor: move;
    }
  }
}

.gallery_image_wrap:hover {
  .gallery_image_tray {
    transform: translateY(0);
    opacity: 1;
  }
}


.favorite_active .favorite_btn svg {
  :global(#heart) {
    transform-origin: center;
    transform: scale(0.2);
    fill: #e2264d !important;
    stroke: #e2264d !important;
    animation: animateHeart 0.3s linear forwards 0.25s;
  }
  :global(#main-circ) {
    pointer-events: none;
    transition: all 2s;
    animation: animateCircle 0.3s linear forwards;
    opacity: 1;
  }
  :global(#grp1) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(0, -30px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(10px, -50px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp2) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(30px, -15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(60px, -15px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp3) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(30px, 0px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(60px, 10px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp4) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(30px, 15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(40px, 50px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp5) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(-10px, 20px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(-60px, 30px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp6) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(-30px, 0px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(-60px, -5px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp7) {
    opacity: 1;
    transition: 0.1s all 0.3s;
    :global(#oval1) {
      transform: scale(0) translate(-30px, -15px);
      transform-origin: 0 0 0;
      transition: 0.5s transform 0.3s;
    }
    :global(#oval2) {
      transform: scale(0) translate(-55px, -30px);
      transform-origin: 0 0 0;
      transition: 1.5s transform 0.3s;
    }
  }
  :global(#grp2) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp3) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp4) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp5) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp6) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
  :global(#grp7) {
    opacity: 1;
    transition: 0.1s opacity 0.3s;
  }
}

@keyframes animateCircle {
  40% {
    transform: scale(10);
    opacity: 1;
    fill: #dd4688;
  }
  55% {
    transform: scale(11);
    opacity: 1;
    fill: #d46abf;
  }
  65% {
    transform: scale(12);
    opacity: 1;
    fill: #cc8ef5;
  }
  75% {
    transform: scale(13);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.5;
  }
  85% {
    transform: scale(17);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.2;
  }
  95% {
    transform: scale(18);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0.1;
  }
  100% {
    transform: scale(19);
    opacity: 1;
    fill: transparent;
    stroke: #cc8ef5;
    stroke-width: 0;
  }
}

@keyframes animateHeart {
  0% {
    transform: scale(0.2);
  }
  40% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animateHeartOut {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}