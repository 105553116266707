@import "../../../styles/uitilities.scss";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 475px;
  box-shadow: -2px 0px 8px rgba(7, 20, 84, 0.07);
  position: fixed;
  top: 83px;
  right: 0;
  z-index: 99;
  height: calc(100vh - 84px);

  background-color: $dashboard-bg-alt;
  justify-content: center;
  align-items: center;
  // animation: slideAnim 0.5s;
  padding-bottom: 0;

  // transform: translateX(0);
  // transition: 0.5s ease-in-out all;
  @include max(1279.98) {
    top: 0;
    padding-top: 62px;
    padding-bottom: 61px;
    height: 100vh;
  }

  @include max(1199.98) {
    padding-top: 52px;
  }

  @include max(575.98) {
    max-width: 100%;
    // padding-left: 16px;
    // padding-right: 16px;
  }

  .HistoryDrawerWrapper {
    width: 100%;
    overflow-y: auto;
    padding: 30px 25px 0px 25px;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    *,
    button>svg,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $text-color;
    }

    button>svg path {
      fill: $text-color !important;
    }

    :global {
      .accordion-item {
        background-color: var(--dashboard-bg-alt);
        border-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
      }

      .accordion-button {
        background-color: rgba(123, 165, 255, 0.19);
        border-radius: 0px !important;
      }
    }
  }
}

.closeWrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 40px;
  font-size: 0px;
  border-radius: 50%;
  z-index: 999;
  overflow: hidden;
  position: absolute;
  top: 32px;
  right: 20px;

  @include max(1279.98) {
    top: 85px;
  }

  button {
    font-size: 0px;
    border: none;
    padding: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s background-color ease-in-out, 0.3s ease-in-out opacity;

    &::after {
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $text-color-alt;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: 0.3s background-color ease-in-out;
    }

    &::before {
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: $text-color-alt;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: 0.3s background-color ease-in-out;
    }

    &:hover {
      opacity: 0.5;
    }
  }
}

.HistoryDrawerWrapper {
  display: flex;
  flex-direction: column;

  &_title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0px;
  }

  &_content {
    padding-top: 10px;

    &_dropdown {
      :global {
        .dropdown-toggle {
          width: 100%;
          text-align: left;
          background-color: transparent;
          border-color: $input-border;
          padding-left: 15px;
          font-size: 18px;
          font-weight: 400;
          background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10.637" height="6.732" viewBox="0 0 10.637 6.732"> <path id="Path_67988" data-name="Path 67988" d="M95.672-2125l4.612,4.612,4.612-4.612" transform="translate(-94.964 2125.707)" fill="none" stroke="%23000" stroke-width="2"/></svg>');
          background-repeat: no-repeat;
          background-position: right 15px center;
          background-size: 10px;

          &:active {
            border-color: $input-border;
          }

          &::after {
            display: none !important;
          }
        }

        .dropdown-menu {
          background-color: $body-background;
          width: 100%;
        }
      }
    }

  }

  &_versions {
    margin-left: -25px;
    margin-right: -25px;
    margin-top: 20px;
    max-height: calc(100vh - 220px);
    overflow: auto;

    &_title {
      opacity: 0.4;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 2px solid $border-opacity-dark;
      border-top: 2px solid $border-opacity-dark;
      width: 100%;
      text-transform: uppercase;
      padding: 16px 30px;
      margin-bottom: 0px !important;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100px;
      position: relative;
      display: block;
    }
  }

  &_footer {
    margin-top: auto;
    margin: 0 -25px;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 25px;
    display: none;
    background-color: $body-background;

    &.ShowRestoreBtn {
      display: block;

      &_btn {
        color: #fff !important;
      }
    }

    .restore_btn {
      color: #fff !important;
    }
  }

  :global {
    .accordion {
      .accordion-item {
        border-radius: 0px;
        border: none;
      }

      .accordion-button {
        padding-left: 25px;
        padding-right: 25px;
        font-size: 16px;
        font-weight: 500;
        border-radius: 0;
        border: none;
        box-shadow: none;

        &:after {
          background-size: 15px;
          background-position: center;
        }
      }

      .accordion-body {
        padding: 0px !important;
      }
    }
  }
}

.DeleteModal,
.RestoreModal {
  &_body {
    padding: 30px 46px;

    @include max(379.9) {
      padding: 15px 15px;
    }
  }

  &_header {
    padding-top: 20px;
    max-width: 310px;
    margin: auto;
    text-align: center;
    color: $text-color;

    h4 {
      margin-bottom: 8px;
      font-size: 24px;
      color: $text-color;
    }

    p {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 28px;
      color: #646464;
      white-space: pre-wrap;
    }
  }

  &_button {
    &_wrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 -7px;
    }

    &_Delete,
    &_restore,
    &_cancel {
      padding-top: 12px;
      padding-bottom: 12px;
      width: 100%;
      margin: 0 7px;
      max-width: calc(50% - 7px) !important;
      min-width: 50px !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

body {
  &:global(.dark) {
    .HistoryDrawerWrapper_versions {
      :global {
        .accordion-button:after {
          filter: invert(1);
        }
      }
    }
  }
}

.no_data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;

}