@import '../../styles/uitilities.scss';

.demo_card {
    padding: 10px;
    border-radius: 13px;
    background-color: $card-bg;
    border: 1px solid rgba(#E3E5F1, 0.69);
    box-shadow: 2px 4px 9px rgba(#403F3F, 0.14);
    display: flex;
    align-items: center;
    height: 100%;
    &_wrap {
        padding: 0 10px;
        margin-bottom: 20px;
        flex: 0 0 100%;
        max-width: 100%;
        @include min(576) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include min(992) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
        }
        @include min(1600) {
            flex: 0 0 25%;
            max-width: 25%;
        }
        a {
            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &_image {
        position: relative;
        overflow: hidden;
        flex: 0 0 127px;
        max-width: 127px;
        width: 100%;
        border-radius: 9px;
        margin-bottom: 0;
        @include max(575.98) {
            max-width: 92px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
            transition: 0.3s ease transform;
        }
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: #454545;
            opacity: 0.44;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }
        &::after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: 100%;
        }
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
        }
    }
    &_details {
        padding-left: 16px;
    }
    &_title {
        font-size: size(20);
        font-weight: $font-medium;
        line-height: 20px;
        color: $text-color-alt;
        margin-bottom: 12px;
        @include max(575.98) {
            font-size: size(16);
        }
    }
    &_description {
        font-size: size(16);
        font-weight: $font-regular;
        line-height: 24px;
        color: $text-color-alt;
        margin-bottom: 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        @include max(575.98) {
            font-size: size(14);
        }
    }
}
:global(.dark) {
    .demo_card {
        border: 1px solid $card-bg;
    }
}