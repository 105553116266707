@import "../../../../styles/uitilities.scss";

.prelogin_header {
  width: 100vw;
  padding: 0;
  background-color: $header-background;
  padding: 13px 0;
  position: fixed;
  top: 0;
  height: 73px;
  z-index: 99;
  box-shadow: 0 3px 6px #00000029;

  &.logged_in_user {
    ul {
      li {
        margin-right: 35px;
      }
    }

    .prelogin_header_nav_language {
      :global {
        button {
          // margin-left: 15px;
          margin-right: 15px;
        }
      }
    }

    .profile_dropdown {
      min-width: inherit;
      padding: 0 25px 0 0px;
    }
  }

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;

    pointer-events: none;
  }

  & > * {
    position: relative;
    z-index: 4;
  }

  :global(.container) {
    max-width: 95% !important;
  }

  &_inner_wrap {
    display: flex;
    flex-wrap: wrap;
  }

  &_logo {
    flex-basis: 200px;
    max-width: 200px;
    width: 100%;
    margin: 0;
    user-select: none;

    @include max(1439.98) {
      max-width: 160px;
      flex-basis: 160px;
      display: flex;
      align-items: center;

      img {
        width: 100%;
      }
    }

    a {
      max-width: 100%;
      display: inline-block;
    }

    img {
      max-width: 135px;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  &_nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: size(30);

    flex-basis: calc(100% - 200px);
    max-width: calc(100% - 200px);

    @include max(1439.98) {
      flex-basis: calc(100% - 160px);
      max-width: calc(100% - 160px);
    }

    @include min-max(1280, 1599.9) {
      padding-top: 3px;
    }

    justify-content: flex-end;

    &_links {
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;

        li {
          font-size: size(18);
          text-transform: capitalize;
          margin-right: 30px;

          @include max(1599.98) {
            margin-right: 25px;
          }

          @include min-max(1280, 1599.98) {
            font-size: size(16);
          }

          // @include max(1439.98) {
          //   font-size: size(16);
          // }
          button {
            background-color: transparent;
            border: none;
            transition: 0.5s ease all;

            @include min-max(1280, 1599.98) {
              font-size: size(15);
            }
          }

          a,
          a:not([href]):not([class]),
          a:not([href]):not([class]):hover,
          button {
            color: $header-menu-item;
            text-decoration: none;
            transition: 0.5s ease all;

            &:hover {
              color: $header-menu-item-active;
            }
          }

          &.active {
            a,
            button {
              color: $header-menu-item-active;
              font-weight: $font-medium;
            }
          }
        }
      }
    }

    &_search {
      width: 15px;
      flex-basis: 15px;
      max-width: 15px;
      overflow: hidden;

      input {
        width: 0%;
        display: none;
      }

      button {
        background-color: transparent;
        border: none;
        padding: 0;

        svg {
          width: 100%;

          path {
            fill: $text-color;
            transition: 0.5s ease all;
          }
        }
      }
    }

    &_language {
      margin-left: 0;
      margin-right: 25px;

      :global(.dropdown-toggle) {
        text-transform: capitalize;

        @include max(1439.98) {
          padding-right: 15px;
        }
      }

      :global(.dropdown-item) {
        text-transform: capitalize;
      }

      button {
        background-color: transparent !important;
        color: $black !important;
        min-width: 1px;
        width: auto;
        padding: 0;
        display: flex;
        align-items: center;
        padding-right: 15px;
        position: relative;

        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        transition: 0.5s ease all;

        .dropdown {
          &_flag {
            width: 18px;
            height: 18px;
            display: block;
            border-radius: 50%;
            overflow: hidden;

            margin: 0 0 -1px;
            margin-right: 6px;
            position: relative;

            img {
              position: absolute;
              top: 0;
              left: 0;
              object-fit: cover;
              -webkit-user-drag: none;
              -khtml-user-drag: none;
              -moz-user-drag: none;
              -o-user-drag: none;
              user-drag: none;
            }
          }

          &_label {
            color: $header-menu-item;
            transition: 0.5s ease all;

            @include max(1439.98) {
              font-size: size(16);
              display: none;
            }
          }
        }

        &::after {
          position: absolute;
          top: 50%;
          right: 0;

          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;

          border-top: 4px solid $border-color-2;
          transition: 0.5s ease all;
          pointer-events: none;
        }
      }
    }

    &_getdemo {
      &_btn {
        min-width: 1px;
        padding: 12px 10px;
        cursor: pointer;
        position: relative;

        @include min(1600) {
          min-width: 150px;
        }

        @include max(1599.98) {
          font-size: size(16);
        }

        &:hover {
          background-color: transparent !important;
          border-color: $text-color-alt !important;
          color: $text-color-alt !important;
        }

        a {
          color: inherit;
          transition: none;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 13px;
    position: relative;
    left: -20px !important;
    top: 20px !important;

    // transition: 0.5s ease all;
    // transition: none !important;
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 65px;
      width: 14px;
      height: 14px;
    }

    &[x-placement="bottom-start"] {
      transform: translate(0px, 34px) !important;

      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        // transition: 0.5s ease all;
      }
    }

    &[x-placement="top-start"] {
      transform: translate(0px, -64px) !important;

      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        // transition: 0.5s ease all;
      }
    }

    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.5s ease all;

      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }

  :global(.dropdown-toggle:active::after) {
    // border-bottom: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  :global(.dropdown.show) {
    .profile_dropdown {
      background-color: transparent;
      border-color: transparent;
    }
  }

  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 13px;
    position: relative;
    right: 0 !important;
    left: inherit !important;

    // transition: 0.5s ease all;
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 20px;
      transition: 0.5s ease all;
    }

    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 6px 10px;
      border-radius: 6px;
      overflow: hidden;
      transition: 0.5s ease all;

      &:hover,
      &:focus {
        color: $text-alt;
        background-color: $dropdown-bg-hover;
      }
    }
  }

  .profile {
    &_dropdown {
      display: flex;
      align-items: center;
      background-color: transparent;
      border-color: transparent;
      padding: 0 20px 0 20px;
      transition: 0.5s ease all;

      // @include max(1599.98) {
      //   padding: 0 20px 0 20px;
      // }
      &:active {
        background-color: transparent;
        border-color: transparent;
      }

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-75%);
        right: 0;
        border-top: 7px solid $border-color-2;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        transition: 0.5s ease all;
      }

      + [x-placement="bottom-start"] {
        transform: translate(0px, 42px) !important;
      }

      + [x-placement="top-start"] {
        transform: translate(0px, -64px) !important;
      }
    }

    &_image {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      margin-bottom: 0;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        transition: 1s ease all;
      }

      &::after {
        content: "";
        display: block;
        height: 0;
        width: 100%;
        padding-bottom: 45%;
      }
    }

    &_details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 11px;

      .username {
        font-size: size(16);
        font-weight: $font-medium;
        padding-bottom: 3px;
        color: $profile-details-color;
        transition: 0.5s ease all;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ownership {
        font-size: size(14);
        font-weight: $font-regular;
        color: $profile-details-color;
        opacity: 0.58;
        transition: 0.5s ease all;
      }
    }
  }
}

.logoutSection {
  height: 200px;
  display: flex;
  justify-content: center;
  color: $text-color;
  flex-direction: column;

  .title {
    text-align: center;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  li {
    list-style: none;
    background-color: #1d5eff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  li:nth-child(2) {
    background: black;
  }
}

@include max(1199.98) {
  :global(body) {
    padding-top: 52px;
  }

  .loggedin_header {
    padding: 8px 0;
  }
}

.logoutsection_btn_wrap {
  button {
    max-width: 100px;
    min-width: 74px;
    margin: 0 10px !important;
    display: inline-block !important;

    &:global(.btn-light) {
      background-color: transparent;
      border-color: var(--btnhover2);
      color: $text-color;

      &:hover {
        background-color: var(--btnhover2);
        border-color: var(--btnhover2);
      }
    }
  }
}

.page_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: block;
  background-color: white;
  z-index: 10000;
  pointer-events: none;
  animation: bodyfadein 0.3s ease-in-out 0.1s forwards;
}

@keyframes bodyfadein {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.flag_Img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.shimmerBox {
  display: flex;
  align-items: center;
}
.profile_name_round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $shimmer-bg;
  margin-right: 16px;
}
.profile_name_shimmer {
  width: 75px;
  height: 25px;
  border-radius: 3px;
  background-color: $shimmer-bg;
  @extend .shimmer-styles;
}

.shimmer-styles {
  border-radius: 5px;
  width: 130px;
  background: $shimmer-bg;
  background-image: $shimmer-gradient;
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}
