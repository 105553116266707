@import "../../../styles/uitilities.scss";

.list_album {
  .preselection {
    opacity: 0.5;
  }

  .selection_mode {
    // pointer-events: none;
  }

  &_item {
    margin-bottom: 22px;
    display: flex;
    flex-direction: column-reverse;
    &:has(:global(.show.dropdown)){
      z-index: 5;
      position: relative;
    }
    :global {
      .accordion-collapse {
        + .list-album-card {
          .list-album-card-collapse {
            transform: rotate(180deg);
          }
        }

        &.no-images {
          height: 0 !important;
          margin-bottom: 0 !important;
        }

        &.show {
          + .list-album-card {
            .list-album-card-collapse {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
  }

  :global {
    .accordion-collapse {
      &.show {
        margin-bottom: -15px;
      }
    }
  }

  &_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // background: $card-bg;
    border-radius: 10px;
    // padding: 20px;
    padding: 10px 10px 10px 20px;
    background-color: #7e5a50;
    position: relative;

    &.disabled {
      filter: brightness(0.7);

      .list_album_card_collapse,
      .list_album_card_options {
        pointer-events: none;
      }
    }

    .lock_btn {
      cursor: pointer;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .accordion_toggle_overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: none;
    }

    &_title {
      display: flex;
      // font-size: size(20);
      font-size: size(22);
      font-weight: $font-medium;
      // margin-bottom: 10px;
      // color: $text-color-alt;
      color: #fff;
      margin-bottom: 0;

      @include max(575.98) {
        font-size: size(18);
      }

      span {
        display: block;
        padding: 4px 8px;
        font-size: size(13);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        margin-left: 5px;
        margin-top: 1px;
        background-color: $blank-folder-outer;
        color: $text-color-alt;
      }
    }

    &_description {
      font-size: size(14);
      font-weight: $font-regular;
      line-height: 22px;
      color: $text-color-alt;
      opacity: 0.75;
      margin-bottom: 0;
      @include line-clamp(2);
    }

    &_data {
      cursor: pointer;
      max-width: 683px;
      z-index: 3;
      &:before {
        content: "";
        width: 100%;
        position: absolute;
        inset: 0;
        display: block;
        height: 100%;
      }
      // position: relative;
    }

    &_menu {
      display: flex;
      align-items: center;
      z-index: 4;
      position: relative;
      span {
        color: #fff;
        font-size: 16px;
        margin-right: 14px;
        display: inline-block;
      }
      button {
        background-color: transparent;
        border: none;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 6px;
        border-radius: 50%;
        transition: 0.3s ease background-color;
        position: relative;
        z-index: 9999;

        svg {
          path {
            // fill: $text-color-alt;
            fill: #fff;
          }
        }

        &.list_album_card_collapse {
          transition: 0.3s ease transform;
          // z-index: 1000;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          // background-color: $placeholder-image;
          background-color: #8e695f;
        }
      }
    }

    &_options {
      border-radius: 50%;
      width: 32px;
      height: 32px;

      &:global(.show > .btn-primary.dropdown-toggle) {
        border: none;
      }

      &_btn {
        background-color: transparent;
        border: none;
        width: 32px;
        height: 32px;
        padding: 0;
        min-width: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 !important;

        &::after {
          display: none;
        }

        &:hover {
          background-color: transparent;
        }

        &:focus {
          background-color: $placeholder-image;
          border: none;
          border-radius: 50%;
        }
      }

      :global(.dropdown-menu) {
        background-color: $white;
        border-radius: 10px;
        padding: 13px;
        position: relative;

        &::before {
          content: "";
          background: $white;
          position: absolute;
          right: 45px;
          width: 14px;
          height: 14px;
        }

        &[x-placement="bottom-start"] {
          transform: translate(-90px, 40px) !important;

          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }

        &[x-placement="bottom-end"] {
          transform: translate(35px, 40px) !important;

          &::before {
            top: 0;
            transform: translateY(-50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 0 0 5px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
          }
        }

        &[x-placement="top-start"] {
          transform: translate(-90px, -40px) !important;

          &::before {
            bottom: 0;
            transform: translateY(50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-top: 1px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }

        &[x-placement="top-end"] {
          transform: translate(35px, -40px) !important;

          &::before {
            bottom: 0;
            transform: translateY(50%) rotate(135deg);
            border: 1px solid transparent;
            border-radius: 0 5px 0 0;
            border-top: 1px solid $border-color;
            border-right: 1px solid $border-color;
          }
        }

        a {
          font-size: size(17);
          font-weight: $font-regular;
          padding: 6px 10px;
          border-radius: 6px;
          overflow: hidden;
          transition: 0.5s ease all;

          &:hover,
          &:focus {
            color: $text-alt;
            background-color: $dropdown-bg-hover;
          }
        }
      }

      svg {
        path {
          fill: $text-color-alt;
        }
      }

      &:hover {
        // background-color: $placeholder-image;
        background-color: #8e695f;
      }
    }
  }

  &_images {
    margin-top: 26px;
    color: $text-color-alt;

    :global {
      .masonry-wrap {
        margin-top: 0;
      }

      .my-masonry-grid {
        margin-left: 0;
        margin-right: 0;
      }
    }

    @include max(767.98) {
      // padding: 0 6px;
      :global(.masonry-wrap[data-masonry]) {
        margin-left: -6px;
        margin-right: -6px;
      }
    }
  }

  .selection {
    margin-right: 20px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    //position: absolute;
    z-index: 10;
    bottom: 15px;
    right: 15px;
    border: 2px solid #bbafaf;
    //box-shadow: 0 0 100vmin 100vmin #00000062;
    pointer-events: none;
  }

  .gallery_select_tick {
    margin-right: 20px;
    // position: absolute;
    z-index: 5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #1d5eff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    width: 25px;
    height: 25px;
    bottom: 25px;
    right: 25px;
  }
}

.delete_this_photo_modal {
  color: $text-color;

  :global(.modal-dialog) {
    max-width: 474px;
  }

  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
  }

  :global(.modal-header) {
    height: auto;
  }

  :global {
    .custom-checkbox {
      align-items: flex-start;

      span {
        font-size: size(14);
        line-height: 19px;
        color: $text-color-alt;
        opacity: 0.49;
        padding-left: 3px;
      }

      .checkmark {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        margin-top: 2px;
        border-radius: 2px;
        background-color: $modal-bg;

        &::after {
          position: absolute;
          top: 3px;
          left: 2px;
          border-radius: 0;
          width: 10px;
          height: 5px;
          background: transparent;
          border: 1px solid transparent;
          border-left: 1px solid #fc4343;
          border-bottom: 1px solid #fc4343;
          transform: rotate(-45deg);
        }
      }

      input {
        &:checked {
          + .checkmark {
            border-radius: 2px;
            border: 1px solid #fc4343;
          }
        }
      }
    }
  }

  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;

    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }

    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;

      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }

  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }

  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }

  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
  }
}

.spinner {
  width: 35px;
  height: 35px;
  margin-left: 50px;
}

.delete_account {
  padding: 40px;

  @include max(575.98) {
    padding: 22px;
  }

  .field_error {
    margin-bottom: 30px !important;
  }

  &_title {
    font-size: size(24);
    font-weight: $font-bold;
    color: $text-color-alt;
    text-align: left;
    margin-bottom: 10px;

    @include max(575.98) {
      font-size: size(20);
    }
  }

  &_description {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.61;
    line-height: 28px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  :global(.form-group) {
    margin-bottom: 20px;

    label {
      padding-bottom: 10px;
    }
  }

  :global(.error-msg) {
    color: rgb(255, 98, 97);
  }

  &_btn_wrap {
    display: flex;
    justify-content: flex-end;

    button {
      font-size: size(14);
      font-weight: $font-medium;
      margin: 0 4px !important;
      min-width: inherit;
      height: 42px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:focus {
        color: $green;
      }

      @include max(575.98) {
        padding: 0 34px;
        width: max-content !important;
        min-width: 112px;
      }

      @include max(374.98) {
        &:first-child {
          margin-bottom: 10px;
        }
      }

      @include min(576) {
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &:global(.btn-tertiary) {
        &:focus {
          color: $white;
        }
      }
    }
  }
}

.field_error {
  input {
    border-color: rgb(255, 98, 97);
  }
}

.delete_account_modal {
  .input_wrap {
    position: relative;
  }

  .icon_visibility {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    svg {
      path {
        fill: $text-color-alt;
      }
    }
  }

  .errors_list {
    padding-top: 5px;

    ul {
      list-style: none;
      padding-left: 15px;
      margin-bottom: 0;

      li {
        font-size: size(13);
        color: red;
        position: relative;

        &::before {
          content: "*";
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.disableTab {
  pointer-events: none;
}
