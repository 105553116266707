@import "../../../../styles/uitilities.scss";

.right_filtered_category {
  display: flex;
  justify-content: flex-end;
  @include min(1280) {
    width: 100% !important;
    &_options {
      width: 100% !important;
      :global {
        .dropdown-menu {
          &:before {
            opacity: 0;
            display: none;
          }
        }
      }
    }
  }
  @include max(1199.9) {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid $input-border;
  }

  &_options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: max-content;
    // .dashboard_dropdown{
    //   min-width: 200px;
    // }
    button {
      &.add_new_btn {
        @include min-max(1280, 1699.98) {
          margin-right: 2.5px !important;
        }
      }
      &:active {
        border-color: $body-background !important;
        color: $body-background !important;
        background-color: $text-color !important;
      }
    }

    @include max(1279.98) {
      width: 100% !important;

      > * {
        width: auto !important;

        &:nth-child(1) {
          margin-left: 0px !important;
          margin-right: auto !important;
        }
      }
    }

    @include min(1420) {
      > * {
        display: inline-block;
      }
    }
    @include min-max(1200, 1499.9) {
      :global {
        .dropdown-menu {
          padding: 10px;

          a.dropdown-item {
            font-size: 14px !important;
            // padding: 5px 10px 10px 20px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  .currentItemCount {
    color: $text-color-alt;
    margin-right: 10px;
    opacity: 0.5;

    @include max(1439.98) {
      display: none;
    }
  }

  .dashboard_btn {
    min-width: inherit !important;
    padding: 11px 14px;
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 20px;
    margin: 0 7.5px;
    display: flex;
    align-items: center;

    @include max(1799.9) {
      padding: 11px 10px;
    }

    @include max(1699.98) {
      margin: 0 3px;
      padding: 4px 10px;
      font-size: size(16);

      &:first-child {
        margin-left: 0;
      }
    }

    &:global(.btn-border-secondary) {
      background-color: transparent;
      border: 1px solid $dashboard-add-btn;
      color: $dashboard-add-btn;

      &:hover {
        background: var(--dashboard-add-btn-bg-hover);
        border: 1px solid var(--dashboard-add-btn);
        color: var(--dashboard-add-btn-hover);

        svg {
          color: currentColor !important;
          path {
            fill: currentColor !important;
          }
        }
      }

      svg {
        position: relative;
        top: 1px;
        color: currentColor !important;
        path {
          // fill: $dashboard-add-btn !important;
          fill: currentColor !important;
        }
      }
    }

    &.add_new_btn {
      svg {
        path {
          fill: $btn-secondary;
        }
      }

      &:hover {
        svg {
          path {
            fill: $white;
          }
        }
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.dashboard_dropdown {
      padding: 0;
      width: max-content;
    }

    svg {
      margin-right: 5px;

      @include max(1599.98) {
        margin-right: 4px;
      }
      @include min-max(1200, 1499.9) {
        transform: scale(0.8);
      }
      path {
        // transition: 0.3s ease all;
        fill: $text-color-alt;
      }
    }

    &:global(.btn-border-grey) {
      color: $text-color-alt;
      background: transparent;
      // border: 1px solid $dashboard-add-btn-border;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: $dashboard-nav-btn-hover-text;
        background: $dashboard-nav-btn-hover-bg;
        border-color: $dashboard-nav-btn-hover;

        svg {
          color: currentColor;
          path {
            fill: currentColor;
            // fill: $dashboard-nav-btn-hover-text;
          }
        }

        &::after {
          border-right: 1px solid $dashboard-nav-btn-hover-text;
          border-bottom: 1px solid $dashboard-nav-btn-hover-text;
        }
      }
    }

    &.add_btn {
      border: 1px solid $dashboard-add-btn;
      background: transparent;
      color: $dashboard-add-btn;

      svg {
        path {
          fill: $dashboard-add-btn;
        }
      }

      &:hover {
        background: $dashboard-add-btn-bg-hover;
        border: 1px solid $dashboard-add-btn;
        color: $dashboard-add-btn-hover;

        svg {
          path {
            fill: $dashboard-add-btn-hover;
          }
        }
      }
    }

    &:global(.dropdown-toggle) {
      position: relative;
      padding-right: 40px;
      color: $text-color-alt;
      text-transform: capitalize;
      margin-right: 0 !important;

      @include max(1199) {
        padding-right: 35px;
      }

      &:hover {
        color: $dashboard-nav-btn-hover-text;
      }

      &::after {
        position: absolute;
        top: 50%;
        right: 14px;
        width: 7px;
        height: 7px;
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
        border-right: 1px solid $text-color-alt;
        border-bottom: 1px solid $text-color-alt;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

  .dashboard_btn,
  .Dropdown_toggle_btn {
    @include max(1799.9) {
      font-size: 14px;
    }
    @include min-max(1200, 1399.9) {
      font-size: 13px;
    }
    @include min-max(1200, 1799.9) {
      margin-right: 5px !important;
      margin-left: 0px !important;
      &:nth-last-child(1) {
        margin-right: 0px !important;
      }
    }
  }

  .dashboard_actions {
    &_btn {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      position: relative;
      margin: 0 15px 0 10px;

      &:hover {
        background: rgba(#000, 0.12);
      }

      &_label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
      }

      span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $text-color-alt;

        + span {
          margin-top: 2px;
        }
      }

      &::after {
        display: none;
      }
    }

    &_close {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      position: relative;

      &:hover {
        background: rgba(#000, 0.12);
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        path {
          fill: $text-color-alt;
        }
      }
    }
  }

  .selection_options {
    display: flex;
    align-items: center;

    :global(.btn-primary.dropdown-toggle) {
      background-color: transparent;
      border: none;

      &:hover {
        background-color: rgba(#000, 0.12) !important;
      }
    }

    :global(.dropdown-toggle) {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        border-top: 6px solid rgba($white, 0.46);
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
    }
  }

  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 15px 13px;
    position: relative;
    transform: translate(-30px, 51px) !important;
    li {
      display: flex;
      align-items: center;
      background: transparent !important;
      padding: 0 12px;
      transition: 0.3s ease-in;
      &:hover {
        color: $text-alt;
        background-color: #3333 !important;
        border-radius: 6px;
      }
    }
    &::before {
      content: "";
      background: $white;
      position: absolute;
      right: 30px;
      width: 14px;
      height: 14px;
    }

    &[x-placement="bottom-start"] {
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        transition: 0.5s ease all;
      }
    }

    &[x-placement="bottom-end"] {
      transform: translate(0px, 45px) !important;

      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        transition: 0.5s ease all;
      }
    }

    &[x-placement="top-start"] {
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        transition: 0.5s ease all;
      }
    }

    a {
      font-size: size(17);
      font-weight: $font-regular;
      padding: 10px 15px;
      border-radius: 6px;
      overflow: hidden;
      // text-transform: capitalize;
      background: transparent !important;

      &:hover,
      &:focus {
        color: $text-alt;
        // background-color: $dropdown-bg-hover;
      }
    }
  }

  .reArrange_actions {
    margin-right: 20px;
  }

  button {
    height: 42px;

    @include max(1699.98) {
      height: 35px;
    }
  }
}

.add_to_album {
  :global(.modal-dialog) {
    max-width: 335px;

    :global(.modal-content) {
      padding: 20px 30px;
    }

    :global(.modal-header) {
      height: auto;
      border-bottom: 1px solid $input-border;
      margin-bottom: 13px;

      :global(.modal-title) {
        color: $text-color;
      }
    }

    :global(.h4) {
      font-size: size(24);
      margin-bottom: 6px;
    }

    :global(.btn-close) {
      top: 18px !important;
      right: 19px !important;
      font-size: 15px !important;
    }
  }
}

.add_to_new_album_btn_wrap {
  font-size: size(18);
  font-weight: 500;
  margin: 13px 0;
  display: flex;
  align-items: center;

  .new_album_label {
    padding-left: 15px;
    color: $text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 58px;
  }

  &:hover {
    .add_to_album_btn {
      color: $text-alt-hover;
    }
  }
}

.add_to_album_btn {
  border: 1px solid $input-border;
  border-radius: 11px;
  color: $text-color;
  width: 58px;
  height: 58px;
  background-color: transparent;

  svg {
    color: currentColor;

    path {
      fill: currentColor;
    }
  }
}

.delete_this_photo_modal {
  color: $text-color;

  :global(.modal-dialog) {
    max-width: 474px;
  }

  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
  }

  :global(.modal-header) {
    height: auto;
  }

  :global {
    .custom-checkbox {
      align-items: flex-start;

      span {
        font-size: size(14);
        line-height: 19px;
        color: $text-color-alt;
        opacity: 0.49;
        padding-left: 3px;
      }

      .checkmark {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        margin-top: 2px;
        border-radius: 2px;
        background-color: $modal-bg;

        &::after {
          position: absolute;
          top: 3px;
          left: 2px;
          border-radius: 0;
          width: 10px;
          height: 5px;
          background: transparent;
          border: 1px solid transparent;
          border-left: 1px solid #fc4343;
          border-bottom: 1px solid #fc4343;
          transform: rotate(-45deg);
        }
      }

      input {
        &:checked {
          + .checkmark {
            border-radius: 2px;
            border: 1px solid #fc4343;
          }
        }
      }
    }
  }

  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;
    justify-content: center;

    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 11px 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }

    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;

      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }

  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }

  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }

  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
  }
}

.dashboard_select_dropdown {
  margin-left: -70px;
}

// file upload modal
.dashboard_select_dropdown {
  margin-left: -88px;
  width: 177px;
}

:global(body.dark) {
  .dashboard_cancel_btn {
    color: white;
    border-color: white;

    &:hover {
      border-color: #212529;
      color: white;
    }
  }
}

.dashboard_cancel_btn {
  background-color: transparent;
  padding: 0px;
}

.spinner {
  width: 35px;
  height: 35px;
  margin-left: 50px;
}

.albumList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 0;
  cursor: pointer;

  .gotoAction {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-5px);
    transition: 0.2s ease all;
  }

  &:hover {
    background-color: #45453942;

    .gotoAction {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
}

.listContainer {
  overflow-y: scroll;
  height: 200px;
}

.listContainer::-webkit-scrollbar {
  display: none;
}

.gotoAction {
  font-size: 2em;
  font-weight: 500;
  color: gray;
  cursor: pointer;
}

.AlbumCreateSection {
  &_input {
    font-size: size(14);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.52;
  }

  :global(.btn) {
    min-width: auto;
  }

  &_actions {
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
}

.currentItemCount {
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
}

.selection_btn_loader {
  position: relative;
}

.selection_btn_loader::after {
  content: ".";
  animation: dots 2s steps(5, end) infinite;
  font-size: 40px;
  line-height: 1px;
  vertical-align: top;
  position: absolute;
  left: 35%;
  top: 25%;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}
.Dropdown_toggle_btn {
  background-color: transparent;
  color: $text-color;
  font-weight: 400;
  min-width: 95px;
  text-transform: capitalize;
  padding: 0 11px;

  &:hover {
    background-color: $text-color;
    color: $body-background;
  }
  svg {
    margin: 0 3px;
    transform: rotate(180deg);
  }
  &:focus:not(:hover),
  &:active:not(:hover),
  &:global(.active):not(:hover),
  &:global(.show):not(:hover) {
    border-color: $text-color;
    color: $text-color;
  }
  &:global(.active),
  &:global(.show) {
    svg {
      transform: rotate(0deg) translateY(-1px) !important;
    }
    &:after {
      content: "";
      width: 15px;
      height: 15px;
      background-color: white;
      display: block;
      position: absolute;
      top: calc(100% + 6px);
      transform: rotate(45deg);
      z-index: 10000;
      border-radius: 3px;
    }
    @include max(1699.9) {
      &:after {
        top: calc(100% + 12px);
      }
    }
  }
}
.select_option_loader {
  width: 100%;
  transform: translate(6px, -12px) scale(0.4) !important;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  > div > div {
    background-color: currentColor !important;
  }
}
