@import "../../styles/uitilities.scss";

.gallery_blank_page {
  margin: 0 auto;
  min-height: calc(100vh - 330px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include min(768) {
    width: max-content;
  }
  &_wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include max(575.98) {
      max-width: 300px;
    }
    @include max(349.98) {
      // max-width: 293px;
      p {
        font-size: 1em;
      }
    }
  }
  &_image {
    margin-bottom: 58px;
    @include max(767.98) {
      max-width: 71px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }
    img {
      width: 100%;
    }
  }
  &_title {
    font-size: size(30);
    font-weight: $font-bold;
    margin-bottom: 16px;
    color: $text-color-alt;
    text-align: center;
    @include max(767.98) {
      font-size: size(18);
      margin-bottom: 5px;
      margin-top: 15px;
    }
    @include max(575.98) {
      margin-bottom: 5px;
    }
  }
  &_description {
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 28px;
    margin-bottom: 38px;
    color: $text-color-alt;
    opacity: 0.61;
    text-align: center;
    @include max(767.98) {
      font-size: size(16);
      margin-bottom: 26px;
    }
    @include max(575.98) {
      max-width: 240px;
      line-height: 1.4;
    }
  }
  &_btn {
    font-size: size(17);
    white-space: pre;
    @include max(767.98) {
      font-size: size(16);
      max-width: 170px !important;
      padding: 12px 30px;
    }
    @include max(575.6) {
      padding: 12px 15px;
    }
  }
}
