@import '../../styles/uitilities.scss';

.home_browse_photos {
    position: relative;
    padding: 57px 0 107px;
    @include max(991.98) {
        padding: 70px 0;
    }
    @include max(767.98) {
        padding: 55px 0 38px;
    }
    :global(.container) {
        @include max(991.98) {
            display: flex;
            flex-direction: column;
        }
    }
    &_title {
        margin-bottom: 35px;
        @include max(991.98) {
            order: 1;
        }
    }
    &_button {
        text-align: center;
        @include max(991.98) {
            order: 3;
        }
        :global(.btn) {
            &:focus {
                color: $white;
            }
        }
    }
    &_gallery {
        margin-top: -61px;
        padding: 0 5px;
        pointer-events: none;
        @include min(992) {
            align-items: flex-end;
            :global(.col) {
                padding-left: 6.5px;
                padding-right: 6.5px;
                @include max(1439.98) {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
        @include max(1199.98) {
            margin-top: -50px;
        }
        @include max(991.98) {
            margin-top: 50px;
        }
        @include max(991.98) {
            order: 2;
            margin-top: 0;
            margin-bottom: 25px;
        }
    }
    &_image {
        max-width: 185px;
        position: relative;
        overflow: hidden;
        border-radius: 13px;
        margin-bottom: 13px;
        transform: translateZ(0);
        background: $shimmer-bg;
        pointer-events: all;
        @include max(1439.98) {
            margin-bottom: 8px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            max-width: 100%;
            max-height: 100%;
            transition: 1s ease all;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
          
        }
        &::after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
        }
        &.aspect_1 {
            &::after {
                padding-bottom: calc(260 / 184 * 100%);
            }
        }
        &.aspect_2 {
            &::after {
                padding-bottom: calc(155 / 184 * 100%);
            }
        }
        &.aspect_3 {
            &::after {
                padding-bottom: calc(95 / 184 * 100%);
            }
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
        &_mob {
            position: relative;
            overflow: hidden;
            border-radius: 13px;
            margin-bottom: 13px;
            background: $shimmer-bg;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                max-width: 100%;
                max-height: 100%;
                transition: 1s ease all;
            }
            &::after {
                content: "";
                display: block;
                height: 0;
                width: 100%;
            }
            &.aspect_mob_1 {
                &::after {
                    padding-bottom: calc(295 / 165 * 100%);
                }
            }
            &.aspect_mob_2 {
                &::after {
                    padding-bottom: calc(161 / 165 * 100%);
                }
            }
            &.aspect_mob_3 {
                &::after {
                    padding-bottom: calc(121 / 165 * 100%);
                }
            }
            &.aspect_mob_4 {
                &::after {
                    padding-bottom: calc(226 / 343 * 100%);
                }
            }
        }
    }
    .column {
        flex: 0 0 100%;
        max-width: 100%;
        :global(.row) {
            margin: 0 -9px;
            @include max(575.98) {
                margin: 0 -6.5px;
            }
        }
        :global(.col) {
            @include min-max(576, 991.98) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 0 9px;
            }
            @include max(575.98) {
                padding: 0 6.5px;
            }
        }
        @include min-max(768, 991.98) {
            flex: 0 0 50%;
            max-width: 50%;
            &:nth-child(3n) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            &:nth-child(2) {
                .home_browse_photos_image_mob {
                    height: calc(100% - 13px);
                }
            }
        }
    }
    .anim_elements {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        .anim_elem {
            position: absolute;
            width: max-content;
            height: max-content;
            margin-bottom: 0;
            z-index: -1;
            @include max(991.98) {
                display: none;
            }
            &.circle_yellow {
                top: 5%;
                transform: translateX(calc(-49vw / 2));
                @include max(1199.98) {
                    transform: translateX(calc(-60vw / 2));
                }
            }
            &.circle_green {
                top: 37.5%;
                transform: translateX(calc(11.5vw / 2));
                @include max(1199.98) {
                    top: 42%;
                }
            }
            &.circle_grey {
                top: 9%;
                transform: translateX(calc(78vw / 2));
            }
            &.donut_grey {
                top: 43.5%;
                transform: translateX(calc(-89.5vw / 2));
            }
            &.donut_yellow {
                bottom: 10%;
                transform: translateX(calc(70vw / 2));
            }
        }
    }
}