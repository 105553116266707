@import "../../styles/uitilities.scss";

.edit_profile {
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include max(767.98) {
    padding: 30px 15px 55px;
  }
  @include max(575.98) {
    padding: 22px;
  }
  &_title {
    font-size: size(27);
    font-weight: $font-medium;
    margin-bottom: 30px;
    color: $text-color-alt;
    @include max(767.98) {
      font-size: size(22);
      text-align: left;
      margin-bottom: 15px;
    }
  }
  :global {
    .dropdown {
      &-toggle {
        color: $text-color-alt;
        background-color: $input-background;
        border-color: $input-border !important;
        text-align: left;
        font-size: size(16);
        padding: 15px 16px !important;
        width: 100%;
        font-weight: $font-regular;
        &::after {
          position: absolute;
          top: 44%;
          right: 20px;
          width: 8px;
          height: 8px;
          border: 1px solid transparent;
          border-right: 1px solid $text-color-alt;
          border-bottom: 1px solid $text-color-alt;
          transform: translateY(-50%) rotate(45deg);
          margin-left: 0;
          transition: 0.5s ease all;
        }
        &::before {
          content: "";
          width: 1px;
          height: 75%;
          // background-color: $border-color;
          background-color: $text-color;
          position: absolute;
          top: 50%;
          right: 50px;
          transform: translateY(-50%);
          opacity: 0.2;
        }
      }
      &-menu {
        width: 100%;
        background-color: $white;
        border-radius: 10px;
        padding: 13px;
        position: relative;
        a {
          font-size: size(17);
          font-weight: $font-regular;
          padding: 6px 10px;
          border-radius: 6px;
          overflow: hidden;
          transition: 0.5s ease all;
          &:hover,
          &:focus {
            color: $text-alt;
            background-color: $dropdown-bg-hover;
          }
        }
      }
    }
    .btn-wrap {
      text-align: right;
    }
    .date-input {
      position: relative;
      input {
        font-family: $font-family;
        font-size: size(16);
        font-weight: 400;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        pointer-events: none;
        path {
          fill: $text-color-alt;
        }
      }
      ::-webkit-calendar-picker-indicator {
        opacity: 0;
      }
    }
  }
  textarea {
    height: 130px;
    font-size: size(16);
    line-height: 1.2;
    color: $text-color-alt;
  }
  .error_message {
    color: $red;
    padding-top: 2px;
  }
}

:global {
  .edit-profile-modal {
    .modal-dialog {
      max-width: 824px;
      @include max(767.98) {
        margin: 0;
      }
    }
    .modal-body {
      @include max(767.98) {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
