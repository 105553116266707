.shareContent {
  .imageSection {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: calc(100vh - 120px);
      object-fit: contain;
    }
  }
  p {
    text-align: center;
  }
}
