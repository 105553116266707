@import "../../../styles/uitilities.scss";

.starredImage_wrap {
  padding: 50px;

  .right_filtered_category {
    // display: flex;
    color: $text-color-alt;
    font-size: size(14);
    font-weight: $font-regular;
    h3 {
      margin-bottom: 6px;
      color: $text-color-alt;
    }
  }

  .ImageGallery {
    padding-top: 20px;
  }
  :global {
    .my-masonry-grid {
      margin-left: 0;
      margin-right: 0;
    }
    .masonry-wrap {
      margin-top: 30px;
    }
  }
}

.gallery_filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: max(356px, 100%);
  padding-top: 14px;
  padding-bottom: 14px;
  position: relative;
  border-bottom: 2px solid $input-border;
  min-height: 72px;
  @include max(1279.98) {
    min-height: 50px;
  }
  @include max(399.9) {
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-top: 10px !important;
    }
  }
  &.favourites_view {
    // justify-content: flex-end;
    h3 {
      color: $text-color-alt;
      margin-bottom: 0;
    }
    p {
      color: $text-color-alt;
      margin-top: 6px;
    }
  }
  .favActions {
    display: flex;
  }
}

.dashboard_btn {
  min-width: inherit;
  padding: 11px 14px;
  font-size: size(18);
  font-weight: $font-regular;
  line-height: 20px;
  margin: 0 7.5px;
  @include max(1439.98) {
    margin: 0 5px;
    padding: 8px 10px;
  }
  &.rearrange_cancel_btn {
    padding-left: 25px;
    padding-right: 25px;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &.dashboard_dropdown {
    padding: 0;
  }
  svg {
    margin-right: 11px;
    path {
      transition: 0.3s ease all;
      fill: $text-color-alt;
    }
  }
  &:global(.btn-border-grey) {
    color: $text-color-alt;
    background: transparent;
    border: 1px solid $dashboard-add-btn-border;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: $dashboard-btn-hover;
      background: $text-color-alt;
      border: 1px solid $text-color-alt;
      svg {
        path {
          fill: $dashboard-btn-hover;
        }
      }
      &::after {
        border-right: 1px solid $dashboard-btn-hover;
        border-bottom: 1px solid $dashboard-btn-hover;
      }
    }
  }
  &.add_btn {
    border: 1px solid $dashboard-add-btn;
    background: transparent;
    color: $dashboard-add-btn;
    svg {
      position: relative;
      top: -2px;
      path {
        fill: $dashboard-add-btn;
      }
    }
    &:hover {
      background: $dashboard-add-btn-bg-hover;
      border: 1px solid $dashboard-add-btn;
      color: $dashboard-add-btn-hover;
      svg {
        path {
          fill: $dashboard-add-btn-hover;
        }
      }
    }
  }
  &:global(.dropdown-toggle) {
    position: relative;
    padding-right: 40px;
    color: $text-color-alt;
    text-transform: capitalize;
    margin-right: 0 !important;
    // max-width: 100% !important;
    // width: 100% !important;
    &:hover {
      color: $dashboard-add-btn-hover;
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 14px;
      width: 7px;
      height: 7px;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid $text-color-alt;
      border-bottom: 1px solid $text-color-alt;
      transform: translateY(-50%) rotate(45deg);
    }
  }
}
