@import "../../../styles/uitilities.scss";

:global(.post-date-wrap) {
  font-size: size(11);
  line-height: 120%;
  :global(.post-date-label) {
    width: 100%;
    display: block;
  }
}

.StaredImage {
  &.shake_active {
    animation: rearrangeShake 1.2s linear infinite forwards;
  }
}

@keyframes rearrangeShake {
  0% {
    transform: rotate(0);
  }
  7.69% {
    transform: rotate(1deg);
  }
  15.38% {
    transform: rotate(0);
  }
  23.07% {
    transform: rotate(-1deg);
  }
  30.76% {
    transform: rotate(0);
  }
  38.45% {
    transform: rotate(1deg);
  }
  46.14% {
    transform: rotate(0);
  }
  53.83% {
    transform: rotate(-1deg);
  }
  61.52% {
    transform: rotate(0);
  }
  69.21% {
    transform: rotate(0);
  }
  76.9% {
    transform: rotate(0);
  }
  84.59% {
    transform: rotate(0);
  }
  92.28% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
