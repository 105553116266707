@import "../../../../styles/uitilities.scss";

.left_filtered_category {
  // overflow-x: auto;
  // overflow-y: hidden;
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  &_list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    @include max(1199.98) {
      display: flex;
    }
    div {
      text-transform: capitalize;
      display: inline-block;
      padding: 0 22px;

      @include max(1439.98) {
        padding: 0 12px;
      }
      &::before {
        content: "";
        width: 0;
        height: 2px;
        background: $blue;
        position: absolute;
        bottom: -25px;
        left: 0;
        transition: 0.3s ease all;
        @include max(1439.98) {
          bottom: -21px;
        }
        @include max(1199.98) {
          bottom: -16px;
        }
      }
      a {
        font-size: size(18);
        font-weight: $font-regular;
        line-height: 1;
        color: $text-color-alt !important;
        opacity: 0.61;
        transition: 0.3s ease all;
        @include max(767.98) {
          font-size: size(16);
        }
        &.tab_active {
          opacity: 1;
          text-shadow: 0px 0px 0.3px #000;
        }
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      &:hover {
        a {
          opacity: 1;
        }
      }
    }
  }
  .floater {
    background-color: $modal-link;
    position: absolute;
    height: 2px;
    top: 36px;
    border-radius: 2px;
    transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
