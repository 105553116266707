@import "../../../styles/uitilities.scss";

.delete_account_modal {
  .input_wrap {
    position: relative;
  }
  .icon_visibility {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    svg {
      path {
        fill: $text-color-alt;
      }
    }
  }
  .errors_list {
    padding-top: 5px;
    ul {
      list-style: none;
      padding-left: 15px;
      margin-bottom: 0;
      li {
        font-size: size(13);
        color: red;
        position: relative;
        &::before {
          content: "*";
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.delete_account {
  padding: 40px;
  @include max(575.98) {
    padding: 22px;
  }
  .field_error {
    margin-bottom: 30px !important;
  }
  &_title {
    font-size: size(24);
    font-weight: $font-bold;
    color: $text-color-alt;
    text-align: left;
    margin-bottom: 10px;
    @include max(575.98) {
      font-size: size(20);
    }
  }
  &_description {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.61;
    line-height: 28px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  :global(.form-group) {
    margin-bottom: 20px;
    label {
      padding-bottom: 10px;
    }
  }
  :global(.error-msg) {
    color: rgb(255, 98, 97);
  }
  &_btn_wrap {
    display: flex;
    justify-content: flex-end;
    button {
      font-size: size(14);
      font-weight: $font-medium;
      margin: 0 4px !important;
      min-width: inherit;
      height: 42px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      // &:focus {
      //   color: $green;
      // }
      @include max(575.98) {
        padding: 0 34px;
        width: max-content !important;
        min-width: 112px;
      }
      @include max(374.98) {
        &:first-child {
          margin-bottom: 10px;
        }
      }
      @include min(576) {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &:global(.btn-tertiary) {
        &:focus {
          color: $white;
        }
      }
    }
  }
}

.lockerror {
  display: flex;
  justify-content: start;

  p {
    color: red;
    margin: 6px 0;
  }
}
