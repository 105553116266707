@import "../../styles/uitilities.scss";

.CheckoutForm {
  display: flex;
  flex-direction: column;
  &__button {
    min-width: 120px;
    padding: 4px 5px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    border: 1px solid $border-color;
    background-color: transparent;
    padding-left: 40px;
    position: relative;
    height: 45px;
    transition: 0.3s ease all;
    &:hover {
      box-shadow: 0 8px 12px #b7b7b7c7;
      transform: translateY(-2px);
    }
    &Icon {
      background-color: $primary-color;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      transition: 0.3s ease all;
      pointer-events: none;
    }
    span {
      padding-left: 15px;
      font-size: size(18);
      line-height: 1;
      margin-top: -2px;
      display: block;
    }
    &.paymentTriggered {
      &:hover {
        transform: none;
        box-shadow: none;
      }
      &.CheckoutForm__button {
        overflow: hidden;
        .CheckoutForm__buttonIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          transform: scale(4) translate(20%, -10%);
          svg {
            transform: scale(0.3) translate(-100%, -30%);
          }
        }
      }
    }
  }
}
