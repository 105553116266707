@import "../../../styles/uitilities.scss";

.fp_modal {
  width: 100%;

  &_content {
    max-width: 522px;
    width: 100%;
    min-height: 300px;
    border-radius: 20px;
    background-color: $modal-bg;
    padding: 56px 46px 50px;
    transition: all 0.3s;
    position: relative;

    @include max(575.98) {
      max-width: 100%;
      min-height: 100%;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 0;
    }

    .close_btn {
      position: absolute;
      top: 15px;
      right: 12px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .btn {
        transform: rotate(45deg);
        font-size: 35px;
        color: $text-color-alt;
      }
    }
  }

  .forgot_password {
    .email_with_change {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      label {
        flex: auto;
      }

      :global(.input-holder) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      :global(.field-error-message) {
        flex: 0 0 100%;
        max-width: 100%;
      }

    }

    .change_email {
      border: none;
      background: none;
      padding-bottom: 12px;
      color: $text-color-alt;
      opacity: 0.5;
      font-size: size(15);
      font-weight: $font-regular;
      transition: 0.3s ease all;

      &:hover {
        opacity: 1;
      }
    }

    &_header {
      margin-bottom: 36px;
      text-align: center;
    }

    &_title {
      font-size: size(26);
      font-weight: $font-bold;
      color: $text-color-alt;
      margin-bottom: 29px;

      @include max(767.98) {
        font-size: size(22);
      }
    }

    &_info {
      font-size: size(18);
      font-weight: $font-regular;
      line-height: 28px;
      color: $text-color-alt;
      max-width: 378px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }

    &_resend_otp {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-top: 10px;

      span {
        font-size: size(15);
        font-weight: $font-medium;
        color: $text-color-alt;
        padding-right: 5px;
      }

      button {
        font-size: size(15);
        font-weight: $font-medium;
        color: $modal-link;
        border: none;
        background: none;

        &:hover {
          opacity: 0.8;
        }
      }
      &_timer{
        color: green !important;

      }
    }

    :global(.otp-field) {
      input {
        color: $text-color;
        background-color: $input-background;
        border-color: $input-border;
      }
    }
  }

  .signin_button {
    margin-top: 31px;
  }
}

@media (max-height: 900px) {
  .fp_modal {
    align-items: flex-start;
    padding-top: 25px;
  }
}

.errorMessage {
  background-color: #FF888821;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  border-radius: 4px;
  margin-top: 22px;
  max-width: calc(100% - 8px);
  margin-left: 4px;

  p {
    margin: 0px;
    padding: 0px;
    color: #ff6261;
  }
}

.input_Style_error {
  border-color: #f85e5e !important;
  box-shadow: 0 0 0 1px #f85e5e;
}

.otpErrorMessage {
  margin: 0px;
  padding: 0px;
  color: #ff6261;
  font-size: 13px;
  position: absolute;
}

.otpMessage {
  // margin: 0px;
  // padding: 0px;
  // color: #3eca6d;
  position: absolute;
  bottom: 13px;
  right: 0;
}

.hideReset {
  pointer-events: none;
  opacity: 0.5;
}

.successMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5ffed;
  border-radius: 5px;
  margin-top: 10px;

  p {
    margin: 0px;
    padding: 0px;
    color: $green;
  }
}

.back_btn {
  font-size: size(15);
  font-weight: 500;
  color: var(--modal-link);
  border: none;
  background: none;

  &_wrap {
    text-align: center;
    margin-top: 15px;

    span {
      font-size: size(15);
      font-weight: 500;
      color: $text-color-alt;
      padding-right: 5px;
    }
  }

  &:hover {
    filter: opacity(0.7);
  }
}

.successMessage_wrap {
  height: 22px;
  width: 100%;
  margin-bottom: -22px;
  margin-top: 6px;

  .successMessage {
    margin-top: 0px !important;
  }
}

.show_otp_msg_response {
  position: relative;
  height: 24px;
  margin-top: 6px;
  margin-bottom: 8px;

  .errorMessage {
    position: absolute;
    top: 0;
    margin-top: 0;
    width: 100%;
  }
}

.otp_error_msg_item {
  margin-top: -18px;
}

.otp_continue_btn_loader::after {
  content: " .";
  animation: dots 2s steps(5, end) infinite;
  font-size: 40px;
  line-height: 1px;
  vertical-align: top;
}


@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}