@import "../../styles/uitilities.scss";

.preference {
  &_link {
    cursor: pointer;
    width: 100% !important;
  }
  &_dis {
    opacity: 0.7;
    font-size: size(14);
  }
  &_menu {
    :global(.dropdown-menu) {
      background-color: white;
      border-radius: 10px;
      padding: 13px;
      position: relative;
      width: 100%;
      &::before {
        content: "";
        background: $white;
        position: absolute;
        left: 20px;
        width: 14px;
        height: 14px;
        @include max(767.98) {
          left: inherit;
          right: 20px;
        }
      }
      &[x-placement="bottom-start"] {
        transform: translate(0, 54px) !important;
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }
      &[x-placement="bottom-end"] {
        transform: translate(0, 54px) !important;
        &::before {
          top: 0;
          transform: translateY(-50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 0 0 5px;
          border-bottom: 1px solid $border-color;
          border-left: 1px solid $border-color;
        }
      }
      &[x-placement="top-start"] {
        transform: translate3d(0px, -54.2px, 0px) !important;
        &::before {
          bottom: 0;
          transform: translateY(50%) rotate(135deg);
          border: 1px solid transparent;
          border-radius: 0 5px 0 0;
          border-top: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }
      a {
        font-size: size(17);
        font-weight: $font-regular;
        padding: 6px 10px;
        border-radius: 6px;
        overflow: hidden;
        &:hover,
        &:focus {
          color: $text-alt;
          background-color: $dropdown-bg-hover;
        }
      }
    }
    .language_bar_toggle {
      background-color: $body-background;
      color: $text-color;
      font-size: size(17);
      font-weight: 400;
      text-align: left;
      padding: 13px;
      width: 160px;
      height: 45px;
      border: 1px solid #a09ea6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:active,
      &:focus,
      &:hover {
        color: $text-color;
        border-color: $dashboard-dropdown-border;
      }
      &:after {
        border: 1px solid transparent;
        border-left-color: $text-alt;
        border-bottom-color: $text-alt;
        transform: rotate(-45deg);
        width: 6px;
        height: 6px;
      }
    }
    :global(.show.dropdown) {
      .language_bar_toggle {
        background-color: transparent;
        color: $text-alt;
      }
    }
    .pr_3 {
      padding-right: 1em;
    }
    .pr_4 {
      display: block;
      width: 100%;
      padding-right: 2em;
      @include max(575.9){
        padding-right: 1em;
      }
    }
    :global(.btn_darkmode_theme_toggle) {
      position: relative;
      top: 0;
      width: 110px;
      height: 33px;
      box-shadow: none;
      border: 1px solid #e0e2e8;
      border-radius: 3px;
      svg {
        width: 22px;
        height: 22px;
        top: 0;
        bottom: 0;
      }
      span {
        display: block;
        font-size: size(14);
        margin-left: 10px;
        width: 59px;
        padding-left: 5px;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid #a8a8a84a;
      }
      :global(.theme-mode) {
        display: flex;
        flex-direction: row-reverse;
      }
      :global(.theme-light) {
        span {
          margin-left: 0;
          padding-left: 0;
          margin-right: 10px;
          padding-right: 5px;
          border-left: 0;
          border-right: 1px solid #a8a8a84a;
        }
      }
    }
  }
}

.tab_item_title {
  font-size: size(30);
  font-weight: 500;
  @include min(992) {
    margin-bottom: 25px;
  }
  @include max(767.98) {
    font-size: size(18);
    padding: 0 0 15px 0;
    line-height: 100%;
    border-bottom: 2px solid $dashboard-item-border;
  }
}

.preference_box {
  display: flex;
  justify-content: space-between;

  border-bottom: inset;
  border-bottom-width: 1px;
  border-color: $dashboard-item-border;
  @include min(576) {
    padding: 15px 0;
  }
  @include max(575.98) {
    padding: 13px 0;
  }
  &:nth-last-child(1) {
    border-bottom: none !important;
  }
  &:first-child {
    @include min(768) {
      padding-top: 0;
    }
  }
  .link_title {
    display: block;
    margin-bottom: 4px;
    + .preference_dis {
      color: $text-color;
      font-weight: $font-regular;
    }
    @include max(575.98) {
      margin-bottom: 5px;
    }
  }

  .preference_form_group {
    .preference_slider_round {
      border-radius: 34px;
    }

    :global(.toggle-switch) {
      width: 48px;
      height: 28px;
      :global(.slider) {
        font-size: 13px;
        &:before {
          width: 23px;
          height: 23px;
        }
      }
    }
    .preference_box_radio_wrap {
      display: flex;
      @include min(768) {
        width: 181px;
      }
      > div {
        @include min(768) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        &:last-child {
          label {
            justify-content: flex-end;
          }
        }
      }
      :global(.custom-checkbox) {
        :global(.checkmark) {
          background-color: transparent;
        }
        span {
          color: $dashboard-menu-item;
          font-size: 17px;
          &.checkmark_checked {
            color: $text-color;
          }
        }
      }
    }
  }
  .preference_subtitle {
    font-weight: 500;
    letter-spacing: 0.36px;
    margin-bottom: 8px;
    width: max-content;
    color: $text-color-alt;
    @include min(576) {
      font-size: size(18);
    }
    @include max(575.98) {
      font-size: size(16);
      margin-bottom: 5px;
    }
  }
  .preference_form_button {
    button {
      min-width: 135px;
      border-radius: 6px;
      font-size: 17px;
      font-weight: 400;
      color: white;
      &:focus {
        background-color: $red;
      }
      @include max(575.9) {
        font-size: size(14);
        min-width: 91px;
        padding: 8px 5px;
      }
    }
  }
  :global {
    .btn_theme_toggle {
      transform: translate(0);
    }
  }
}

.language_select_btn {
  border: none;
  width: 20px;
  height: 75%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:before,
  &:after {
    content: "";
    display: block;
    border-radius: 5px;
    height: 13px;
    width: 2px;
    background-color: $text-color;
  }
  &:before {
    transform: rotate(-38deg) translate(0px, 2px);
  }
  &:after {
    transform: rotate(38deg) translate(0px, -2px);
  }
}

:global(body.dark) {
  .language_bar_toggle {
    border: 1px solid $body-background;
    &:after {
      border-left-color: white;
      border-bottom-color: white;
    }
    &:hover {
      color: white;
      background-color: black;
    }
  }
  :global(.show.dropdown) {
    .language_bar_toggle {
      color: white;
      background-color: black;
      border: 1px solid $body-background;
    }
  }
}
.language_menu_mob {
  z-index: 1100;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.7);
}

.delete_account {
  padding: 50px 46px;
  @include max(575.98) {
    padding: 22px;
  }
  .field_error {
    margin-bottom: 30px !important;
  }
  &_title {
    font-size: size(24);
    font-weight: $font-bold;
    color: $text-color-alt;
    text-align: left;
    margin-bottom: 8px;
    @include max(575.98) {
      font-size: size(20);
    }
  }
  &_description {
    font-size: size(16);
    font-weight: $font-regular;
    color: $text-color-alt;
    opacity: 0.61;
    line-height: 1.45;
    text-align: left;
    max-width: 350px;
    margin-bottom: 20px;
  }
  :global(.form-group) {
    margin-bottom: 20px;
    label {
      padding-bottom: 10px;
    }
  }
  :global(.error-msg) {
    color: rgb(255, 98, 97);
  }
  &_btn_wrap {
    display: flex;
    justify-content: flex-end;
    button {
      font-size: size(14);
      font-weight: $font-medium;
      margin: 0 4px !important;
      min-width: inherit;
      height: 42px;
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include max(575.98) {
        padding: 0 34px;
        width: max-content !important;
        min-width: 112px;
      }
      @include max(374.98) {
        &:first-child {
          margin-bottom: 10px;
        }
      }
      @include min(576) {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
      &:global(.btn-tertiary) {
        &:focus {
          color: $white;
        }
      }
    }
  }
}
.field_error {
  input {
    border-color: rgb(255, 98, 97);
  }
}

.delete_account_modal {
  .input_wrap {
    position: relative;
  }
  .icon_visibility {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    svg {
      path {
        fill: $text-color-alt;
      }
    }
  }
  .errors_list {
    padding-top: 5px;
    ul {
      list-style: none;
      padding-left: 15px;
      margin-bottom: 0;
      li {
        font-size: size(13);
        color: red;
        position: relative;
        &::before {
          content: "*";
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.change_password_modal {
  .delete_account {
    &_title {
      text-align: left;
    }
    &_btn_wrap {
      justify-content: flex-end;
      button {
        height: 40px;
      }
    }
  }
}

.delete_this_photo_modal {
  color: $text-color;
  :global(.modal-dialog) {
    max-width: 474px;
  }
  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
  }
  :global(.modal-header) {
    height: auto;
  }
  :global {
    .custom-checkbox {
      align-items: flex-start;
      span {
        font-size: size(14);
        line-height: 19px;
        color: $text-color-alt;
        opacity: 0.49;
        padding-left: 3px;
      }
      .checkmark {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        margin-top: 2px;
        border-radius: 2px;
        background-color: $modal-bg;
        &::after {
          position: absolute;
          top: 3px;
          left: 2px;
          border-radius: 0;
          width: 10px;
          height: 5px;
          background: transparent;
          border: 1px solid transparent;
          border-left: 1px solid #fc4343;
          border-bottom: 1px solid #fc4343;
          transform: rotate(-45deg);
        }
      }
      input {
        &:checked {
          + .checkmark {
            border-radius: 2px;
            border: 1px solid #fc4343;
          }
        }
      }
    }
  }
  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;
    justify-content: center;
    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 11px 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }
    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;
      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }
  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }
  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }
  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
  }
}

.btnItem {
  width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fc4343;
  color: #fff;
  border: none;
  min-height: 50px;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none;
  font-weight: 600;
  display: block;
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
  cursor: pointer;
}
