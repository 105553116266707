@import "../../../../styles/uitilities.scss";

.prelogin_mobile_header {
  width: 100%;
  padding: 0;
  background-color: $header-background;
  padding: 13px 0;
  position: fixed;
  top: 0;
  z-index: 15;
  box-shadow: 0 3px 6px #00000029;
  &_logo {
    margin-bottom: 0;
    max-width: 120px;
    display: inline-block;
    // margin-top: -5px;
    // margin-bottom: -5px;
    img {
      width: 100%;
    }
  }
  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &_nav {
    display: flex;
  }
  &_search {
    margin-right: 25px;
    svg {
      path {
        fill: $text-color;
      }
    }
  }
  &_hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    background: none;
    span {
      display: block;
      width: 25px;
      height: 2px;
      background: $text-color;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .menu_overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #00000076;
    z-index: 88;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease all;
  }
  &_menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 74px 0 0;
    background: $mobile-menu-bg;
    max-width: 440px;
    z-index: 99;
    transform: translateX(100%);
    transition: 0.7s ease transform;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    @include max(575.98) {
      max-width: 100%;
      padding-top: 0px;
    }
    ul {
      li {
        &.active {
          a {
            color: $header-menu-item-active !important;
          }
        }
      }
    }
    &.menu_open {
      transform: translateX(0);
      .prelogin_mobile_header_menu_wrap {
        opacity: 1;
      }
      + .menu_overlay {
        opacity: 1;
        visibility: visible;
      }
    }
    .close_menu {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: none;
      background: none;
      transition: 0.3s ease all;
      &:hover {
        background: $close-menu-bg;
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
          fill: $text-color-alt;
        }
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        margin-bottom: 25px;
        text-transform: capitalize;
        button {
          background-color: transparent;
          border: none;
        }
        a,
        button {
          font-size: size(18);
          font-weight: $font-regular;
          color: $text-color-alt !important;
        }
        &.submenu {
          position: relative;
          cursor: pointer;
          &::after {
            content: "";
            width: 13px;
            height: 13px;
            border: 2px solid transparent;
            border-right: 2px solid $text-color-alt;
            border-bottom: 2px solid $text-color-alt;
            background: transparent;
            transform: translateY(-50%) rotate(45deg);
            position: absolute;
            right: 20px;
            top: 50%;
            pointer-events: none;
          }
          button {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
    &_wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      opacity: 0;
      transition: 0.5s ease opacity;
      @include min(576) {
        padding-left: 15px;
      }
    }
    :global(.container) {
      height: calc( 100% - 85px );
    }
  }
  &_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 15px;
    button{
      padding-left: 8px!important;
      padding-right: 8px!important;
    }
    @include max(374.98) {
      flex-direction: column;
    }
    :global {
      .btn {
        position: relative;
        a {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    button {
      min-width: 160px;
      flex: 0 0 49%;
      @include max(575.98) {
        padding: 15px 10px;
      }
      @include min-max(375, 575.98) {
        flex: 1;
      }
      @include max(374.98) {
        min-width: 142px;
        max-width: 100% !important;
        width: 100%;
      }
      &:first-child {
        margin-right: 7px;
        @include max(374.98) {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 7px;
        }
      }
    }
  }
  
}


.logoutSection {
  height: 200px;
  display: flex;
  justify-content: center;
  color: $text-color;
  flex-direction: column;
  .title {
    text-align: center;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 500;
  }
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  li {
    list-style: none;
    background-color: #1d5eff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  li:nth-child(2) {
    background: black;
  }
}
@include max(1199.98) {
  :global(body) {
    padding-top: 52px;
  }
  .loggedin_header {
    padding: 8px 0;
  }
}

.logoutsection_btn_wrap {
  button {
    max-width: 100px;
    min-width: 74px;
    margin: 0 10px !important;
    display: inline-block !important;
    &:global(.btn-light) {
      background-color: transparent;
      border-color: var(--btnhover2);
      color: $text-color;
      &:hover {
        background-color: var(--btnhover2);
        border-color: var(--btnhover2);
      }
    }
  }
}
.prelogin_mobile_header_logo_menu{
  padding: 13px 0px 12px 0px;
  margin-left: 12px;
}
.main_menu_container{
  @include max(575.9){
    padding-top: 30px;
    // border-top: 1px solid $input-border;
  }
}