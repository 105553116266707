@import "../../../styles/uitilities.scss";

.dashboard {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 90px);
  padding: 51px 0;
  background: $dashboard-bg;
  justify-content: center;
  align-items: center;
  @include max(767.98) {
    padding: 29px 16px;
  }
  .tabs {
    max-width: 520px;
    width: 100%;
    height: 76px;
    background-color: $card-bg;
    border-radius: 6px;
    box-shadow: -2px 0px 8px #07145411;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 17px;
    position: relative;
    @include max(767.98) {
      height: 60px;
    }
    .tab {
      width: 30%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      transition: 0.3s;
      font-size: size(21);
      font-weight: $font-medium;
      color: $text-color;
      cursor: pointer;
      @include max(767.98) {
        font-size: size(16);
      }
      p {
        margin-bottom: 0;
      }
    }
    .activeTab {
      position: absolute;
      background: #23cc2b 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px #23cc2b4f;
      border-radius: 6px;
      transition: 0.3s cubic-bezier(0.27, 0.5, 0.89, 0.92);
    }
  }
  .FamilyTreeForm {
    max-width: 520px;
    width: 100%;
    background-color: $card-bg;
    box-shadow: -2px 0px 8px #07145411;
    border-radius: 6px;
    padding: 31px 48px;
    @include max(767.98) {
      padding: 26px 25px;
    }
    &_heading {
      h3 {
        font-size: size(27);
        font-weight: $font-semibold;
        margin-bottom: 32px;
        color: $text-color;
        @include max(767.98) {
          font-size: size(24);
          margin-bottom: 20px;
          text-align: center;
        }
      }
      p {
        font-size: size(21);
        font-weight: $font-semibold;
        margin-bottom: 25px;
        color: $text-color;
        line-height: 29px;
        @include max(767.98) {
          font-size: size(18);
          margin-bottom: 20px;
          text-align: center;
        }
      }
    }
    :global(.form-group) {
      margin-bottom: 25px;
      label {
        font-size: size(14);
        font-weight: $font-regular;
        margin-bottom: 12px;
        color: $text-color-alt;
        padding-bottom: 0;
        line-height: 18px;
      }
    }
    :global(.textarea) {
      margin-bottom: 35px;
    }
    :global(.form-control) {
      font-size: size(16);
      font-weight: $font-regular;
    }
    .fieldGroup {
      width: 100%;
      position: relative;
      margin-bottom: 15px;
      label {
        // color: black;
        font-size: 14px;
        line-height: 1.5;
        text-decoration: none;
        font-weight: 500;
        display: block;
        width: 100%;
        margin-bottom: 12px;
      }
      input {
        font-family: "Ubuntu", sans-serif;
        width: 100%;
        position: relative;
        height: 50px;
        border: 1px solid #d3d5db;
        border-radius: 10px !important;
        overflow: hidden;
        padding: 10px;
        font-size: 16px;
        line-height: 1;
        text-decoration: none;
        font-weight: 500;
        color: $text-opacity;
        border: 1px solid $input-border;
        &[type="date"]::-webkit-inner-spin-button,
        &[type="date"]::-webkit-calendar-picker-indicator {
          opacity: 0;
        }
        background-color: $input-background;
      }
      .date_input {
        width: 100%;
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          pointer-events: none;
          path {
            fill: $text-color;
          }
        }
      }
    }
    textarea {
      resize: none;
    }
    .skipPerson {
      height: 80px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: size(18);
      font-weight: $font-regular;
      color: $text-color;
      p {
        margin-bottom: 0;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    :global(.btn) {
      max-width: 100%;
    }
  }
}


.checkBoxWrapper {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  .checkBox_buttons {
    display: flex;
  }
  .title {
    font-size: 14px;
    line-height: 1.5;
    text-decoration: none;
    font-weight: 500;
    display: block;
    width: 100%;
    margin-bottom: 12px;
  }
  .checkBoxItem {
    width: 100%;
    border-radius: 30px;
    border: 1px solid #d3d5db;
    height: 50px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 0.3s all ease-in-out;
    label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      border: 1px solid rgb(151, 151, 151);
    }
    &.checked {
      background-color: #1d5eff;
      border: 1px solid #1d5eff;
      color: #fff;
      label {
        color: #fff;
      }
    }
  }
  label {
    color: $text-color-alt;
    font-size: size(16);
    line-height: 1.5;
    text-decoration: none;
    font-weight: $font-medium;
    display: block;
    width: 100%;
    margin-bottom: 0px;
    transition: 0.3s all ease-in-out;
  }
  input {
    font-family: "Ubuntu", sans-serif;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: pointer;
  }
}