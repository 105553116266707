@import "../../styles/uitilities.scss";

.get_started {
  width: 100vw;
  min-height: calc(100vh - 84px);
  background-color: rgba(0, 0, 0, 0.04);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include max(1199.98) {
    min-height: calc(100vh - 52px);
  }
  &_title {
    font-size: size(32);
    font-weight: $font-bold;
    margin-top: 34px;
    margin-bottom: 18px;
    color: $text-color-alt;
    max-width: 728px;
    margin-left: auto;
    margin-right: auto;
    @include max(767.98) {
      font-size: size(26);
    }
  }
  &_description {
    font-size: size(18);
    font-weight: $font-regular;
    line-height: 32px;
    color: $text-color-alt;
    opacity: 0.61;
    margin-bottom: 38px;
    max-width: 728px;
    margin-left: auto;
    margin-right: auto;
    @include max(767.98) {
      font-size: size(16);
      line-height: 26px;
    }
  }

  &_wrapper {
    width: 810px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 82px 0 61px;
    :global {
      .btn {
        a {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &_video {
    border-radius: size(20);
    border: 10px solid #fff;
    box-shadow: -2px 0 8px rgba(#071454, 0.07);
    position: relative;
    margin-bottom: 38px;
    @include max(767.98) {
      margin-bottom: 31px;
    }
    .icon_video_play_toggle {
      border: none;
      background: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      width: 100%;
      height: 100%;
      img {
        transition: 0.5s ease all;
        @include max(575.98) {
          transform: scale(0.7);
        }
      }
      &:hover {
        img {
          transform: scale(1.1);
          @include max(575.98) {
            transform: scale(0.8);
          }
        }
      }
    }
  }

  .anim_elements {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    .anim_elem {
      position: absolute;
      width: max-content;
      height: max-content;
      margin-bottom: 0;
      @include max(991.98) {
        display: none;
      }
      &.circle_yellow {
        top: 6%;
        transform: translateX(calc(-49vw / 2));
        @include max(1599.98) {
          top: 12%;
          transform: translateX(calc(-60vw / 2));
        }
      }
      &.donut_grey {
        top: 45%;
        transform: translateX(calc(-76vw / 2));
      }
      &.donut_green {
        top: 5.8%;
        transform: translateX(calc(84.4vw / 2));
      }
      &.donut_yellow {
        bottom: 19.4%;
        transform: translateX(calc(67.1vw / 2));
        @include max(1599.98) {
          transform: translateX(calc(88vw / 2));
        }
      }
    }
  }
}