




$body-background: var(--body-bg) !default;
$header-background: var(--header-bg) !default;
$text-color: var(--text-color) !default;
$text-color-alt: var(--text-color-alt) !default;
$input-background: var(--input-bg) !default;
$close-hover-bg: var(--close-hover-bg) !default;
$dark-bg-alt: var(--dark-bg-alt) !default;
$dark-bg-alt-2: var(--dark-bg-alt-2) !default;
$social-icon-color: var(--social-icon-color) !default;
$social-icon-hover: var(--social-icon-hover) !default;
$social-icon-bg: var(--social-icon-bg) !default;
$footer-bg: var(--footer-bg) !default;
$post-footer-color: var(--post-footer-color) !default;
$post-footer-bg: var(--post-footer-bg) !default;
$card-bg: var(--card-bg) !default;
$text-alt-hover: var(--text-alt-hover) !default;
$dark-bg-alt-3: var(--dark-bg-alt-3) !default;
$modal-link: var(--modal-link) !default;
$modal-bg: var(--modal-bg) !default;
$tab-bg: var(--tab-bg) !default;
$tab-active-bg: var(--tab-active-bg) !default;
$label-bg: var(--label-bg) !default;
$label-color: var(--label-color) !default;
$dark-toggle: var(--dark-toggle) !default;
$dark-toggle-bg: var(--dark-toggle-bg) !default;
$profile-details-color: var(--profile-details-color) !default;

$dashboard-loader: var(--dashboard-loader) !default;

$gradient-bg: var(--gradient-bg) !default;

$card-box-shadow: var(--card-box-shadow) !default;
$card-box-shadow-alt: var(--card-box-shadow-alt) !default;

$card-border: var(--card-border) !default;
$input-border: var(--input-border) !default;
$input-border-alt: var(--input-border-alt) !default;
$dashboard-item-border: var(--dashboard-item-border) !default;

$pagination-bullet: var(--pagination-bullet) !default;
$pagination-bullet-active: var(--pagination-bullet-active) !default;

$dashboard-bg: var(--dashboard-bg) !default;
$dashboard-bg-alt: var(--dashboard-bg-alt) !default;

$dashboard-header-bg: var(--dashboard-header-bg) !default;

$dashboard-menu-item: var(--dashboard-menu-item) !default;
$dashboard-menu-active: var(--dashboard-menu-active) !default;
$dashboard-menu-active-bg: var(--dashboard-menu-active-bg) !default;
$dashboard-menu-hover: var(--dashboard-menu-hover) !default;
$dashboard-menu-hover-bg: var(--dashboard-menu-hover-bg) !default;

$dashboard-btn-hover: var(--dashboard-btn-hover) !default;
$dashboard-add-btn: var(--dashboard-add-btn) !default;
$dashboard-add-btn-border: var(--dashboard-add-btn-border) !default;
$dashboard-add-btn-hover: var(--dashboard-add-btn-hover) !default;
$dashboard-add-btn-bg-hover: var(--dashboard-add-btn-bg-hover) !default;

$dashboard-btn-gray: var(--dashboard-btn-gray) !default;
$dashboard-btn-gray-hover: var(--dashboard-btn-gray-hover) !default;
$dashboard-btn-gray2: var(--dashboard-btn-gray2) !default;
$dashboard-btn-gray2-hover: var(--dashboard-btn-gray2-hover) !default;
$dashboard-btn-gray2-text: var(--dashboard-btn-gray2-text) !default;

$rearrange-close-bg: var(--rearrange-close-bg) !default;
$plan-month: var(--plan-month) !default;
$menu-link: var(--menu-link) !default;
$mobile-menu-bg: var(--mobile-menu-bg) !default;
$close-menu-bg: var(--close-menu-bg) !default;
$swiper-nav-bg: var(--swiper-nav-bg) !default;
$banner-video-unload-bg: var(--banner-video-unload-bg) !default;
$file-upload-bg: var(--file-upload-bg) !default;
$file-upload-hover-bg: var(--file-upload-hover-bg) !default;
$file-preview-bg: var(--file-preview-bg) !default;
$text-opacity: var(--text-opacity) !default;
$input-label: var(--input-label) !default;

$gallery-img-bg: var(--gallery-img-bg) !default;
$shimmer-bg: var(--shimmer-bg) !default;
$shimmer-gradient: var(--shimmer-gradient) !default;

$header-menu-item: var(--header-menu-item) !default;
$header-menu-item-active: var(--header-menu-item-active) !default;

$album-details-tab: var(--album-header-tab) !default;

$testimonial-swiper-arrow-bg: var(--testimonial-swiper-arrow-bg) !default;
$otp-border: var(--otp-border) !default;

$shimmer-bg-alt: var(--shimmer-bg-alt) !default;
// $shimmer-gradient-alt: var(--shimmer-gradient-alt) !default;
$shimmer-green-bg: var(--shimmer-green-bg) !default;
$shimmer-green-gradient: var(--shimmer-green-gradient) !default;
$contact-input: var(--contact-input) !default;
$contact-page-bg: var(--contact-page-bg) !default;
$placeholder-image: var(--placeholder-image) !default;
$text-label-grey: var(--text-label-grey) !default;
$dashboard-header-border: var(--dashboard-header-border) !default;
$button-dark-alt: var(--button-dark-alt) !default;
$button-dark-border-alt: var(--button-dark-border-alt) !default;
$slider-track: var(--slider-track) !default;
$border-opacity-dark: var(--border-opacity-dark) !default;
$blank-folder-outer: var(--blank-folder-outer) !default;
$blank-folder-bg: var(--blank-folder-bg) !default;
$dark-toggler-border: var(--dark-toggler-border) !default;
$fu-scrollbar-thumb: var(--fu-scrollbar-thumb) !default;
$fu-scrollbar-track: var(--fu-scrollbar-track) !default;
$searchbar-placeholder: var(--searchbar-placeholder) !default;
$search-filter-input: var(--search-filter-input) !default;
$demo-bg: var(--demo-bg) !default;
$dashboard-nav-btn-hover: var(--dashboard-nav-btn-hover) !default;
$dashboard-nav-btn-hover-bg: var(--dashboard-nav-btn-hover-bg) !default;
$dashboard-nav-btn-hover-text: var(--dashboard-nav-btn-hover-text) !default;
$dashboard-dropdown-border: var(--dashboard-dropdown-border) !default;
$edit-image-button-bg: var(--edit-image-button-bg) !default;
$edit-image-button-text: var(--edit-image-button-text) !default;
$edit-image-button-text-hover: var(--edit-image-button-text-hover) !default;
$tree-connection-line: var(--tree-connection-line) !default;
$tree-connection-dot: var(--tree-connection-dot) !default;

$checkout-modal-bg: var(--checkout-modal-bg) !default;
$checkout-modal-bg-alt: var(--checkout-modal-bg-alt) !default;

$searchbar-border: #dde8f8 !default;

$error-message: #ff6261 !default;
$error-message-alt: #f03b0f !default;

$section-background-light: #f3f6fb;

$primary-color: #267ffd !default;
$secondary-color: #001631 !default;

$white: #fff !default;
$black: #000 !default;
$red: #ff6261 !default;
$blue: #062cea !default;
$blue-alt: #233ec2 !default;
$yellow: #ffce00 !default;
$green: #23cc2b !default;
$green-alt: #098f11 !default;
$dark-alt: #2b2b2b !default;
$light-grey: #efefef !default;
$dark-grey: #606060 !default;
$alt-grey: #707070 !default;
$alt-light: #d8d8d8 !default;
$orange-alt: #d9800b !default;
$orange-red-alt: #f03b0f !default;
$orange-light: #ffece2 !default;

$border-grey: #b0b4b3 !default;
$button-grey: #a09ea6 !default;

$dropdown-bg: #26325d !default;
$dropdown-bg-hover: #f6f6f6 !default;

$section-background-grey: #f6f6f6 !default;

$box-shadow-light: #c4cae9;
$box-shadow-light-alt: #cfdef7;
$box-shadow-light-alt-2: #d7dae4;

$btn-primary: $primary-color;
$btn-primary-hover: #014afe;

$btn-secondary: #1d5eff;
$btn-tertiary: $blue;

$pagination-nav: #b4b4b4 !default;

$toggle-grey: #a2a4ac !default;



$shimmer-gradient-alt: linear-gradient(
  to right,
  var(--shimmer-gradient-alt-0) 0%,
  var(--shimmer-gradient-alt-10) 10%,
  var(--shimmer-gradient-alt-20) 20%,
  var(--shimmer-gradient-alt-30) 30%,
  var(--shimmer-gradient-alt-50) 50%,
  var(--shimmer-gradient-alt-100) 100%
);


/* Border Color */
$border-color: #d3d5db !default;
$border-color-2: #b1b1b1;
$border-color-alt: #e3e5f1 !default;

$list-bullet-tick: #23cc2b !default;
$green-alt: #0fa116 !default;

$delete-red: #fc4343 !default;

/* Text Color */
$text-color: var(--text-color) !default;
$text-alt: #252533 !default;
$text-alt-2: #333333 !default;
$text-alt-hover: #0a2cea !default;

$base-font: 1rem !default;

/* Font Family */
$font-family: "Ubuntu", sans-serif;
$font-family-inter: "Inter", sans-serif;
$font-family-lato: "Lato", sans-serif;

//Font weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-weight-title: $font-semibold;

@mixin px-rem($pxValue, $property: "font-size", $standard-size: 16) {
  #{$property}: $pxValue/$standard-size + rem;
}
$base-size: 16;
@function size($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}

//   Media query
@mixin min-max($resMin, $resMax) {
  @media (min-width: $resMin+px) and (max-width: $resMax+px) {
    @content;
  }
}
@mixin max($res) {
  @media (max-width: $res+px) {
    @content;
  }
}
@mixin min($res) {
  @media (min-width: $res+px) {
    @content;
  }
}

@mixin button($btn_color, $url, $x1, $y1, $bor, $col) {
  background: $btn_color;
  -webkit-mask: url($url);
  mask: url($url);
  -webkit-mask-size: $x1 $y1;
  mask-size: $x1 $y1;
  border: $bor;
  color: $col;
}
@-webkit-keyframes btnAnim {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes btnAnim {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@-webkit-keyframes btnAnim2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

@keyframes btnAnim2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}

//  bootstrap variables
$btn-font-size: size(18);
$btn-font-weight: $font-medium;
$btn-line-height: 1;
$btn-padding-x: 32px;
$btn-padding-y: 15px;
$btn-border-radius: 6px;

$btn-font-size-sm: size(18);
$btn-padding-x-sm: 21px;
$btn-padding-y-sm: 13px;
$btn-border-radius-sm: 6px;

$btn-secondary-hover: $btn-primary;
$btn-tertiary-hover: $btn-primary;

$btn-disabled: #b4b4b4;

@mixin line-clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
