@import "../../styles/uitilities.scss";

.quick_demo {
    &_banner {
        background-color: $demo-bg;
        padding: 81px 0;
        position: relative;
        @include max(767.98) {
            padding: 73px 0;
        }
        :global {
            .container {
                position: relative;
                z-index: 2;
            }
        }
        &_image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            z-index: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &_title {
            font-size: size(36);
            font-weight: $font-medium;
            line-height: 48px;
            color: $white;
            max-width: 410px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 22px;
            text-align: center;
            @include max(575.98) {
                font-size: size(26);
                line-height: 38px;
            }
        }
        &_description {
            font-size: size(18);
            font-weight: $font-regular;
            line-height: 32px;
            color: $white;
            max-width: 410px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 0;
            text-align: center;
            @include max(575.98) {
                font-size: size(16);
                line-height: 28px;
            }
        }
    }
    &_videos {
        padding: 120px 0 143px;
        @include max(767.98) {
            padding: 44px 0;
        }
        &_title {
            font-size: size(36);
            font-weight: $font-medium;
            line-height: 44px;
            margin-bottom: 50px;
            color: $text-color-alt;
            @include max(575.98) {
                font-size: size(26);
                line-height: 34px;
                margin-bottom: 24px;
            }
        }
        &_wrap {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }
    }
}
