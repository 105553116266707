@import "../../styles/uitilities.scss";

@mixin resitem($w,$h){
  $val: calc( 10000 / $w * $h);
  $val1: floor($val);
  $val2: calc($val1 / 100);
  position: relative;
  >div{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
  }
  &:before{
      content: "";
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: ($val2 * 1%);
      pointer-events: none;
  }
}

.create_slideshow {
  &_modal {
    :global(.modal-dialog) {
      max-width: 387px;
    }
    &_content {
      padding: 40px 38px 35px;
    }
    &_title {
      font-size: size(24);
      font-weight: $font-medium;
      color: $text-color-alt;
      margin-bottom: 22px;
      text-align: center;
    }
    :global(.form-group) {
      margin-bottom: 22px;
    }
    .slideshow_name {
      &_label {
        font-size: size(14);
        font-weight: $font-regular;
        color: $text-color-alt;
        margin-bottom: 6px;
        padding-bottom: 0;
      }
      &_input {
        font-size: size(14);
        font-weight: $font-regular;
        color: $text-color-alt;
        opacity: 0.52;
      }
    }
    &_actions {
      display: flex;
      justify-content: center;
      align-items: center;
      :global(.btn) {
        // max-width: 149px;
        width: 100%;
        flex: 0 0 47.5%;
        max-width: 47.5%;
        min-width: inherit;
        &:first-child {
          margin-right: 14px;
        }
        &:global(.btn-border-grey) {
          &:hover {
            border-color: #000;
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
}

.slideshow_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.add_new_slideshow{
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 15px;
  @include min(1350) {
    &_name{
      font-size: size(22);
    }
    &_content{
      >div{
        font-size: size(21);
      }
    }
  }
  @include max(1599.98) {
      flex: 0 0 25%;
      max-width: 25%;
  }
  @include max(1199.98) {
    display: none;
  }
  @include max(991.98) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
  }
  @include max(575.98) {
      flex: 0 0 50%;
      max-width: 50%;
  }
  @include max(424.98) {
      flex: 0 0 100%;
      max-width: 100%;
  }
  &_content{
    @include resitem(288,203);
    cursor: pointer;
    >div{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 12px;
      background-color: $card-bg;
      border: 1px dashed rgba(#BFC4CE, 0.49);
      color: $text-color;
      will-change: color, border-color;
      svg{
        color: currentColor;
        margin-bottom: 16px;
        path{
          fill: currentColor;
        }
        @include min-max(576, 991.9){
          max-width: 19px;
          max-height: 19px;
          margin-bottom: 8px;
        }
        @include max(575.9){
          max-width: 17px;
          max-height: 17px;
          margin-bottom: 5px;
        }
      }
    }

    &:hover{
      >div{
        background-color: $modal-link;
        color: $white !important;
        border: transparent;
      }
    }
    
  }
  
  
}