@import "../../styles/uitilities.scss";

.tree {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: auto;
  height: calc(100vh - 83px);
  scrollbar-width: none;
  @include max(1279.9) {
    height: calc(100vh - 52px);
  }
  &::-webkit-scrollbar {
    display: none;
  }
  tspan {
    user-select: none;
  }
  :global(#tree) {
    width: 100%;
    height: 100%;
    // overflow: auto !important;
    svg {
      tspan {
        fill: $text-color-alt;
      }
    }
    :global {
      g {
        &.card-item {
          > svg {
            rect {
              fill: $modal-bg !important;
            }
            + g.text {
              path {
                fill: $modal-bg !important;
              }
            }
          }
        }
      }
    }
  }
  :global(.cardWrapper) {
    background-color: $dashboard-header-bg;
    box-shadow: 2px 2px 5px rgba(192, 196, 206, 0.49);
    border-radius: 10px;
    padding: 13px;
    box-sizing: border-box;
    cursor: pointer;
    position: fixed;
  }
  :global(foreignObject) {
    overflow: unset;
  }
  :global(#addNew) {
    position: fixed;
    bottom: -10px;
    width: 100%;
    height: 40px;
    background-position: center;
    background-size: auto;
    background-color: transparent;
    border: none;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 9;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
    @include max(1279.98) {
      opacity: 1;
      bottom: -31px;
    }
  }
  :global(#addNew > span) {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #23cc2b;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    bottom: 10px;
    pointer-events: none;
  }

  :global(#addNew > span::after) {
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: #fff;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(#addNew > span::before) {
    position: absolute;
    width: 14px;
    height: 2px;
    background-color: #fff;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(#addNew::after) {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='106.334' height='41.347' viewBox='0 0 106.334 41.347'%3E%3Cdefs%3E%3Cfilter id='Path_76531' x='0' y='0' width='106.334' height='41.347' filterUnits='userSpaceOnUse'%3E%3CfeOffset dx='2' dy='2' input='SourceAlpha'/%3E%3CfeGaussianBlur stdDeviation='2.5' result='blur'/%3E%3CfeFlood flood-color='%23bfc4ce' flood-opacity='0.49'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3C/filter%3E%3C/defs%3E%3Cg transform='matrix(1, 0, 0, 1, 0, 0)' filter='url(%23Path_76531)'%3E%3Cpath id='Path_76531-2' data-name='Path 76531' d='M-4619.16-4735.3a36.637,36.637,0,0,1,17.479,2.537c11.661,5.259,9.106,17.39,29.16,18.5,12.914,0,21.019-14.055,25.02-17.042,3.889-3.348,8.786-3.414,17.011-3.6s-5.225-5.707-5.225-5.707l-75.039.706Z' transform='translate(4624.66 4746.11)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center -23px;
    background-size: 160px;
    background-repeat: no-repeat;
    z-index: -2;
    opacity: 0;
    transition-delay: 0.3s;
    transition: 0s all ease-in-out;
    transform: scale(0.75);
    pointer-events: none;
  }
  :global(#addNew::before) {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='91.334' height='26.347' viewBox='0 0 91.334 26.347'%3E%3Cpath id='Path_76530' data-name='Path 76530' d='M-4619.16-4735.3a36.637,36.637,0,0,1,17.479,2.537c11.661,5.259,9.106,17.39,29.16,18.5,12.914,0,21.019-14.055,25.02-17.042,3.889-3.348,8.786-3.414,17.011-3.6s-5.225-5.707-5.225-5.707l-75.039.706Z' transform='translate(4619.16 4740.608)' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    z-index: -1;
    pointer-events: none;
  }
  :global(.mainWrapper:hover) :global(#addNew) {
    opacity: 1;
    bottom: -31px;
  }
  :global(.mainWrapper:hover) :global(#addNew::after) {
    opacity: 1;
    transition-delay: 0.5s;
    transition: 1s all ease-in-out;
  }
  :global(.image-wrapper) {
    width: 140px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
  }

  :global(.image-container) {
    width: 100%;
    position: relative;
    padding-bottom: 100%;
  }
  :global(.image-container.no-image) {
    background-color: #f2f3f5;
  }

  :global(.image-container.no-image::after) {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #252533;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(.image-container.no-image::before) {
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #252533;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: 0.3s background-color ease-in-out;
  }
  :global(.image-container img) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  :global(.content-wrapper) {
    width: 100%;
    position: relative;
  }

  :global(.content-head) {
    width: 100%;
    text-align: center;
  }
  :global(.content-head h3) {
    font-size: 14px;
    line-height: 1.5;
    color: $text-color-alt;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 3px;
  }

  :global(.content-head p) {
    font-size: 12px;
    line-height: 1.5;
    color: $text-label-grey;
    font-weight: 400;
    margin: 0px;
    margin-bottom: 0px;
  }

  :global(html, body) {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    font-family: Helvetica;
    font-family: "Ubuntu", sans-serif;
  }

  :global {
    circle.bft-fill {
      stroke: $tree-connection-dot !important;
    }
    g#base_up {
      transform: translate(20px, -5px);
    }
  }

  :global(.bft-light) {
    background-color: $dashboard-bg !important;
  }

  :global(.link path) {
    stroke-width: 4px;
    stroke: $tree-connection-line;
    stroke-linejoin: unset !important;
  }

  :global(#dot circle) {
    fill: #23cc2b;
    stroke: #f6f6f6;
    stroke-width: 4px;
    // r: 8px;
  }
  .treeControls {
    display: flex;
    align-items: center;
    margin: 0 0px;
    position: absolute;
    // bottom: 15px;
    margin-top: 15px;
    right: 0;
    padding: 10px 20px;
    width: 100%;
    justify-content: flex-end;
    pointer-events: none;
    button {
      pointer-events: all !important;
    }
    @include max(1279.98) {
      bottom: 60px;
    }
    :global {
      .btn-curved {
        visibility: visible;
        transition: 0.3s ease-in-out all;
        &.clear {
          svg path {
            fill: rgb(29, 94, 255);
          }
        }
      }
      .btn-no-border-white {
        min-width: 41px;
        svg {
          margin-right: 8px !important;
        }
      }
      // @include max(1599.9){
      //   .btn-no-border-white{
      //     padding-left: 12px;
      //     padding-right: 12px;
      //   }
      // }
    }
    &:not(.btnInvisible) {
      .ham_btn {
        span {
          background-color: $primary-color;
        }
      }
      // :global {
      //   .btn-round {
      //     &:hover,
      //     &:focus {
      //       background-color: #fff;
      //       border-color: #fff;
      //       transform: scale(1.1);
      //     }
      //   }
      // }
    }
    &.btnInvisible {
      pointer-events: none;
      :global {
        .btn-curved {
          transform: translateX(10px);
          visibility: hidden;
          opacity: 0;
        }
        // .btn-round {
        //   &:hover,
        //   &:focus {
        //     background-color: #fff;
        //     border-color: #fff;
        //     transform: scale(1.1);
        //   }
        // }
      }
      .btnDiv {
        transform: translateX(10px);
        visibility: hidden;
        opacity: 0;
      }
      .btnRounded {
        pointer-events: all;
      }
    }
    :global {
      .btn {
        // margin: 0 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: size(18);
        font-weight: $font-medium;
        color: #252533;
        // @include max(1599.98) {
        padding: 10px 20px;
        margin: 0 6px;
        // }
        @include max(1279.98) {
          border-radius: 50%;
          width: 46px;
          height: 46px;
          padding: 0;
          &:not(.btn-round) {
            span {
              display: none;
            }
          }
          svg {
            margin-right: 0 !important;
          }
        }
        &:hover,
        &:focus {
          color: #fff;
          background-color: $btn-primary-hover;
          border-color: $btn-primary-hover;
          box-shadow: 0 3px 5px rgba(#bfc4ce, 0.36) !important;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        &:global(.btn-round) {
          // width: 47px;
          pointer-events: all;
          border-radius: 50%;
          padding: 0;
          // height: 47px;
          z-index: 99;
          position: relative;
          transform-origin: center;
          transition: 0.3s ease transform;
          background-color: #fff;
          border-color: #fff;
          overflow: hidden;
          // @include max(1599.98) {
          width: 41px;
          height: 41px;
          will-change: transform;
          // display: none;
          // }
          span {
            will-change: transform, width, margin-bottom;
          }
        }

        &:first-child {
          &:hover,
          &:focus {
            svg {
              path {
                fill: #fff;
                &:nth-child(1),
                &:nth-child(3),
                &:nth-child(5) {
                  fill: $btn-primary-hover;
                }
              }
            }
          }
        }
        &:nth-child(2) {
          &:hover {
            path {
              fill: #fff;
            }
          }
        }
        svg {
          margin-right: 15px;
          path {
            transition: 0.5s ease all;
          }
        }
      }
    }
    .btnDiv {
      display: flex;
      align-items: center;
      border-radius: 23px;
      box-shadow: 0 3px 5px rgba(#bfc4ce, 0.36);
      // margin: 0 11px;
      visibility: visible;
      transition: 0.3s ease-in-out all;
      // @include max(1599.98) {
      margin: 0 6px;
      // }
      .zoomInput {
        // padding: 13px 14px;
        background-color: #fff;
        border: none;
        max-width: 75px;
        width: 100%;
        font-size: size(18);
        font-weight: $font-medium;
        color: #252533;
        // height: 48px;
        // @include max(1599.98) {
        padding: 10px 12px;
        height: 41px;
        // }
      }
      .btn_modify {
        width: 53px;
        // min-height: 48px;
        border: none;
        background-color: #fff;
        transition: 0.25s ease background-color;
        // @include max(1599.98) {
        min-height: 41px;
        // }
        svg {
          path {
            transition: 0.25s ease fill;
          }
        }
        &:hover {
          background-color: $btn-primary-hover;
          border-color: $btn-primary-hover;
          svg {
            path {
              fill: #fff;
            }
          }
        }
        &:first-of-type {
          border-radius: 23px 0 0 23px;
          border-right: 1px solid rgba(#a2a4ac, 0.6);
        }
        &:last-of-type {
          border-radius: 0 23px 23px 0;
          border-left: 1px solid rgba(#a2a4ac, 0.6);
        }
      }
    }
  }
}

:global(.dark #addNew::before) {
  filter: invert(82%) sepia(3%) saturate(4350%) hue-rotate(202deg)
    brightness(98%) contrast(90%);
}
:global(
    .dark
      :is(
        .cardWrapper,
        .btn-curved,
        .btn-curved + div,
        .btn.btn-curved:focus,
        .btn-div:focus
      )
  ) {
  box-shadow: none !important;
}

:global {
  .myTemplate.bft-light {
    > g {
      > path {
        transform: translateX(4px) !important;
        + use {
          transform: translateX(4px) !important;
        }
      }
      g.text {
        svg {
          cursor: pointer;
        }
      }
    }
  }
}
[data-name="Rectangle 17311"] {
  cursor: pointer !important;
  * {
    pointer-events: none !important;
  }
}

.btnRounded {
  transition: 0.3s all ease-in-out !important;
  border-color: transparent !important;
  &:hover {
    background-color: rgb(216, 216, 216);
  }
}
.ham_btn {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 2px;
  width: 16px;
  height: 16px;

  span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #000;
    margin-bottom: 0;
    transition: 0.25s all ease-in-out;
    display: flex !important;
  }
}

:global {
  #Image_1972 {
    height: 134px;
  }
  rect.user {
    stroke-width: 3px;
    stroke: $primary-color;
  }
  .lock-user {
    .text {
      display: none !important;
    }
  }
}
.delete_this_photo_modal {
  color: $text-color;
  :global(.modal-dialog) {
    max-width: 474px;
  }
  :global(.modal-content) {
    padding: 50px 45px;
    text-align: center;
  }
  :global(.modal-header) {
    height: auto;
  }
  :global {
    .custom-checkbox {
      align-items: flex-start;
      span {
        font-size: size(14);
        line-height: 19px;
        color: $text-color-alt;
        opacity: 0.49;
        padding-left: 3px;
      }
      .checkmark {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        margin-top: 2px;
        border-radius: 2px;
        background-color: $modal-bg;
        &::after {
          position: absolute;
          top: 3px;
          left: 2px;
          border-radius: 0;
          width: 10px;
          height: 5px;
          background: transparent;
          border: 1px solid transparent;
          border-left: 1px solid #fc4343;
          border-bottom: 1px solid #fc4343;
          transform: rotate(-45deg);
        }
      }
      input {
        &:checked {
          + .checkmark {
            border-radius: 2px;
            border: 1px solid #fc4343;
          }
        }
      }
    }
  }
  .delete_btn_wrap {
    display: flex;
    margin-top: 25px;
    justify-content: center;
    button {
      width: 100%;
      max-width: 185px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 11px 13px;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }
    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;
      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }
  .delete_title {
    font-size: size(24);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }
  .delete_content {
    font-size: size(16);
    font-weight: 400;
  }
  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
  }

  :global {
    .btn.icon-primary:not(:hover) {
      color: $primary-color;
      svg {
        path {
          fill: $primary-color;
        }
      }
    }
  }
}
@media print {
  @page {
    size: A3 landscape;
    -webkit-size: A3 landscape;
  }
}
