@import "../../styles/uitilities.scss";

.VideoPreviewModal{

    background-color: rgba(0,0,0,0.7);
    &_video{
        max-width: 100%;
        max-width: 100%;
        transform: translate(0px, 3px);
    }
    :global{
        .btn-close{
            margin-top: -54PX;
            margin-right: -44PX;
        }
        #myvideo{
            // border-color: #777;
            border-width: 6px;
        }
        .modal-dialog{
            max-width: 800px;
        }
        .modal-content{
            position: relative;
            background-color: transparent!important;

            bottom: 0;
            height: 100%;
            display: flex;
            left: 0;
            right: 0;
            width: 100%;
            align-items: center;

            video{
                max-width: 100%;
            }
        }
        .modal-content{
            height: auto;
        }
    }
}