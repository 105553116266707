@import "../../../../styles/uitilities.scss";

.mobile_gallery_actions {
  display: flex;
  padding-bottom: 13px;
  border-bottom: 1px solid $input-border;
  margin-bottom: 10px;
  @include max(1279.98) {
    padding-left: 36px;
    padding-right: 36px;
    margin-left: -36px;
    margin-right: -26px;
  }
  @include max(767.98) {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
    margin-right: -15px;
  }
  :global(.dropdown-menu) {
    background-color: $white;
    border-radius: 10px;
    padding: 15px 13px;
    position: relative;
    &::before {
      content: "";
      background: $white;
      position: absolute;
      left: 20px;
      width: 14px;
      height: 14px;
    }
    &[x-placement="bottom-start"] {
      transform: translate(-8px, 44px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        transition: 0.5s ease all;
      }
    }
    &[x-placement="bottom-end"] {
      transform: translate(0px, 45px) !important;
      &::before {
        top: 0;
        transform: translateY(-50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 0 0 5px;
        border-bottom: 1px solid $border-color;
        border-left: 1px solid $border-color;
        transition: 0.5s ease all;
      }
    }
    &[x-placement="top-start"] {
      &::before {
        bottom: 0;
        transform: translateY(50%) rotate(135deg);
        border: 1px solid transparent;
        border-radius: 0 5px 0 0;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        transition: 0.5s ease all;
      }
    }
  }
  :global(.dropdown-item) {
    &:active,
    &:focus {
      background-color: #e9ecef !important;
      color: $black;
    }
  }
  .dashboard {
    &_dropdown {
      button {
        &::after {
          display: none;
        }
      }
      &.show {
        + .btn {
          background-color: $text-color-alt !important;
        }
      }
    }
    &_add_btn {
      margin-left: auto;
      margin-right: 0px;
      border: none;
    }
  }
  button {
    padding: 5px;
    min-height: 33px;
    min-width: 35px;
    margin-right: 2px;
  }

  .file_upload {
    display: none;
  }
}
.dashboard_btn {
  background-color: $dashboard-bg;
  width: auto !important;
  margin: 0 10px 0 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &:focus:hover {
    background-color: $text-color-alt;
  }
  p {
    margin-bottom: 0;
    margin-left: 5px;
    font-size: size(15.5);
  }
}
.dashboard_add_btn {
  text-transform: capitalize;
  font-size: size(15.5);
  padding: 9px !important;
  background-color: transparent;
  color: $text-color;
  width: auto !important;
  border: 1px solid $text-color!important;
  &:hover{
    background-color: $text-color!important;
    color: $body-background!important;
  }
  svg {
    top: -1px;
    position: relative;
    margin-right: 6px;
    path {
      fill: currentColor;
    }
  }
  &:hover {
    background-color: $blue-alt;
    border-color: $blue-alt;
  }
}
.dashboard_btn,
.dashboard_dropdown {
  color: $text-color !important;
  svg {
    path {
      fill: currentColor !important;
    }
  }
  &:hover {
    color: white !important;
  }
}
.dashboard_btn {
  &:hover {
    background-color: $text-color-alt !important;
    svg {
      path {
        fill: $dashboard-btn-hover !important;
      }
    }
    p {
      color: $dashboard-btn-hover !important;
    }
  }
}
.add_btn_wrap_mob {
  position: fixed;
  bottom: 72px;
  z-index: 10;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  &:before {
    content: "";
    width: 100%;
    height: 150px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 0;
    filter: blur(3px);
    pointer-events: none;
  }
  button {
    width: 90px;
    height: 32px;
    z-index: 10;
  }
  .dashboard_cancel_btn {
    border: none;
  }
  button {
    padding: 5px 7px 8px 7px;
    min-width: 35px;
    margin-right: 10px;
    font-size: size(14);
  }
}

.save_btn_wrap_mob {
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid $input-border;
  button {
    width: 90px;
    height: 32px;
    z-index: 10;
  }
  .dashboard_cancel_btn {
    background-color: transparent;
    &:hover {
      background-color: $secondary-color;
      border-color: $secondary-color;
    }
  }
  button {
    padding: 5px 7px 8px 7px;
    min-width: 35px;
    margin-right: 10px;
    font-size: size(14);
  }
}

:global(body.dark) {
  .save_btn_wrap_mob {
    .dashboard_cancel_btn {
      color: $text-color;
      border-color: $text-color;
      &:hover {
        background-color: white;
        color: black;
      }
    }
  }
}

.dashboard_actions {
  &_btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
    border: none;
    // background: rgba(#000, 0.12);
    // border: 1px solid rgba(#000, 0.12);
    padding: 0;
    min-width: inherit;
    position: relative;
    margin-right: 23px;
    &:focus,
    &:active {
      background-color: rgba(#000, 0.12) !important;
      border-color: transparent !important;
    }
    &_label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 4px;
    }
    &:hover {
      background-color: rgba(#000, 0.12) !important;
      border-color: transparent !important;
    }
    span {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $text-color-alt;
      + span {
        margin-top: 2px;
      }
    }
    &::after {
      display: none;
    }
  }
  &_close {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: transparent;
    border: none;
    padding: 0;
    min-width: inherit;
    position: relative;
    &:hover {
      background-color: rgba(#000, 0.12);
      border-color: transparent;
    }
    &:focus,
    &:active {
      background-color: rgba(#000, 0.12);
      border-color: transparent;
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      path {
        fill: $text-color-alt;
      }
    }
  }
}

.delete_this_photo_modal {
  color: $text-color;
  :global(.modal-dialog) {
    max-width: 474px;
  }
  :global(.modal-content) {
    padding: 22px 34px;
    text-align: center;
  }
  :global(.modal-header) {
    height: auto;
  }
  :global {
    .custom-checkbox {
      align-items: flex-start;
      span {
        font-size: size(14);
        line-height: 19px;
        color: rgba(#000, 0.49);
        padding-left: 3px;
      }
      .checkmark {
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        margin-top: 2px;
        border-radius: 2px;
        &::after {
          position: absolute;
          top: 3px;
          left: 2px;
          border-radius: 0;
          width: 10px;
          height: 5px;
          background: transparent;
          border: 1px solid transparent;
          border-left: 1px solid #fc4343;
          border-bottom: 1px solid #fc4343;
          transform: rotate(-45deg);
        }
      }
      input {
        &:checked {
          + .checkmark {
            border-radius: 2px;
            border: 1px solid #fc4343;
          }
        }
      }
    }
  }
  .delete_btn_wrap {
    display: flex;
    margin-top: 18px;
    justify-content: center;
    button {
      width: 100%;
      max-width: 112px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      font-size: size(15);
      padding: 0px 13px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 7px;
      transition: background-color 0.3s ease-in;
    }
    :global(.btn-outline-gray) {
      background-color: transparent;
      border: 1px solid $input-border;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    :global(.btn-danger) {
      color: white;
      background-color: $delete-red;
      &:hover {
        background-color: $orange-red-alt;
      }
    }
  }
  .delete_title {
    font-size: size(20);
    font-weight: 700;
    margin-bottom: 10px;
    color: $text-color;
  }
  .delete_content {
    font-size: size(14);
    font-weight: 400;
  }
  :global(.btn-close) {
    top: 18px !important;
    right: 19px !important;
    font-size: 15px !important;
    display: none;
  }
}


.add_to_album {
  :global(.modal-dialog) {
    max-width: 335px;
    margin: auto;
    :global(.modal-content) {
      padding: 20px 30px;
    }
    :global(.modal-header) {
      height: auto;
      border-bottom: 1px solid $input-border;
      margin-bottom: 13px;
      :global(.modal-title) {
        color: $text-color;
      }
    }
    :global(.h4) {
      font-size: size(24);
      margin-bottom: 6px;
    }
    :global(.btn-close) {
      top: 18px !important;
      right: 19px !important;
      font-size: 15px !important;
    }
  }
}

.albumList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 13px 0;
  cursor: pointer;
  .gotoAction {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-5px);
    transition: 0.2s ease all;
  }
  &:hover {
    background-color: #45453942;
    .gotoAction {
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
    }
  }
}
.add_to_new_album_btn_wrap {
  font-size: size(18);
  font-weight: 500;
  margin: 13px 0;
  display: flex;
  align-items: center;
  .new_album_label {
    padding-left: 15px;
    color: $text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 58px;
  }
  &:hover {
    .add_to_album_btn {
      color: $text-alt-hover;
    }
  }
}

.add_to_new_album_btn_wrap {
  font-size: size(18);
  font-weight: 500;
  margin: 13px 0;
  display: flex;
  align-items: center;
  .new_album_label {
    padding-left: 15px;
    color: $text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 58px;
  }
  &:hover {
    .add_to_album_btn {
      color: $text-alt-hover;
    }
  }
}
.add_to_album_btn {
  border: 1px solid $input-border;
  border-radius: 11px;
  color: $text-color;
  width: 58px;
  height: 58px;
  background-color: transparent;
  svg {
    color: currentColor;
    path {
      fill: currentColor;
    }
  }
}
.listContainer {
  overflow-y: scroll;
  height: 200px;
}
.listContainer::-webkit-scrollbar {
  display: none;
}