@import "../../styles/uitilities.scss";

.search_results {
  color: $text-color;
  @include min(768) {
    position: fixed;
    // width: calc(100vw - 250px);
    width: 100%;
  }
  @include min(1440) {
    position: fixed;
    width: calc(100vw - 310px);
  }
  @include min(768) {
    padding: 50px;
  }
  :global {
    .noData_loader {
      margin-top: 0;
    }
  }
  &_query {
    display: flex;
    align-items: center;
    font-size: size(22);
    font-weight: $font-semibold;
    margin-bottom: 20px;
    color: $text-color-alt;
    @include max(575.98) {
      font-size: size(18);
      margin-bottom: 14px;
    }
  
  }
  &_filter {
    display: flex;
    align-items: center;
    margin: 0 -5px;
    margin-bottom: 20px;
    &_searchbar {
      margin-bottom: 5px;
      input {
        height: 32px;
        border-radius: 6px;
        background-color: $white;
        // border: 1px solid $input-background;
        border: 1px solid $border-color;
        caret-color: $black;
        color: $black;
        &::placeholder {
          color: rgba($black, 0.7);
        }
        &::-webkit-input-placeholder {
          color: rgba($black, 0.7);
        }
        &::-moz-placeholder {
          color: rgba($black, 0.7);
        }
        &::-webkit-calendar-picker-indicator {
          opacity: 0;
          cursor: pointer;
        }
        &:focus {
          background-color: $white;
          border: 1px solid $input-background;
          caret-color: $black;
          color: $black;
          &::placeholder {
            color: rgba($black, 0.7);
          }
          &::-webkit-input-placeholder {
            color: rgba($black, 0.7);
          }
          &::-moz-placeholder {
            color: rgba($black, 0.7);
          }
          &::-webkit-calendar-picker-indicator {
            opacity: 0;
            cursor: pointer;
          }
        }
      }
      svg {
        position: absolute;
        top: 50%;
        right: 18px;
        transform: translateY(-50%);
        pointer-events: none;
        path {
          fill: $black;
        }
      }
    }
    :global {
      .dropdown {
        width: max-content !important;
        padding: 0 5px;
        &-toggle {
          font-size: size(16);
          font-weight: $font-regular;
          min-width: inherit;
          padding: 8px 12px;
          width: max-content;
          background-color: $search-filter-input;
          border-color: $search-filter-input;
          border-radius: 6px;
          color: $text-color-alt;
          @include max(575.98) {
            font-size: size(14);
          }
          &:hover {
            background-color: $search-filter-input;
            border-color: $search-filter-input;
            color: $text-color-alt;
          }
          &::after {
            margin-left: 10px;
            margin-bottom: -2px;
            border-top: 4px solid;
            border-right: 4px solid transparent;
            border-bottom: 0;
            border-left: 4px solid transparent;
          }
        }
        &-menu {
          background-color: $white;
          border-radius: 10px;
          padding: 15px 13px;
          position: relative;
          min-width: 197px;
          .scrollDiv {
            max-height: 200px;
            overflow: auto;
            scrollbar-width: thin;
            scrollbar-color: #d2d4da rgba(#071454, 0.07);
            padding-right: 10px;
            &::-webkit-scrollbar {
              width: 6px;
              border-radius: 10px;
            }
            &::-webkit-scrollbar-track {
              background: rgba(#071454, 0.07);
              border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
              background: #d2d4da;
              border-radius: 10px;
            }
          }
          &::before {
            content: "";
            background: $white;
            position: absolute;
            left: 30px;
            width: 14px;
            height: 14px;
          }
          &[x-placement="bottom-start"] {
            transform: translate(5px, 42px) !important;
            &::before {
              top: 0;
              transform: translateY(-50%) rotate(135deg);
              border: 1px solid transparent;
              border-radius: 0 0 0 5px;
              border-bottom: 1px solid $border-color;
              border-left: 1px solid $border-color;
            }
          }
          &[x-placement="bottom-end"] {
            &::before {
              top: 0;
              left: inherit;
              right: 15px;
              transform: translateY(-50%) rotate(135deg);
              border: 1px solid transparent;
              border-radius: 0 0 0 5px;
              border-bottom: 1px solid $border-color;
              border-left: 1px solid $border-color;
            }
          }
          &[x-placement="top-start"] {
            transform: translate(5px, -42px) !important;
            &::before {
              bottom: 0;
              transform: translateY(50%) rotate(135deg);
              border: 1px solid transparent;
              border-radius: 0 5px 0 0;
              border-top: 1px solid $border-color;
              border-right: 1px solid $border-color;
            }
          }
          &[x-placement="top-end"] {
            &::before {
              bottom: 0;
              right: 15px;
              left: inherit;
              transform: translateY(50%) rotate(45deg);
              border: 1px solid transparent;
              border-radius: 0 5px 0 0;
              border-bottom: 1px solid $border-color;
              border-right: 1px solid $border-color;
            }
          }
          a {
            font-size: size(17);
            font-weight: $font-regular;
            padding: 6px 10px;
            border-radius: 6px;
            overflow: hidden;
            &:hover,
            &:focus {
              color: $text-alt;
              background-color: $dropdown-bg-hover;
            }
          }
        }
      }
    }
  }
  &_wrap {
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #d2d4da rgba(#071454, 0.07);
    @include min(768) {
      height: calc(100% + 16px);
      padding-right: 50px;
      padding-bottom: 60px;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(#071454, 0.07);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d2d4da;
      border-radius: 10px;
    }
    // .no_result_title {
    //     font-size: size(22);
    //     font-weight: $font-semibold;
    //     margin-bottom: 20px;
    //     color: $text-color-alt;
    //     @include max(575.98) {
    //         font-size: size(18);
    //         margin-bottom: 14px;
    //     }
    // }
    .no_result_description {
      justify-content: center;
      display: flex;
      align-items: center;
      height: 100%;
      font-size: 52px;
      h4{
        color:#d2d4da;
      }
    }
    .search_result {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid $dashboard-item-border;
      @include max(575.98) {
        padding: 10px 0;
      }
      &:first-child {
        border-top: 1px solid $dashboard-item-border;
      }
      &_image {
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        width: 59px;
        min-width: 59px;
        margin-bottom: 0;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
        }
        &::after {
          content: "";
          display: block;
          height: 0;
          width: 100%;
          padding-bottom: calc(44 / 59 * 100%);
        }
      }
      &_match {
        font-size: size(18);
        font-weight: $font-regular;
        color: $text-color;
        margin-bottom: 8px;
        @include max(575.98) {
          font-size: size(14);
          margin-bottom: 6px;
        }
        span {
          font-weight: $font-medium;
        }
      }
      &_album {
        font-size: size(14);
        font-weight: $font-regular;
        color: $text-color;
        opacity: 0.7;
        line-height: 1;
      }
      &_data {
        padding-left: 26px;
      }
    }
  }

  .dashboard_actions {
    &_btn {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      position: relative;
      margin: 0 15px 0 10px;
      &:hover {
        background: rgba(#000, 0.12);
      }
      &_label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 4px;
      }
      span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $text-color-alt;
        + span {
          margin-top: 2px;
        }
      }
      &::after {
        display: none;
      }
    }
    &_close {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: transparent;
      border: none;
      padding: 0;
      min-width: inherit;
      position: relative;
      &:hover {
        background: rgba(#000, 0.12);
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        path {
          fill: $text-color-alt;
        }
      }
    }
  }
}
.search_results_container {
  position: relative;
  background-color: $dashboard-header-bg;
  @include min(768) {
    height: calc(100vh - 133px);
  }
  &::after {
    content: "";
    width: 100%;
    height: 80px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to top,
      $dashboard-header-bg,
      transparent
    );
  }
}

@include min(768) {
  .search_results_container {
    border-radius: 12px;
  }
}

@include max(767.98) {
  .search_results_container {
    padding: 15px;
    min-height: calc(100vh - 52px);
  }
}
@include min(576) {
  .search_results_container {
    padding: 42px 62px 75px;
  }
}
@include max(575.98) {
  .search_results_container {
    padding: 15px;
  }
}
