@import "../../styles/uitilities.scss";

.preference {
  @include min(768) {
    padding: 50px;
    min-height: calc(100vh - 76px);
    .preference_wrap {
      padding: 42px 62px 60px 62px;
    }
  }
  @include max(767.98) {
    .preference_wrap {
      border-radius: 0;
    }
  }
  .preference_wrap {
    background-color: $dashboard-header-bg;
    color: $text-color;
    @include max(767.98) {
      min-height: calc(100vh - 52px);
    }
    @include min(768) {
      border-radius: 12px;
    }
    @include min-max(576, 767.98) {
      padding: 42px;
    }
    @include max(575.98) {
      padding: 16px;
    }
  }
}