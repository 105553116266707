@import "../../styles/uitilities.scss";

.delete_this_photo_modal {
    color: $text-color;
    :global(.modal-dialog) {
      max-width: 474px;
    }
    :global(.modal-content) {
      padding: 50px 45px;
      text-align: center;
      @include max(575.98) {
        padding: 22px;
      }
    }
    :global(.modal-header) {
      height: auto;
    }
    .delete_btn_wrap {
      display: flex;
      margin-top: 25px;
      @include max(575.98) {
        justify-content: center;
        margin-top: 18px;
      }
      button {
        width: 100%;
        max-width: 185px;
        border: none;
        border-radius: 6px;
        font-weight: 500;
        font-size: size(17);
        padding: 13px;
        margin: 0 7px;
        transition: background-color 0.3s ease-in;
        @include max(575.98) {
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 4px;
          font-size: size(14);
          max-width: 108px;
        }
      }
      :global(.btn-outline-gray) {
        background-color: transparent;
        border: 1px solid $input-border;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      :global(.btn-danger) {
        color: white;
        background-color: $delete-red;
        &:hover {
          background-color: $orange-red-alt;
        }
      }
    }
    .delete_title {
      font-size: size(24);
      font-weight: 700;
      margin-bottom: 10px;
      color: $text-color;
      @include max(575.98) {
        font-size: size(20);
      }
    }
    .delete_content {
      font-size: size(16);
      font-weight: 400;
      @include max(575.98) {
        font-size: size(14);
      }
    }
    :global(.btn-close) {
      top: 18px !important;
      right: 19px !important;
      font-size: 15px !important;
    }
  }
  
  .btn_loader {
    position: relative;
  }
  
  .btn_loader::after {
    content: ".";
    animation: dots 2s steps(5, end) infinite;
    font-size: 40px;
    line-height: 1px;
    vertical-align: top;
    position: absolute;
    left: 40%;
    top: 25%;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }